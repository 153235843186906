import type {MediaDeviceRequest} from './types';
import {getDevices} from './devices';
import {getMediaConstraints} from './constraints';
import {normalizeGetUserMediaError} from './errors';
import {logger} from './logger';

/**
 * Get MediaStream with provided input constraints
 *
 * @returns MediaStream
 *
 * @beta
 */
export const getMediaStream = async (
    request: MediaDeviceRequest & {
        getDefaultConstraints: () => MediaStreamConstraints;
    },
) => {
    const constraints = getMediaConstraints({
        audio: request.audio,
        video: request.video,
        defaultConstraints: request.getDefaultConstraints(),
    });

    logger.debug({request, constraints}, 'Constraints used for getting media');

    try {
        // Track device changes before and after the getUserMedia call to keep
        // track of authorized/unauthorized devices
        return await navigator.mediaDevices.getUserMedia(constraints);
    } catch (error: unknown) {
        if (error instanceof Error) {
            const devices = await getDevices();
            throw new Error(
                normalizeGetUserMediaError(error, constraints, devices),
            );
        }
        throw error;
    }
};
