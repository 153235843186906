import type {
    IndexedDevices,
    InputDeviceConstraint,
    MediaDeviceInfoLike,
    MediaDeviceRequest,
    InputConstraintSet,
} from '@pexip/media-control';
import {
    createStreamTrackEventSubscriptions,
    createTrackDevicesChanges,
    extractConstraintsWithKeys,
    findDeviceFromConstraints,
    relaxInputConstraint,
    resolveMediaDeviceConstraints,
    shouldRequestDevice,
    toMediaDeviceInputKind,
} from '@pexip/media-control';
import {calculateMaxBlurPass} from '@pexip/media-processor';
import {hasOwn, assert, isEmpty} from '@pexip/utils';

import type {
    AudioContentHint,
    ExtendedMediaTrackSettings,
    ExtendedMediaTrackSettingsKey,
    Media,
    MediaInit,
    MediaTrack,
    MediaTrackInit,
    TrackProcessor,
    UserMediaStatus,
    VideoContentHint,
} from './types';
import {internalSignals} from './signals';

export const makeDeriveDeviceStatus =
    (constraints: MediaDeviceRequest) =>
    (audio: UserMediaStatus, video: UserMediaStatus, both: UserMediaStatus) => {
        if (constraints.audio) {
            if (constraints.video) {
                return both;
            }
            return audio;
        }
        if (constraints.video) {
            return video;
        }
        return both;
    };

export const createMediaTrack = (trackInit: MediaTrackInit): MediaTrack => {
    const trackConsistency =
        trackInit.track === undefined ||
        (trackInit.kind === trackInit.input?.kind &&
            trackInit.kind === toMediaDeviceInputKind(trackInit.track));
    assert(
        trackConsistency,
        `Inconsistent track kind: ${trackInit.input?.kind} ${trackInit.track && toMediaDeviceInputKind(trackInit.track)} vs ${trackInit.kind}`,
    );
    const currentConstraints =
        typeof trackInit.constraints === 'boolean' ? {} : trackInit.constraints;

    // Find the original source track through the linked list
    let sourceMediaTrack = trackInit.previousMediaTrack;
    while (sourceMediaTrack?.previousMediaTrack) {
        sourceMediaTrack = sourceMediaTrack.previousMediaTrack;
    }

    const getConstraints = () => {
        if (trackInit.constraints === false) {
            return false;
        }
        return {
            ...currentConstraints,
            ...trackInit.track?.getConstraints(),
        };
    };
    const getSettings = () => {
        return {
            ...trackInit.previousMediaTrack?.getSettings(),
            ...trackInit.track?.getSettings(),
            ...(trackInit.getSettings?.() ?? {}),
        };
    };

    const mediaTrack: MediaTrack = {
        get kind() {
            return trackInit.kind;
        },
        get id() {
            return trackInit.id ?? trackInit.track?.id ?? trackInit.kind;
        },
        get track() {
            return trackInit.track;
        },
        get input() {
            return trackInit.track?.readyState !== 'live'
                ? undefined
                : trackInit.input;
        },
        get expectedInput() {
            return trackInit.expectedInput;
        },
        get label() {
            return trackInit.label;
        },
        get previousMediaTrack() {
            return trackInit.previousMediaTrack;
        },
        get muted() {
            if (trackInit.overrideMute) {
                return trackInit.muted;
            }
            return (
                // If the source track muted, the following processed track will
                // be muted as well since there is no data to process
                !!isTrackMuted(this.source.track) ||
                isTrackMuted(trackInit.track)
            );
        },
        get source() {
            return sourceMediaTrack ?? mediaTrack;
        },
        mute(toMute) {
            if (trackInit.overrideMute) {
                return trackInit.mute?.(toMute);
            }
            trackInit.previousMediaTrack?.mute(toMute);
            if (trackInit.track) {
                trackInit.track.enabled = !toMute;
            }
        },
        getSettings,
        getConstraints() {
            return getConstraints();
        },
        clone() {
            return createMediaTrack({
                ...trackInit,
                constraints: getConstraints(),
                track: trackInit.track?.clone(),
            });
        },
        async applyConstraints(constraints) {
            const resolvedConstraints =
                resolveMediaDeviceConstraints(constraints);
            if (
                typeof resolvedConstraints === 'boolean' ||
                resolvedConstraints === undefined
            ) {
                return Promise.resolve();
            }

            await trackInit.previousMediaTrack?.applyConstraints(
                resolvedConstraints,
            );
            await trackInit.applyConstraints?.(constraints);
            if (trackInit.track) {
                if (
                    'contentHint' in resolvedConstraints &&
                    typeof resolvedConstraints.contentHint === 'string' &&
                    trackInit.track.contentHint !==
                        resolvedConstraints.contentHint
                ) {
                    trackInit.track.contentHint =
                        resolvedConstraints.contentHint;
                }
                const settings = trackInit.track.getSettings();
                const reducedConstraints = Object.keys(
                    resolvedConstraints,
                ).reduce<MediaTrackConstraints>((accm, key) => {
                    const constraintKey = key as keyof MediaTrackConstraints;
                    if (constraintKey in settings) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment --- Type issue and need to be fixed when we have time
                        // @ts-expect-error
                        accm[constraintKey] =
                            resolvedConstraints[constraintKey];
                    }
                    return accm;
                }, {});
                // Firefox will throw an error if passing an empty constraint object
                if (!isEmpty(reducedConstraints)) {
                    await trackInit.track.applyConstraints(reducedConstraints);
                }
            }
            // Update the current constraints
            Object.assign(currentConstraints, resolvedConstraints);
        },
        async release() {
            trackInit.track?.stop();
            if (trackInit.track) {
                trackInit.signals?.onTrackReleased?.emit(trackInit.track);
                trackInit.signals?.[
                    trackInit.track.kind === 'audio'
                        ? 'onAudioMuteStateChanged'
                        : 'onVideoMuteStateChanged'
                ]?.emit(undefined);
            }
            await trackInit.previousMediaTrack?.release();
            await trackInit.release?.();
        },
        toJSON() {
            return {
                label: trackInit.label,
                track: trackInit.track,
                previousMediaTrack: trackInit.previousMediaTrack,
                constraints: getConstraints(),
                settings: getSettings(),
            };
        },
    };
    return mediaTrack;
};

export const createTrackProcessingPipeline =
    (processors: TrackProcessor[]) =>
    async (track: MediaTrack): Promise<MediaTrack> => {
        let lastTrack = track;
        for (const process of processors) {
            lastTrack = await process(lastTrack);
        }
        return lastTrack;
    };

/**
 * Interpret provided input to resolve to a MediaDeviceInfoLike when possible
 * otherwise `undefined`
 */
export const interpretInput = (
    input: boolean | MediaDeviceInfoLike | undefined,
    getCurrentInput: () => MediaDeviceInfoLike | undefined,
) => {
    if (input === true || input === undefined) {
        return getCurrentInput();
    }
    if (input === false) {
        return undefined;
    }
    return input;
};

export const findExpectedInput = (
    devices: IndexedDevices,
    constraints: InputDeviceConstraint | undefined,
    input: MediaDeviceInfoLike | undefined,
    kind: 'audioinput' | 'videoinput',
) => {
    const relaxedConstraints = relaxInputConstraint(kind, constraints, devices);
    const {
        device: [[device] = []],
    } = extractConstraintsWithKeys(['device'])(relaxedConstraints);
    // The result from `findDeviceFromConstraints` has more restrictive
    // result since it also consider if the device can be found from the
    // device list
    const found = device ?? findDeviceFromConstraints(constraints, devices);
    const expectedInput = interpretInput(found, () => input);
    return expectedInput;
};

/**
 * A utility function to check if the provided track is muted. There are
 * 2 factors to be considered: `MediaStreamTrack['muted']` and `MediaStreamTrack['enabled']`.
 *
 * ```md
 * | muted \ enabled | true  | false |
 * |-----------------| ----- | ----- |
 * |     true        | true  | true  |
 * |     false       | false | true  |
 * ```
 *
 * @param track - The tracks can be got from `MediaStream['getAudioStats']` or
 * `MediaStream['getVideoTracks']`
 *
 * @returns `true` means muted, `false` means not muted and `undefined` means
 * there is no track to check
 */
export const isTrackMuted = (track: MediaStreamTrack | undefined) => {
    if (!track || track.readyState === 'ended') {
        return undefined;
    }
    return track.muted || !track.enabled;
};

export const buildMedia = (
    mediaInit: MediaInit,
    onDevicesChanged = internalSignals.onDevicesChanged,
): Media => {
    const props = {
        originalConstraints:
            mediaInit.originalConstraints ?? mediaInit.constraints,
        status: mediaInit.status,
        devices: mediaInit.devices,
        stream: mediaInit.stream ?? new MediaStream(),
        audioTrack: mediaInit.tracks
            .flatMap(track => {
                return track.kind === 'audioinput' ? [track] : [];
            })
            .at(0),
        videoTrack: mediaInit.tracks
            .flatMap(track => (track.kind === 'videoinput' ? [track] : []))
            .at(0),
    };

    const subscribeTrackEvents = (track: MediaStreamTrack) =>
        createStreamTrackEventSubscriptions(track, {
            ended: track => {
                mediaInit.signals?.[
                    track.kind === 'audio'
                        ? 'onAudioMuteStateChanged'
                        : 'onVideoMuteStateChanged'
                ]?.emit(undefined);
                mediaInit.signals?.onStreamTrackEndedFinal?.emit(track);
            },
            mute: track => {
                mediaInit.signals?.[
                    track.kind === 'audio'
                        ? 'onAudioMuteStateChanged'
                        : 'onVideoMuteStateChanged'
                ]?.emit(true);
                mediaInit.signals?.onStreamTrackMuted?.emit(track);
            },
            unmute: track => {
                mediaInit.signals?.[
                    track.kind === 'audio'
                        ? 'onAudioMuteStateChanged'
                        : 'onVideoMuteStateChanged'
                ]?.emit(!track.enabled);
                mediaInit.signals?.onStreamTrackUnmuted?.emit(track);
            },
        });
    const subscribeTrackAndSourceTrackEvents = (track: MediaTrack) => {
        let trackUnsubscribe:
            | ReturnType<typeof subscribeTrackEvents>
            | undefined;
        let sourceTrackUnsubscribe:
            | ReturnType<typeof subscribeTrackEvents>
            | undefined;
        if (track.track) {
            trackUnsubscribe = subscribeTrackEvents(track.track);
            if (track.source.track && track.source.track !== track.track) {
                sourceTrackUnsubscribe = subscribeTrackEvents(
                    track.source.track,
                );
            }
        }
        return () => {
            trackUnsubscribe?.();
            sourceTrackUnsubscribe?.();
        };
    };

    const trackSubscriptions = new Map<
        MediaStreamTrack,
        ReturnType<typeof createStreamTrackEventSubscriptions>
    >(
        mediaInit.tracks.flatMap(track => {
            if (track.track) {
                return [
                    [track.track, subscribeTrackAndSourceTrackEvents(track)],
                ];
            }
            return [];
        }),
    );

    const getConstraints = (): MediaDeviceRequest => {
        const audio =
            props.audioTrack?.getConstraints() ?? mediaInit.constraints.audio;
        const video =
            props.videoTrack?.getConstraints() ?? mediaInit.constraints.video;
        return {audio: audio, video: video};
    };

    let unsubscribe: undefined | (() => void) = onDevicesChanged.add(
        devices => {
            props.devices = devices;
        },
    );

    const muteTrack = (track: MediaTrack, mute: boolean) => {
        const previousMuteState = track.muted;
        const previousEnabledState = track.track?.enabled;
        track.mute(mute);
        const currentMuteState = track.muted;
        const currentEnabledState = track.track?.enabled;
        if (
            track.track &&
            previousEnabledState !== undefined &&
            currentEnabledState !== undefined &&
            previousEnabledState !== currentEnabledState
        ) {
            mediaInit.signals?.onStreamTrackEnabled?.emit(track.track);
        }
        if (previousMuteState !== currentMuteState) {
            mediaInit.signals?.[
                track.kind === 'audioinput'
                    ? 'onAudioMuteStateChanged'
                    : 'onVideoMuteStateChanged'
            ]?.emit(currentMuteState);
        }
    };

    return {
        get id() {
            return props.stream.id;
        },
        get active() {
            return !!props.stream.active;
        },
        get devices() {
            return props.devices;
        },
        get stream() {
            return props.stream;
        },
        get expectedAudioInput() {
            return props.audioTrack?.expectedInput;
        },
        get expectedVideoInput() {
            return props.videoTrack?.expectedInput;
        },
        get audioInput() {
            return props.audioTrack?.input;
        },
        get videoInput() {
            return props.videoTrack?.input;
        },
        get status() {
            return props.status;
        },
        set status(status) {
            if (props.status !== status) {
                props.status = status;
                mediaInit.signals?.onStatusChanged?.emit(status);
            }
        },
        get audioMuted() {
            return props.audioTrack?.muted;
        },
        get videoMuted() {
            return props.videoTrack?.muted;
        },
        getConstraints,
        getOriginalConstraints() {
            return props.originalConstraints;
        },
        isAudioInputUnavailable() {
            return !props.devices.anyAuthorizedDevice('audioinput');
        },
        isVideoInputUnavailable() {
            return !props.devices.anyAuthorizedDevice('videoinput');
        },
        setOriginalConstraints(constraints) {
            props.originalConstraints = constraints;
        },
        requestedAudio() {
            return (
                Boolean(props.audioTrack?.track) ||
                (Boolean(props.originalConstraints.audio) &&
                    props.devices.size('audioinput') > 0)
            );
        },
        requestedVideo() {
            return (
                Boolean(props.videoTrack?.track) ||
                (Boolean(props.originalConstraints.video) &&
                    props.devices.size('videoinput') > 0)
            );
        },
        muteAudio(mute) {
            if (!props.audioTrack) {
                return;
            }
            muteTrack(props.audioTrack, mute);
        },
        muteVideo(mute) {
            if (!props.videoTrack) {
                return;
            }
            muteTrack(props.videoTrack, mute);
        },
        applyConstraints: async constraints => {
            await Promise.all([
                typeof constraints.audio !== 'object'
                    ? Promise.resolve()
                    : props.audioTrack?.applyConstraints(constraints.audio),

                typeof constraints.video !== 'object'
                    ? Promise.resolve()
                    : props.videoTrack?.applyConstraints(constraints.video),
            ]);
        },
        async release() {
            unsubscribe?.();
            unsubscribe = undefined;
            trackSubscriptions.forEach(unsubscribe => unsubscribe());
            trackSubscriptions.clear();
            await Promise.all([
                props.audioTrack?.release(),
                props.videoTrack?.release(),
            ]);
        },
        getSettings: () => ({
            audio: props.audioTrack?.getSettings(),
            video: props.videoTrack?.getSettings(),
        }),
        clone() {
            const tracks = [props.audioTrack, props.videoTrack].flatMap(
                track => {
                    if (!track?.source) {
                        return [];
                    }
                    const cloned = track.source.clone();
                    // Restore the enabled state for all cloned track
                    cloned.mute(false);
                    return cloned;
                },
            );
            const stream =
                this.stream &&
                new MediaStream(
                    tracks.flatMap(track => (track.track ? [track.track] : [])),
                );
            const clonedMedia = buildMedia({
                tracks,
                devices: this.devices,
                status: this.status,
                stream,
                constraints: this.getConstraints(),
                originalConstraints: this.getOriginalConstraints(),
                permission: mediaInit.permission,
            });
            return clonedMedia;
        },
        getTracks() {
            return [props.audioTrack, props.videoTrack].flatMap(track =>
                track ? [track] : [],
            );
        },
        getAudioTracks() {
            return props.audioTrack ? [props.audioTrack] : [];
        },
        getVideoTracks() {
            return props.videoTrack ? [props.videoTrack] : [];
        },
        addTrack(track) {
            switch (track.kind) {
                case 'audioinput':
                    if (track.id === props.audioTrack?.id) {
                        return;
                    }
                    props.audioTrack = track;
                    break;
                case 'videoinput':
                    if (track.id === props.videoTrack?.id) {
                        return;
                    }
                    props.videoTrack = track;
                    break;
                default:
                    break;
            }
            if (track.track) {
                props.stream.addTrack(track.track);
                if (!trackSubscriptions.has(track.track)) {
                    trackSubscriptions.set(
                        track.track,
                        subscribeTrackAndSourceTrackEvents(track),
                    );
                }
                mediaInit.signals?.onAddTrack?.emit(track.track);
                props.stream.dispatchEvent(
                    new MediaStreamTrackEvent('addtrack', {track: track.track}),
                );
            }
        },
        removeTrack(track) {
            let removed = false;
            if (track.id === props.audioTrack?.id) {
                props.audioTrack = undefined;
                removed = true;
            }
            if (track.id === props.videoTrack?.id) {
                props.videoTrack = undefined;
                removed = true;
            }
            if (removed && track.track) {
                props.stream.removeTrack(track.track);
                trackSubscriptions.get(track.track)?.();
                trackSubscriptions.delete(track.track);
                mediaInit.signals?.onRemoveTrack?.emit(track.track);
                props.stream.dispatchEvent(
                    new MediaStreamTrackEvent('removetrack', {
                        track: track.track,
                    }),
                );
            }
        },
        toJSON() {
            return {
                constraints: this.getConstraints(),
                devices: this.devices,
                stream: this.stream,
                audioInput: this.audioInput,
                videoInput: this.videoInput,
                expectedAudioInput: this.expectedAudioInput,
                expectedVideoInput: this.expectedVideoInput,
                status: this.status,
                audioMuted: this.audioMuted,
                videoMuted: this.videoMuted,
            };
        },
    };
};

/**
 * Shallow copy the provided object and override with provided overriding
 *
 * @param original - Original object
 * @param overriding - Object of the same type to override the original
 *
 * @returns a shallow copied object
 */
export const shallowCopy = <T>(original: T, overriding: Partial<T>): T => {
    const copy = Object.create(
        Object.getPrototypeOf(original),
        Object.getOwnPropertyDescriptors(original),
    ) as T;
    return Object.defineProperties(
        copy,
        Object.getOwnPropertyDescriptors(overriding),
    );
};

export const getDevicesChanges = (
    prev: MediaDeviceInfoLike[],
    next: MediaDeviceInfoLike[],
) => {
    const trackChanges = createTrackDevicesChanges(prev);
    return trackChanges(next);
};

export const AUDIO_SETTINGS_KEYS: ExtendedMediaTrackSettingsKey[] = [
    'denoise',
    'contentHint',
];
export const VIDEO_SETTINGS_KEYS: ExtendedMediaTrackSettingsKey[] = [
    'frameRate',
    'videoSegmentation',
    'videoSegmentationModel',
    'foregroundThreshold',
    'backgroundBlurAmount',
    'edgeBlurAmount',
    'maskCombineRatio',
    'backgroundImageUrl',
    'contentHint',
];
export const MIXING_SETTINGS_KEYS: ExtendedMediaTrackSettingsKey[] = [
    'mixWithAdditionalMedia',
];

interface SettingsCache {
    settingsA?: ExtendedMediaTrackSettings;
    settingsB?: ExtendedMediaTrackSettings;
    diff?: ExtendedMediaTrackSettings;
    result?: boolean;
}
export const hasSettingsChanged = (
    keysToLookFor: ExtendedMediaTrackSettingsKey[],
) => {
    const cache: SettingsCache = {};
    return (
        settingsA: ExtendedMediaTrackSettings | undefined,
        settingsB: ExtendedMediaTrackSettings | undefined,
    ): boolean => {
        if (
            cache.result !== undefined &&
            cache.settingsA === settingsA &&
            cache.settingsB === settingsB
        ) {
            return cache.result;
        }
        cache.settingsA = settingsA;
        cache.settingsB = settingsB;
        for (const key of keysToLookFor) {
            if (settingsA === settingsB) {
                cache.result = false;
                return cache.result;
            }
            if (settingsA === undefined || settingsB === undefined) {
                cache.result = true;
                return cache.result;
            }
            if (settingsA[key] !== settingsB[key]) {
                cache.result = true;
                return cache.result;
            }
        }
        cache.result = false;
        return cache.result;
    };
};

export const getSettingsFromKeys = (
    keys: ExtendedMediaTrackSettingsKey[],
    settings: InputConstraintSet | undefined | false,
) => {
    if (!settings) {
        return settings;
    }
    const result: ExtendedMediaTrackSettings = {};
    for (const key of keys) {
        if (settings[key] !== undefined) {
            // @ts-expect-error --- Type issue and need to be fixed when we have time
            result[key] = settings[key];
        }
    }
    return result;
};

export const diffSettings = (
    keysToLookFor: ExtendedMediaTrackSettingsKey[],
) => {
    const cache: SettingsCache = {};
    return (
        settingsA: ExtendedMediaTrackSettings | undefined,
        settingsB: ExtendedMediaTrackSettings | undefined,
    ): ExtendedMediaTrackSettings | undefined => {
        // Same as before
        if (cache.settingsA === settingsA && cache.settingsB === settingsB) {
            return cache.diff;
        }
        cache.settingsA = settingsA;
        cache.settingsB = settingsB;
        // Nothing changed
        if (settingsA === settingsB) {
            cache.diff = undefined;
            return cache.diff;
        }
        if (settingsA === undefined || settingsB === undefined) {
            const settings = settingsA ?? settingsB;
            for (const key of keysToLookFor) {
                if (settings?.[key] !== undefined) {
                    if (cache.diff === undefined) {
                        cache.diff = {};
                    }
                    // @ts-expect-error --- Type issue and need to be fixed when we have time
                    cache.diff[key] = settings[key];
                }
            }
            return cache.diff;
        }
        for (const key of keysToLookFor) {
            if (settingsA[key] !== settingsB[key]) {
                if (cache.diff === undefined) {
                    cache.diff = {};
                }
                // @ts-expect-error --- Type issue and need to be fixed when we have time
                cache.diff[key] = settingsB[key];
            }
        }
        return cache.diff;
    };
};

export const mergeSettings = (
    settingsA: ExtendedMediaTrackSettings | undefined,
    settingsB: ExtendedMediaTrackSettings | undefined,
): ExtendedMediaTrackSettings | undefined => {
    if (settingsA === undefined) {
        return settingsB;
    }
    if (settingsB === undefined) {
        return settingsA;
    }
    return {...settingsA, ...settingsB};
};

/**
 * A function to get the blur kernel size of image height
 *
 * @param percentage - The percentage of image height to calculate the blur
 * kernel size
 * @param height - The image height
 * @param max - The upper bound
 *
 * @returns blur kernel size
 */
export const getBlurKernelSize = (
    percentage: number,
    height: number,
    max = calculateMaxBlurPass(height),
) => {
    if (height <= 0 || percentage <= 0 || max <= 0) {
        return 0;
    }
    return Math.min(Math.ceil(percentage * 0.01 * max), max);
};

/**
 * Apply the content hint to the track
 *
 * @param hint - Content hint
 * @param track - The track to be applied
 */
export const applyContentHint =
    <T extends AudioContentHint | VideoContentHint>(hint?: T) =>
    (track: MediaStreamTrack) => {
        if (hint !== undefined && hint !== track.contentHint) {
            track.contentHint = hint;
        }
    };

/**
 * Check if the browser supports PTZ feature
 * @returns true if the browser supports PTZ feature, otherwise false
 */
export const hasPtzFeature = () => {
    const supports = navigator.mediaDevices.getSupportedConstraints();
    return (
        hasOwn(supports, 'pan') &&
        hasOwn(supports, 'tilt') &&
        hasOwn(supports, 'zoom')
    );
};

interface RequestState {
    kind: 'audioinput' | 'videoinput';
    permission: PermissionState;
    currentDevices: IndexedDevices;
    request: InputDeviceConstraint | undefined;
    currentMediaTracks: MediaTrack[];
    force?: boolean;
}
export const refineMediaConstraints = (
    state: RequestState,
): InputDeviceConstraint | undefined => {
    switch (state.permission) {
        case 'denied':
            return false;
        case 'prompt':
        case 'granted': {
            const requestNeeded = shouldRequestDevice({
                kind: state.kind,
                permission: state.permission,
                currentDevices: state.currentDevices,
                request: state.request,
                tracks: state.currentMediaTracks.flatMap(track =>
                    track.source?.track ? [track.source.track] : [],
                ),
            });
            if (requestNeeded || state.force) {
                return state.request;
            }
            if (state.request) {
                // We already have all we need, skip the request
                return undefined;
            }
            return state.request;
        }
        default:
            return state.request;
    }
};

interface ProcessorParams {
    audioProcessors: TrackProcessor[];
    videoProcessors: TrackProcessor[];
    onProcessingError(error: Error, track: MediaTrack): void;
}
export const createMediaProcessor = ({
    audioProcessors,
    videoProcessors,
    onProcessingError,
}: ProcessorParams) => {
    const processAudioTrack = createTrackProcessingPipeline(audioProcessors);
    const processVideoTrack = createTrackProcessingPipeline(videoProcessors);
    return async (newTracks: MediaTrack[]) => {
        const processTrack = async (track: MediaTrack) => {
            try {
                switch (track.kind) {
                    case 'audioinput':
                        return await processAudioTrack(track);
                    case 'videoinput':
                        return await processVideoTrack(track);
                    default:
                        assert(false, `Unexpected track kind: ${track.kind}`);
                }
            } catch (error) {
                if (error instanceof Error) {
                    onProcessingError?.(error, track);
                }
                return track;
            }
        };
        return await Promise.all(newTracks.map(track => processTrack(track)));
    };
};
