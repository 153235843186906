import React, {useId} from 'react';
import cx from 'classnames';
import type SimpleBarCore from 'simplebar-core';

import {sizeToPadding} from '../../../utils/helpers';
import {Box} from '../Box/Box';
import {Scrollbars} from '../Scrollbars/Scrollbars';

import styles from './Panel.module.scss';

export const Panel = React.forwardRef<
    HTMLDivElement,
    React.PropsWithChildren & {
        fillHeight?: boolean;
        overflowHidden?: boolean;
        withScrollbars?: boolean;
        scrollbarRef?: React.RefObject<SimpleBarCore>;
        scrollElementRef?: React.RefObject<HTMLElement>;
        scrollElementClassname?: string;
        onScroll?: () => void;
    } & React.ComponentProps<typeof Box>
>(
    (
        {
            fillHeight = true,
            hasFooterShadow = true,
            headerContent,
            headerContentBelowDivider,
            footerContent,
            padding = 'small',
            headerPadding = padding,
            footerPadding = padding,
            overflowHidden = false,
            withScrollbars = true,
            scrollbarRef,
            children,
            scrollElementRef,
            scrollElementClassname,
            onScroll,
            className,
            ...boxProps
        },
        ref,
    ) => {
        const id = useId();

        return (
            <Box
                hasFooterShadow={hasFooterShadow}
                className={cx(
                    styles.box,
                    {[styles.fillHeight]: fillHeight},
                    {[styles.overflowHidden]: overflowHidden},
                    className,
                )}
                padding="none"
                headerContent={headerContent}
                headerContentBelowDivider={headerContentBelowDivider}
                footerContent={footerContent}
                footerPadding={footerPadding}
                headerPadding={headerPadding}
                ref={ref}
                {...boxProps}
            >
                {children && withScrollbars ? (
                    <Scrollbars
                        ref={scrollbarRef}
                        scrollElementRef={scrollElementRef}
                        className={cx(styles.scrollbar)}
                        ariaLabel={`Scrollable panel content ${id}`}
                        onScroll={onScroll}
                    >
                        <div
                            className={cx(
                                styles[sizeToPadding(padding)],
                                scrollElementClassname,
                            )}
                        >
                            {children}
                        </div>
                    </Scrollbars>
                ) : (
                    children
                )}
            </Box>
        );
    },
);

Panel.displayName = 'Panel';
export type PanelProps = React.ComponentProps<typeof Panel>;
