import {useLayoutEffect} from 'react';
import {useTranslation} from 'react-i18next';

import type {Media} from '@pexip/media';
import {isFallback, isFallbackAudio, isFallbackVideo} from '@pexip/media';
import type {Signal} from '@pexip/signal';

export const useFallbackDeviceNotifier = (
    mediaChangeSubscribe: Signal<Media | undefined>['add'],
    onMessageFallback: (message: string) => void,
) => {
    const {t} = useTranslation();

    useLayoutEffect(
        () =>
            mediaChangeSubscribe(media => {
                if (media?.status && isFallback(media.status)) {
                    const connector = t('media.input.connector', ' and ');
                    const messageParams = [
                        {
                            originalDeviceName: media.expectedVideoInput?.label,
                            fallbackDeviceName: media.videoInput?.label,
                            deviceType: t('media.input.video', 'camera'),
                            fallback:
                                isFallbackVideo(media.status) &&
                                media.expectedVideoInput?.label !==
                                    media.videoInput?.label,
                        },
                        {
                            originalDeviceName: media.expectedAudioInput?.label,
                            fallbackDeviceName: media.audioInput?.label,
                            deviceType: t('media.input.audio', 'microphone'),
                            fallback:
                                isFallbackAudio(media.status) &&
                                media.expectedAudioInput?.label !==
                                    media.audioInput?.label,
                        },
                    ].reduce(
                        (devices, device) => {
                            if (device.fallback) {
                                Object.keys(devices).forEach(key => {
                                    const deviceKey =
                                        key as keyof typeof devices;
                                    const value = device[deviceKey];
                                    if (value) {
                                        devices[deviceKey] = [
                                            devices[deviceKey],
                                            deviceKey !== 'deviceType'
                                                ? `'${value}'`
                                                : value,
                                        ]
                                            .filter(Boolean)
                                            .join(connector);
                                    }
                                });
                            }
                            return devices;
                        },
                        {
                            originalDeviceName: '',
                            fallbackDeviceName: '',
                            deviceType: '',
                        },
                    );
                    if (
                        !messageParams.fallbackDeviceName ||
                        !messageParams.originalDeviceName ||
                        !messageParams.deviceType
                    ) {
                        return;
                    }
                    const message = t('media.input.fallback-video', {
                        defaultValue:
                            "We couldn't use {{originalDeviceName}} so we switched your {{deviceType}} to {{fallbackDeviceName}}",
                        ...messageParams,
                    });

                    onMessageFallback(message);
                }
            }),
        [mediaChangeSubscribe, onMessageFallback, t],
    );
};
