import React, {useEffect, useRef, useState} from 'react';
import cx from 'classnames';

import {KEYBOARD_EVENT_KEY} from '../../../constants/keyboard';

import styles from './Draggable.module.scss';

export interface FloatRootOverflow {
    overflow: boolean;
    top?: boolean;
    bottom?: boolean;
    left?: boolean;
    right?: boolean;
}
export type AlignWithFloatRootTrigger = React.MutableRefObject<
    (() => void) | null
>;

export enum LockStyle {
    NONE,
    VERTICAL,
    HORIZONTAL,
}

const calculateScrollDirection = (
    component: HTMLDivElement,
    scrollElement: HTMLElement,
) => {
    const elementBottom = component.getBoundingClientRect().bottom;

    const elementTop = component.getBoundingClientRect().top;

    const containerBottom = scrollElement.getBoundingClientRect().bottom;

    const containerTop = scrollElement.getBoundingClientRect().top;

    return elementBottom >= containerBottom
        ? 'DOWN'
        : elementTop <= containerTop
          ? 'UP'
          : 'STOP';
};

export const Draggable = React.forwardRef<
    HTMLDivElement,
    React.ComponentProps<'div'> & {
        ariaLabel?: string;
        isDisabled?: boolean;
        isMoveableWithArrowKeys?: boolean;
        showFocus?: boolean;
        floatRoot?: React.RefObject<HTMLDivElement>;
        beforeReposition?: (element: HTMLDivElement) => void;
        onReposition?: (
            element: HTMLDivElement,
            floatRootOverflow: FloatRootOverflow,
        ) => void;
        onPointerUpExtra?: (element: HTMLDivElement) => void;
        onPointerMoveExtra?: (element: HTMLDivElement) => void;
        shouldCaptureClick?: boolean;
        scrollElementRef?: React.RefObject<HTMLElement>;
        scrollOffset?: number;
        scrollTimer?: number;
        alignWithFloatRootTrigger?: AlignWithFloatRootTrigger;
        lockStyle?: LockStyle;
        pointerCapture?: boolean;
        pointerCaptureTarget?: HTMLElement | null;
    }
>(
    (
        {
            ariaLabel = 'Draggable',
            isDisabled = false,
            isMoveableWithArrowKeys = false,
            showFocus = true,
            floatRoot = undefined,
            beforeReposition,
            onReposition,
            onPointerUpExtra,
            onPointerMoveExtra,
            shouldCaptureClick = true,
            alignWithFloatRootTrigger,
            scrollElementRef,
            scrollOffset = 10,
            scrollTimer = 30,
            lockStyle = LockStyle.NONE,
            pointerCapture = false,
            pointerCaptureTarget,
            children,
            className,
            ...props
        },
        ref,
    ) => {
        let componentRef = useRef<HTMLDivElement>(null);
        componentRef = (ref as React.RefObject<HTMLDivElement>) ?? componentRef;

        const [isDragging, setIsDragging] = useState(false);
        const wasMoved = useRef(false);

        useEffect(() => {
            if (!componentRef.current) {
                return;
            }
            const component = componentRef.current;
            setIsDragging(false);
            wasMoved.current = false;

            if (isDisabled) {
                return;
            }
            let componentRect = component.getBoundingClientRect();

            const scrollElement = scrollElementRef?.current ?? undefined;

            let startPosX = 0,
                startPosY = 0,
                transformX = 0,
                transformY = 0,
                parentRect: DOMRect | undefined,
                parentLeft = 0,
                parentTop = 0;

            // Scroll context
            let lastScrollTop = scrollElement?.scrollTop;
            let scrollDirection: 'UP' | 'DOWN' | 'STOP' = 'STOP';
            let scrollInterval: NodeJS.Timeout;
            let isAutoScrolling = false;
            let internalDraggingState = false;

            const cleanupDocumentListeners = () => {
                document.removeEventListener('pointermove', onPointerMove);
                document.removeEventListener('pointerup', onPointerUp);
                document.removeEventListener('keyup', onKeyUp);
            };

            const resetAutoScrolling = () => {
                clearInterval(scrollInterval);
                isAutoScrolling = false;
                scrollDirection = 'STOP';
            };

            const setInsetAsPercentages = () => {
                component.style.left = `${
                    ((component.offsetLeft + transformX) / window.innerWidth) *
                    100
                }%`;

                component.style.top = `${
                    ((component.offsetTop + transformY) / window.innerHeight) *
                    100
                }%`;
            };

            const setBeforeRepositionState = () => {
                componentRect = component.getBoundingClientRect();
                const offsetParent = component.offsetParent;
                parentRect = offsetParent?.getBoundingClientRect();
                parentLeft = parentRect?.left ?? 0;
                parentTop = parentRect?.top ?? 0;

                if (beforeReposition) {
                    beforeReposition(component);
                    // get new rect in case beforeReposition had side effects
                    componentRect = component.getBoundingClientRect();
                }
                // take control of the component
                component.style.left = `${componentRect.left - parentLeft}px`;
                component.style.top = `${componentRect.top - parentTop}px`;
                component.style.removeProperty('right');
                component.style.removeProperty('bottom');
                component.style.transform = 'none';
            };

            component.addEventListener('pointerdown', onPointerDown);
            isMoveableWithArrowKeys &&
                component.addEventListener('keydown', onKeyDown);
            scrollElement?.addEventListener('scroll', onScroll);

            // Wrapping state change for isDragging and internalDraggingState
            function setDraggingState(state: boolean) {
                setIsDragging(state);
                internalDraggingState = state;
            }

            function onKeyDown(e: KeyboardEvent) {
                if (e.target !== component) {
                    return;
                }

                const isArrowKey =
                    e.key === KEYBOARD_EVENT_KEY.arrowUp ||
                    e.key === KEYBOARD_EVENT_KEY.arrowRight ||
                    e.key === KEYBOARD_EVENT_KEY.arrowDown ||
                    e.key === KEYBOARD_EVENT_KEY.arrowLeft;

                if (!isArrowKey) {
                    return;
                }

                document.addEventListener('keyup', onKeyUp);

                if (!e.repeat) {
                    setBeforeRepositionState();
                }

                if (onPointerMoveExtra) {
                    onPointerMoveExtra(component);
                }
                /*
                 * If shift key is held down when user moves the
                 * draggable with an arrow key we move the element
                 * further than when they just press an arrow key alone.
                 */
                const multiplier = e.shiftKey ? 10 : 1;

                switch (e.key) {
                    case KEYBOARD_EVENT_KEY.arrowUp:
                        transformY = -10 * multiplier;
                        break;
                    case KEYBOARD_EVENT_KEY.arrowRight:
                        transformX = 10 * multiplier;
                        break;
                    case KEYBOARD_EVENT_KEY.arrowDown:
                        transformY = 10 * multiplier;
                        break;
                    case KEYBOARD_EVENT_KEY.arrowLeft:
                        transformX = -10 * multiplier;
                        break;
                    default:
                        // if none of arrow keys don't move the draggable.
                        return;
                }

                setInsetAsPercentages();
                if (transformX || transformY) {
                    wasMoved.current = true;
                }
                (transformX = 0), (transformY = 0);
            }

            function onPointerDown(event: PointerEvent) {
                if (pointerCapture) {
                    // Enable pointer capture to avoid cursor changes while dragging
                    (pointerCaptureTarget ?? component).setPointerCapture(
                        event.pointerId,
                    );
                }
                event.preventDefault();

                if (event.button !== 0) {
                    // only drag on left click
                    return;
                }

                setDraggingState(true);

                setBeforeRepositionState();

                startPosX = event.clientX;
                startPosY = event.clientY;

                document.addEventListener('pointermove', onPointerMove);
                document.addEventListener('pointerup', onPointerUp);
            }

            function onPointerMove(event: PointerEvent) {
                if (onPointerMoveExtra) {
                    onPointerMoveExtra(component);
                }

                if (lockStyle !== LockStyle.VERTICAL) {
                    transformX = event.clientX - startPosX;
                }

                if (lockStyle !== LockStyle.HORIZONTAL) {
                    transformY = event.clientY - startPosY;
                }

                // Do the default transformation if no scroll element is provided
                if (!scrollElement) {
                    component.style.transform = `translate(${transformX}px, ${transformY}px)`;
                }

                // Do scroll transformation and scrolling if scroll context is provided
                else {
                    scrollDirection = calculateScrollDirection(
                        component,
                        scrollElement,
                    );

                    // Stop interval if no scrolling is detected
                    if (scrollDirection === 'STOP') {
                        component.style.transform = `translate(${transformX}px, ${transformY}px)`;

                        if (isAutoScrolling) {
                            resetAutoScrolling();
                        }

                        /*
                         * Start interval if scrolling is detected and no interval is running yet.
                         * Scrolling needs to go on if the item is dragged over the upper or lower border.
                         * The mouse pointermove event will not be triggered if you do not move the mouse.
                         * Because of that an interval is used.
                         */
                    } else if (!isAutoScrolling) {
                        isAutoScrolling = true;
                        scrollInterval = setInterval(() => {
                            if (scrollElement) {
                                // Stop scrolling if item overflows top or bottom
                                if (
                                    (scrollDirection === 'UP' &&
                                        scrollElement.scrollTop === 0) ||
                                    (scrollDirection === 'DOWN' &&
                                        /*
                                         * That is necessary because scrollTop is not rounded and "may give you a decimal value on systems using display scaling" (e.g. zoom in / zoom out in browser)".
                                         * see https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollTop
                                         * see https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight
                                         * !!! Gets equal 1 if you zoom out a lot !!!
                                         */
                                        scrollElement.scrollHeight -
                                            scrollElement.clientHeight -
                                            scrollElement.scrollTop <=
                                            1)
                                ) {
                                    scrollDirection = 'STOP';
                                }
                                switch (scrollDirection) {
                                    // Handle scroll down
                                    case 'DOWN':
                                        scrollElement.scrollTop += scrollOffset;
                                        break;
                                    // Handle scroll up
                                    case 'UP':
                                        scrollElement.scrollTop -= scrollOffset;
                                        break;
                                    // Handle stop scrolling
                                    case 'STOP':
                                        component.style.transform = `translate(${transformX}px, ${transformY}px)`;
                                        resetAutoScrolling();
                                        break;
                                }
                            }
                        }, scrollTimer);
                    }
                }
            }

            function onScroll() {
                if (scrollElement?.scrollTop !== undefined) {
                    if (internalDraggingState && lastScrollTop !== undefined) {
                        const scrollMovement =
                            scrollElement.scrollTop - lastScrollTop;

                        if (lockStyle !== LockStyle.HORIZONTAL) {
                            transformY += scrollMovement;
                            startPosY -= scrollMovement;
                        }

                        component.style.transform = `translate(${transformX}px, ${transformY}px)`;
                    }
                    lastScrollTop = scrollElement.scrollTop;
                }
            }

            const alignWithFloatRoot = () => {
                let top = false,
                    bottom = false,
                    left = false,
                    right = false,
                    floatRootOverflow: FloatRootOverflow = {overflow: false};

                if (floatRoot?.current) {
                    componentRect = component.getBoundingClientRect();
                    const floatRootRect =
                        floatRoot.current.getBoundingClientRect();
                    // handle left
                    if (componentRect.left < floatRootRect.left) {
                        component.style.left = `${
                            floatRoot.current.offsetLeft - parentLeft
                        }px`;
                        left = true;
                    }
                    // handle right
                    if (componentRect.right > floatRootRect.right) {
                        component.style.left = `${
                            floatRootRect.right -
                            component.offsetWidth -
                            parentLeft
                        }px`;
                        right = true;
                    }
                    // handle top
                    if (componentRect.top < floatRootRect.top) {
                        component.style.top = `${
                            floatRoot.current.offsetTop - parentTop
                        }px`;
                        top = true;
                    }
                    //handle bottom
                    if (componentRect.bottom > floatRootRect.bottom) {
                        component.style.top = `${
                            floatRootRect.bottom -
                            component.offsetHeight -
                            parentTop
                        }px`;
                        bottom = true;
                    }

                    floatRootOverflow = {
                        overflow: top || bottom || left || right,
                        top,
                        bottom,
                        left,
                        right,
                    };
                    if (floatRootOverflow.overflow) {
                        setInsetAsPercentages();
                    }
                }
                return floatRootOverflow;
            };
            if (alignWithFloatRootTrigger) {
                alignWithFloatRootTrigger.current = () => {
                    const overflow = alignWithFloatRoot();
                    if (onReposition) {
                        onReposition(component, overflow);
                    }
                };
            }
            function alignWithNearestWindowXWall() {
                if (
                    componentRect.left + componentRect.width / 2 >
                    window.innerWidth / 2
                ) {
                    component.style.right = `${
                        ((window.innerWidth - componentRect.right) /
                            window.innerWidth) *
                        100
                    }%`;
                    component.style.left = 'auto';
                }
            }
            function alignWithNearestWindowYWall() {
                if (
                    componentRect.top + componentRect.height / 2 >
                    window.innerHeight / 2
                ) {
                    component.style.bottom = `${
                        ((window.innerHeight - componentRect.bottom) /
                            window.innerHeight) *
                        100
                    }%`;
                    component.style.top = 'auto';
                }
            }

            const stopDragging = () => {
                // Reset scrolling if mouse button is released
                resetAutoScrolling();

                if (onPointerUpExtra) {
                    onPointerUpExtra(component);
                }
                setInsetAsPercentages();
                component.style.removeProperty('transform');
                if (transformX || transformY) {
                    wasMoved.current = true;
                }
                (transformX = 0), (transformY = 0);
                cleanupDocumentListeners();

                const overflow = alignWithFloatRoot();
                if (!overflow.overflow) {
                    alignWithNearestWindowXWall();
                    alignWithNearestWindowYWall();
                }

                setDraggingState(false);

                if (onReposition) {
                    onReposition(component, overflow);
                }
            };

            function onPointerUp(event: PointerEvent) {
                if (pointerCapture) {
                    (pointerCaptureTarget ?? component).releasePointerCapture(
                        event.pointerId,
                    );
                }
                stopDragging();
            }

            function onKeyUp() {
                stopDragging();
            }

            return () => {
                cleanupDocumentListeners();
                component.removeEventListener('pointerdown', onPointerDown);
                isMoveableWithArrowKeys &&
                    component.removeEventListener('keydown', onKeyDown);
                scrollElement?.removeEventListener('scroll', onScroll);
                resetAutoScrolling();
                /**
                 * important that we remove the transform prop before we issue the callback
                 * because we are cancelling the drag changes and we don't want the component
                 * to have them when computing the callback
                 */
                component.style.removeProperty('transform');
                if (internalDraggingState) {
                    onPointerUpExtra?.(component);
                }
            };
        }, [
            isDisabled,
            isMoveableWithArrowKeys,
            floatRoot,
            beforeReposition,
            onReposition,
            onPointerUpExtra,
            alignWithFloatRootTrigger,
            onPointerMoveExtra,
            scrollTimer,
            scrollOffset,
            lockStyle,
            pointerCapture,
            scrollElementRef,
            pointerCaptureTarget,
        ]);

        return (
            <div
                role="region"
                aria-label={ariaLabel}
                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex -- needs to be tabbable
                tabIndex={isMoveableWithArrowKeys ? 0 : -1}
                ref={componentRef}
                className={cx(
                    {
                        [styles.draggable]: !isDisabled,
                        [styles.dragging]: isDragging,
                        [styles.shadowFocus]:
                            isMoveableWithArrowKeys && showFocus,
                    },
                    className,
                )}
                onClickCapture={
                    shouldCaptureClick
                        ? e => {
                              // shortcut the click event for clickable elements within the draggable wrapper when movement occurs
                              if (wasMoved.current) {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  wasMoved.current = false;
                              }
                          }
                        : undefined
                }
                {...props}
            >
                {children}
            </div>
        );
    },
);

Draggable.displayName = 'Draggable';

export type DraggableProps = React.ComponentProps<typeof Draggable>;
