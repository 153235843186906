import React from 'react';
import {useTranslation} from 'react-i18next';
import cx from 'classnames';

import {FontVariant, Row, Text} from '@pexip/components';
import type {MediaDeviceInfoLike, IndexedDevices} from '@pexip/media-control';

import {PlayingBackDeviceSelection} from './PlayingBackDeviceSelection.view';

import styles from './MicrophoneMonitorModal.module.scss';

export const PlayingBack: React.FC<
    React.PropsWithChildren<{
        devices: IndexedDevices;
        selectedAudioInput?: MediaDeviceInfoLike;
        selectedAudioOutput?: MediaDeviceInfoLike;
        onAudioInputChange: (id: string) => void;
        onOutputInputChange: (id: string) => void;
        tryAgain?: () => void;
    }>
> = ({
    devices,
    children,
    selectedAudioInput,
    selectedAudioOutput,
    onOutputInputChange,
    onAudioInputChange,
    tryAgain,
}) => {
    const {t} = useTranslation();

    return (
        <div>
            <Row className={cx('mt-2', styles.playbackDescriptionRow)}>
                <Text fontVariant={FontVariant.H6}>
                    {t(
                        'media.this-is-how-you-will-sound-to-others',
                        'This is how you will sound to others',
                    )}
                </Text>
            </Row>
            {children}
            <Row className="mt-2">
                <PlayingBackDeviceSelection
                    devices={devices}
                    selectedAudioInput={selectedAudioInput}
                    selectedAudioOutput={selectedAudioOutput}
                    onOutputInputChange={onOutputInputChange}
                    onAudioInputChange={onAudioInputChange}
                    tryAgain={tryAgain}
                />
            </Row>
        </div>
    );
};
