import type {DeviceError} from '../types';

export const onDeviceSelectChange =
    (
        setInputError: React.Dispatch<React.SetStateAction<DeviceError>>,
        updateInput?: (id: string) => void,
    ) =>
    (id: string) => {
        setInputError({title: ''});
        updateInput?.(id);
    };
