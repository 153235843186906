import {useRef, useLayoutEffect, useState} from 'react';

import type {AnalyzerNodeInit, AudioGraph} from '@pexip/media-processor';
import {
    createAnalyzerGraphNode,
    createAudioGraph,
    createStreamSourceGraphNode,
} from '@pexip/media-processor';
import {assert} from '@pexip/utils';

const createGraphAnalyzer = (track: MediaStreamTrack, fftSize?: number) => {
    assert(track, 'Audio Track must be provided');
    const sourceNode = createStreamSourceGraphNode(new MediaStream([track]));
    const analyzerNode = createAnalyzerGraphNode({
        fftSize,
    });
    const graph = createAudioGraph([[sourceNode, analyzerNode]]);
    return [graph, analyzerNode] as const;
};

/**
 *  Creates and returns `AnalyzerNodeInit` based on the main media's `rawStream`.
 *
 *  Note: The `main media` is used here and not the `preview media`, that used in Settings modal.
 *  `rawStream` is used here and not `mediaService.media.stream`, because the audio meter should
 *  keep measuring the sound, regardless if the main audio track is muted or not.
 */
export const useAnalyzer = (
    track: MediaStreamTrack | undefined,
    fftSize?: number,
) => {
    const audioGraph = useRef<AudioGraph | undefined>();
    const [analyzer, setAnalyzer] = useState<AnalyzerNodeInit>();

    useLayoutEffect(() => {
        let ignore = false;
        const updateState = (track: MediaStreamTrack) => {
            const [graph, analyzer] = createGraphAnalyzer(track, fftSize);
            audioGraph.current = graph;
            const analyzerNode = analyzer;
            if (!ignore) {
                setAnalyzer(analyzerNode);
            }
        };
        if (track) {
            updateState(track);
        }

        const cleanUp = () => {
            void audioGraph.current?.release?.();
            setAnalyzer(undefined);
        };

        return () => {
            cleanUp();
            ignore = true;
        };
    }, [fftSize, track]);

    return analyzer;
};
