import type {OverlayTriggerState} from '@react-stately/overlays';
import type {AriaPopoverProps} from '@react-aria/overlays';
import React from 'react';
import {usePopover, Overlay} from '@react-aria/overlays';
import cx from 'classnames';

import {ThemeProvider} from '../../../themes/ThemeContext';
import {Box} from '../../elements/Box/Box';
import type {ColorScheme} from '../../../types/variants';

import styles from './AriaMenu.module.scss';

export const InnerAriaMenuPopover: React.FC<
    Omit<AriaPopoverProps, 'popoverRef'> & {
        children: React.ReactNode;
        state: OverlayTriggerState;
        colorScheme?: ColorScheme;
    }
> = props => {
    const ref = React.useRef<HTMLDivElement>(null);
    const {state, children, colorScheme} = props;

    const {popoverProps, underlayProps} = usePopover(
        {
            ...props,
            popoverRef: ref,
        },
        state,
    );

    return (
        <Overlay>
            <div {...underlayProps} className={cx(styles.innerMenuUnderlay)} />
            <div role="complementary" {...popoverProps} ref={ref}>
                <ThemeProvider colorScheme={colorScheme ?? 'light'}>
                    <Box className={cx(styles.innerMenuPopover)}>
                        {children}
                    </Box>
                </ThemeProvider>
            </div>
        </Overlay>
    );
};
