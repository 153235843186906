import {createSignal} from '@pexip/signal';

import {logger} from './logger';
import type {
    DataChannelConfig,
    DataChannelInit,
    ExtendedRTCPeerConnection,
    GetSignalTypeFromInterface,
    LogFn,
    MediaConfig,
    MediaDirection,
    MediaInit,
    OnTransceiverChangeHandler,
    PCOptionalsSignals,
    PCRequiredSignals,
    PCSignals,
    PeerConnection,
    PeerConnectionSignals,
    RTCPeerConnectionEventListeners,
    References,
    TransceiverConfig,
    TransceiverInit,
} from './types';
import type {TransceiverMediaType} from './constants';
import {CAN_SET_STREAMS} from './constants';

/**
 * create a logger with reference attached
 *
 * @param getRefs - A function to the references for logging
 */
export const createRefsLog = (
    getRefs: () => Record<string, unknown>,
): {
    debug: LogFn;
    info: LogFn;
    error: LogFn;
    warn: LogFn;
} => {
    const info = (obj?: Record<string, unknown>) => {
        const refs = getRefs();
        return {...refs, ...obj};
    };
    return {
        debug: (msg, obj) => logger.debug(info(obj), msg),
        info: (msg, obj) => logger.info(info(obj), msg),
        error: (msg, obj) => logger.error(info(obj), msg),
        warn: (msg, obj) => logger.warn(info(obj), msg),
    };
};

/**
 * Get the states from RTCPeerConnection
 *
 * @param pc - the peer connection to get the states
 */
export const getPeerConnectionStates = (
    pc: PeerConnection | ExtendedRTCPeerConnection,
) => ({
    get connectionState() {
        return pc.connectionState;
    },
    get iceConnectionState() {
        return pc.iceConnectionState;
    },
    get iceGatheringState() {
        return pc.iceGatheringState;
    },
    get signalingState() {
        return pc.signalingState;
    },
});

/**
 * Get common states and props from PeerConnection
 *
 * @param pc - The peer connection to get the info
 */
export const getStatesAndProps = (pc: PeerConnection) => {
    return {
        ...getPeerConnectionStates(pc),
        offerOptions: pc.offerOptions,
        answerOptions: pc.answerOptions,
    };
};

export const logReferences = (refs: References) => ({
    module: refs.module,
    references: refs,
});

export const createGetRefs = (pc: PeerConnection) => () => ({
    ...logReferences(pc.references),
    ...getStatesAndProps(pc),
});

/**
 * Compare the provided 2 streams to check if they are the same
 * @param stream1 - the stream to compare
 * @param stream2 - the stream to compare
 */
export const isSameStream = (stream1?: MediaStream, stream2?: MediaStream) => {
    return stream1 && stream2 && stream1.id === stream2.id;
};

/**
 * JSONify RTCPeerConnectionIceErrorEvent so more info can be captured by logger
 */
const jsonifyIceErrorEvent = (event: Event) => {
    // Firefox does not support RTCPeerConnectionIceErrorEvent
    // https://bugzil.la/1561441
    if (
        'RTCPeerConnectionIceErrorEvent' in window &&
        event instanceof RTCPeerConnectionIceErrorEvent
    ) {
        return Object.defineProperty(event, 'toJSON', {
            configurable: true,
            enumerable: true,
            writable: true,
            value: () => ({
                address: event.address,
                url: event.url,
                port: event.port,
                errorCode: event.errorCode,
                errorText: event.errorText,
            }),
        });
    }
    return event;
};

/**
 * Wire the peer connection event with the pre-defined handler and signal
 * accordingly
 *
 * @param wireOptions - Wire event params
 * + `key` - event key
 * + `pc` - peer connection
 * + `signal` - the signal to wire
 */
export const wirePeerConnectionEventHandler = <
    T extends keyof PeerConnectionSignals,
>({
    key,
    pc,
    signal,
}: {
    key: T;
    pc: PeerConnection;
    signal: PeerConnectionSignals[T];
}) => {
    const log = createRefsLog(createGetRefs(pc));

    switch (key) {
        case 'onConnectionStateChange':
            pc.onConnectionStateChange = (event: Event) => {
                log.info('onConnectionStateChange emitted', {
                    event,
                });
                signal.emit(pc.connectionState);
            };
            break;
        case 'onDataChannel':
            pc.onDataChannel = (event: RTCDataChannelEvent) => {
                log.info('onDataChannel emitted', {
                    event,
                });
                signal.emit(event.channel);
            };
            break;
        case 'onIceCandidate':
            pc.onIceCandidate = (event: RTCPeerConnectionIceEvent) => {
                log.info('onIceCandidate emitted', {
                    event,
                });
                signal.emit(event.candidate);
            };
            break;
        case 'onIceCandidateError':
            pc.onIceCandidateError = (event: Event) => {
                log.info('onIceCandidateError emitted', {
                    event: jsonifyIceErrorEvent(event),
                });
                signal.emit(event);
            };
            break;
        case 'onIceConnectionStateChange':
            pc.onIceConnectionStateChange = (event: Event) => {
                log.info('onIceConnectionStateChange emitted', {
                    event,
                });
                signal.emit(pc.iceConnectionState);
            };
            break;
        case 'onIceGatheringStateChange':
            pc.onIceGatheringStateChange = (event: Event) => {
                log.info('onIceGatheringStateChange emitted', {
                    event,
                });
                signal.emit(pc.iceGatheringState);
            };
            break;
        case 'onNegotiationNeeded':
            pc.onNegotiationNeeded = () => {
                log.info('onNegotiationNeeded emitted');
                signal.emit();
            };
            break;
        case 'onSignalingStateChange':
            pc.onSignalingStateChange = (event: Event) => {
                log.info('onSignalingStateChange emitted', {
                    event,
                });
                signal.emit(pc.signalingState);
            };
            break;
        case 'onTrack':
            pc.onTrack = (event: RTCTrackEvent) => {
                log.info('onTrack emitted', {
                    event,
                });
                signal.emit(event);
            };
            break;
        case 'onRemoteStreams':
            pc.onRemoteStreams = (config: TransceiverConfig) => {
                log.info('onRemoteStreams emitted', {
                    config,
                });
                signal.emit(config);
            };
            break;
        case 'onTransceiverChange':
            pc.onTransceiverChange = () => {
                log.info('onTransceiverChanged');
                signal.emit();
            };
            break;
        case 'onSecureCheckCode':
            pc.onSecureCheckCode = (secureCheckCode: string) => {
                log.info('onSecureCheckCode emitted', {secureCheckCode});
                signal.emit(secureCheckCode);
            };
            break;
    }
};

/**
 * Wire the peer connection events with provided signals
 *
 * @param pc - peer connection
 * @param signals - the set of signals to wire
 */
export const wirePeerConnectionEvents = (
    pc: PeerConnection,
    signals: Partial<PeerConnectionSignals>,
) => {
    Object.keys(signals).forEach(eventKey => {
        const signalKey = eventKey as keyof PeerConnectionSignals;
        const signal = signals[signalKey];
        if (signal) {
            wirePeerConnectionEventHandler({
                key: signalKey,
                pc,
                signal,
            });
        }
    });
};

/**
 * Create a general signal with consistent scoped name
 *
 * @param name - Signal name
 * @param crucial - Signify if the signal is unmissable.
 */
export const createPCSignal = <T = undefined>(name: string, crucial = true) =>
    createSignal<T>({
        name: `call:peerConnection:${name}`,
        allowEmittingWithoutObserver: !crucial,
    });

export const REQUIRED_SIGNAL_KEYS = [
    'onOfferRequired',
    'onReceiveAnswer',
    'onReceiveOffer',
    'onOffer',
    'onOfferIgnored',
    'onAnswer',
    'onError',
] as const;

/**
 * Create and return all required and optional (if specified with `more`),
 * signals for peer connection to work
 *
 * @param more - Keys from `PCOptionalsSignals`, @see PCOptionalsSignals
 * @param scope - any scope prefix for the generated signal name, @see Signal
 *
 * The following signals created by default
 *  'onOfferRequired',
 *  'onReceiveAnswer',
 *  'onReceiveOffer',
 *  'onOffer',
 *  'onAnswer',
 *  'onError',
 * @see REQUIRED_SIGNAL_KEYS
 */
export const createPCSignals = <K extends keyof PCOptionalsSignals>(
    more: K[],
    scope = '',
) => {
    const signalScope = scope && [scope, ':'].join('');

    type Signals = PCRequiredSignals & Required<PCOptionalsSignals>;
    type SignalKeys =
        | (typeof more)[number]
        | (typeof REQUIRED_SIGNAL_KEYS)[number];

    return [...REQUIRED_SIGNAL_KEYS, ...more].reduce(
        (signals, key) => ({
            ...signals,
            [key]: createPCSignal<
                GetSignalTypeFromInterface<Signals, typeof key>
            >(`${signalScope}${key}`),
        }),
        {} as Pick<Signals, SignalKeys>,
    );
};

/**
 * Handle some core signals for the peer connection.
 *
 * @param peer - The peer connection
 *
 * @returns the signal subscriptions which are needed to be called when closing
 * the peer connection
 */
export const withSignals =
    (peer: PeerConnection) =>
    /**
     * Map signals
     *
     * @param signals - The provided signals to map to the PC events
     */
    ({
        onReceiveAnswer,
        onReceiveIceCandidate,
        onReceiveOffer,
        onOffer,
        onOfferIgnored,
        onAnswer,
        onError,
        onNegotiationNeeded = createPCSignal('onNegotiationNeeded'),
        onIceCandidateError = createPCSignal<RTCPeerConnectionIceErrorEvent>(
            'onIceCandidateError',
        ),
        ...pcEventSignals
    }: Omit<PCSignals, 'onOfferRequired'>) => {
        // Wire PeerConnection events to signals
        pcEventSignals &&
            wirePeerConnectionEvents(peer, {
                ...pcEventSignals,
                onNegotiationNeeded,
                onIceCandidateError,
            });

        // States for Perfect Negotiation
        const props = {
            receivingFirstOffer: true,
            makingOffer: false,
            ignoreOffer: false,
        };

        const log = createRefsLog(() => ({
            ...createGetRefs(peer)(),
            ...props,
        }));

        /**
         * Log and emit Error
         */
        const emitError: LogFn = (msg, context) => {
            log.error(msg, {...context, ...getPeerConnectionStates(peer)});
            if (context?.error instanceof Error) {
                onError.emit(context.error);
            }
        };

        const emitLocalDescription = (sdp?: RTCSessionDescriptionInit) => {
            const localDescription = sdp ?? peer.pendingLocalDescription;
            switch (localDescription?.type) {
                case 'offer':
                    onOffer.emit(localDescription);
                    break;
                case 'answer':
                    onAnswer.emit(localDescription);
                    break;
                default:
                    log.error(
                        'Attempt to emit localDescription other than "answer" and "offer"',
                        {localDescription},
                    );
                    throw new Error('Unknown SDP type');
            }
            peer.releaseLocalICECandidatesBuffer(false);
        };

        const signalSubscriptions = [
            onNegotiationNeeded.add(async () => {
                log.info('handle onNegotiationNeeded signal');
                try {
                    props.makingOffer = true;
                    const offer = await peer.createOffer();
                    log.info('emit offer', {offer});
                    emitLocalDescription(offer);
                } catch (error: unknown) {
                    if (error instanceof Error) {
                        if (error.message !== 'Ignore') {
                            emitError('createOffer', {error});
                        }
                    }
                } finally {
                    props.makingOffer = false;
                }
            }),

            onIceCandidateError.add(error => {
                // Sometimes the IceCandidateError could be just a noise, let's just log it instead
                emitError('onIceCandidateError', {
                    event: error,
                });
            }),

            onReceiveIceCandidate?.add(async candidate => {
                log.info('handle onReceiveIceCandidate signal', {candidate});
                try {
                    await peer.receiveIceCandidate(candidate);
                } catch (error) {
                    // Ignore receiving ICE error since we ignore the
                    // offer/answer as a polite peer, otherwise forward the error
                    if (!props.ignoreOffer) {
                        emitError('receiveIceCandidate', {error, candidate});
                    }
                }
            }),

            onReceiveOffer.add(async offer => {
                try {
                    const offerCollisionDetected =
                        props.makingOffer || peer.signalingState !== 'stable';

                    props.ignoreOffer = !peer.polite && offerCollisionDetected;
                    log.info('handle receiveOffer signal', {
                        politePeer: peer.polite,
                        offer,
                        offerCollisionDetected,
                        ignoreOffer: props.ignoreOffer,
                    });
                    if (props.ignoreOffer) {
                        log.info('ignore offer', {
                            politePeer: peer.polite,
                            offer,
                            offerCollisionDetected,
                            ignoreOffer: props.ignoreOffer,
                        });
                        onOfferIgnored.emit();
                        return;
                    }
                    peer.releaseLocalICECandidatesBuffer(true);
                    await peer.receiveOffer(offer);
                    const answer = await peer.createAnswer();
                    log.info('emit answer', {answer});
                    emitLocalDescription(answer);
                } catch (error: unknown) {
                    emitError('receiveOffer/createAnswer', {error, offer});
                } finally {
                    props.receivingFirstOffer = false;
                }
            }),

            onReceiveAnswer.add(async answer => {
                log.info('handle receiveAnswer signal', {answer});
                props.receivingFirstOffer = false;
                try {
                    await peer.receiveAnswer(answer);
                } catch (error) {
                    emitError('receiveAnswer', {error, answer});
                }
            }),
        ].flatMap(a => (a ? [a] : []));
        return signalSubscriptions;
    };

/**
 *  workaround to allow echo cancellation in Chromium browsers, due to https://bugs.chromium.org/p/chromium/issues/detail?id=687574.
 *
 * based on https://dev.to/focusedlabs/echo-cancellation-with-web-audio-api-and-chromium-1f8m
 *  and https://gist.github.com/alexciarlillo/4b9f75516f93c10d7b39282d10cd17bc
 */
export const getCreateLoopbackConnectionFn =
    (
        rtcConnection = new RTCPeerConnection(),
        rtcLoopbackConnection = new RTCPeerConnection(),
        loopbackStream = new MediaStream(),
    ) =>
    async (stream: MediaStream) => {
        rtcConnection.onicecandidate = e =>
            e.candidate &&
            rtcLoopbackConnection.addIceCandidate(
                new RTCIceCandidate(e.candidate),
            );
        rtcLoopbackConnection.onicecandidate = e =>
            e.candidate &&
            rtcConnection.addIceCandidate(new RTCIceCandidate(e.candidate));

        rtcLoopbackConnection.ontrack = e => {
            if (e.streams[0]) {
                return e.streams[0]
                    .getTracks()
                    .forEach(track => loopbackStream.addTrack(track));
            }
        };

        stream.getTracks().forEach(function (track) {
            rtcConnection.addTrack(track, stream);
        });

        const offer = await rtcConnection.createOffer();
        await rtcConnection.setLocalDescription(offer);
        await rtcLoopbackConnection.setRemoteDescription(offer);

        const answer = await rtcLoopbackConnection.createAnswer();
        await rtcLoopbackConnection.setLocalDescription(answer);
        await rtcConnection.setRemoteDescription(answer);

        return loopbackStream;
    };

export const isTransceiverMediaType = (
    mediaType: unknown,
): mediaType is TransceiverMediaType =>
    mediaType === 'audio' || mediaType === 'video';

export const isMediaType = (
    t: unknown,
): t is TransceiverMediaType | 'application' => {
    if (isTransceiverMediaType(t) || t === 'application') {
        return true;
    }
    return false;
};

export const assertTransceiverMediaType = (mediaType: unknown) => {
    if (!isTransceiverMediaType(mediaType)) {
        throw new Error(
            `Expected a media kind string, "audio" | "video" but got "${mediaType}"`,
        );
    }
    return mediaType;
};

export const resolveKindOrTrack = (
    kindOrTrack: MediaStreamTrack | TransceiverMediaType | undefined,
): Pick<TransceiverConfig, 'kind' | 'track'> => {
    if (
        !('MediaStreamTrack' in window) ||
        !(kindOrTrack instanceof MediaStreamTrack)
    ) {
        return {
            kind: assertTransceiverMediaType(kindOrTrack),
            track: undefined,
        };
    }
    return {
        track: kindOrTrack,
        kind: assertTransceiverMediaType(kindOrTrack.kind),
    };
};

const isMediaStream = (t: unknown): t is MediaStream => {
    if (typeof t === 'object' && t !== null && t instanceof MediaStream) {
        return true;
    }
    return false;
};
const isMediaStreams = (t: unknown): t is MediaStream[] =>
    Array.isArray(t) && t.every(isMediaStream);

const isDataChannelObsolete = (dataChannel: RTCDataChannel) =>
    dataChannel.readyState === 'closing' || dataChannel.readyState === 'closed';

interface DataChannelConfigProps extends RTCDataChannelInit {
    dataChannel?: RTCDataChannel;
}
const createDataChannelConfig = (init: DataChannelInit): DataChannelConfig => {
    const props: DataChannelConfigProps = {
        ...init,
    };

    const unsubscribeEvents = (dc: RTCDataChannel) => {
        init.eventListeners?.forEach(({event, listener, options}) => {
            // To make tsc happy
            switch (event) {
                case 'message':
                    dc.removeEventListener(event, listener, options);
                    break;
                default:
                    dc.removeEventListener(event, listener, options);
                    break;
            }
        });
    };

    const subscribeEvents = (dc: RTCDataChannel) => {
        init.eventListeners?.forEach(({event, listener, options}) => {
            // To make tsc happy
            switch (event) {
                case 'message':
                    dc.addEventListener(event, listener, options);
                    break;
                default:
                    dc.addEventListener(event, listener, options);
                    break;
            }
        });
        dc.addEventListener('close', () => unsubscribeEvents(dc), {once: true});
    };

    const isDirty = () =>
        !props.dataChannel || isDataChannelObsolete(props.dataChannel);

    const release = () => {
        props.dataChannel && unsubscribeEvents(props.dataChannel);
    };

    return {
        get dirty() {
            return isDirty();
        },
        get options() {
            return init;
        },
        get kind() {
            return 'application' as const;
        },
        get dataChannel() {
            return props.dataChannel;
        },
        set dataChannel(dc) {
            if (dc) {
                subscribeEvents(dc);
            }
            props.dataChannel = dc;
        },
        release,
        syncDataChannel: peer => {
            if (!isDirty()) {
                return;
            }

            logger.debug({init, props}, 'syncDataChannel');
            const dc = peer.createDataChannel(init.label, init);
            props.dataChannel = dc;
            subscribeEvents(dc);
        },
        toString() {
            return [
                this.options.label,
                `DataChannel${
                    this.options.id !== undefined ? `(${this.options.id})` : ''
                }`,
            ].join('-');
        },
    };
};

export const isTransceiverObsolete = (
    transceiver: RTCRtpTransceiver,
): boolean => {
    return transceiver.mid === null && transceiver.currentDirection !== null;
};

/**
 * Compare 2 flat records (an object with primitive type) using Object.is comparator
 *
 * @remarks No deep comparison
 */
export const compareRecord = <T extends object>(
    record1: T | undefined,
    record2: T | undefined,
): boolean => {
    if (record1 === record2) {
        return true;
    }
    if (!record1 || !record2) {
        return false;
    }
    const keys = new Set([...Object.keys(record1), ...Object.keys(record2)]);
    for (const key of keys) {
        if (record1[key as keyof T] !== record2[key as keyof T]) {
            return false;
        }
    }
    return true;
};

/**
 * Compare 2 Array and using the provided predicate to compare
 */
export const compareArray = <T>(
    list1: T[] | undefined,
    list2: T[] | undefined,
    predicate: (a: T | undefined, b: T | undefined) => boolean,
): boolean => {
    if (
        (list1 === undefined && list2 !== undefined) ||
        (list1 !== undefined && list2 === undefined)
    ) {
        return false;
    }
    const len = Math.max(list1?.length ?? 0, list2?.length ?? 0);
    for (let i = 0; i < len; i++) {
        const a = list1?.[i];
        const b = list2?.[i];
        if (!predicate(a, b)) {
            return false;
        }
    }
    return true;
};

/**
 * Merge two array and overwriting the old on with the new one in order
 */
export const merge = <T extends object[]>(oldParam: T, newParam: T) => {
    const len = Math.max(oldParam.length, newParam.length);
    const merged: RTCRtpEncodingParameters[] = [];
    for (let i = 0; i < len; i++) {
        const o = oldParam[i];
        const n = newParam[i];
        if (!n) {
            return merged;
        }
        merged.push({...o, ...n});
    }
    return merged;
};

interface TransceiverConfigProps {
    content: string;
    track?: MediaStreamTrack | null;
    kind: TransceiverMediaType;
    transceiver?: RTCRtpTransceiver;
    streams: MediaStream[];
    remoteStreams?: MediaStream[];
    direction: MediaDirection;
    allowAutoChangeOfDirection: boolean;
    relativeDirection: boolean;
    sendEncodings?: RTCRtpEncodingParameters[];
}
const createTransceiverConfig = (
    {
        kindOrTrack,
        content,
        direction,
        streams,
        sendEncodings,
        transceiver,
        allowAutoChangeOfDirection = true,
        relativeDirection = true,
    }: TransceiverInit,
    onTransceiverChanged: (
        trans: RTCRtpTransceiver,
        config: TransceiverConfig,
    ) => void,
): TransceiverConfig => {
    const {track, kind} = resolveKindOrTrack(kindOrTrack);
    const subscribeStreamTrackEvents = (stream: MediaStream) => {
        const handleAddTrack = (event: MediaStreamTrackEvent) => {
            if (event.track.kind === config.kind) {
                config.track = event.track;
            }
            syncSenderTrack().catch((error: unknown) => {
                logger.error({error, track, stream}, 'failed to sync sender');
            });
        };
        const handleRemoveTrack = (event: MediaStreamTrackEvent) => {
            if (
                event.track.kind === config.kind &&
                config.track === event.track
            ) {
                config.track = null;
            }
            syncSenderTrack().catch((error: unknown) => {
                logger.error({error, track, stream}, 'failed to sync sender');
            });
        };
        stream.addEventListener('addtrack', handleAddTrack);
        stream.addEventListener('removetrack', handleRemoveTrack);
        return () => {
            stream.removeEventListener('addtrack', handleAddTrack);
            stream.removeEventListener('removetrack', handleRemoveTrack);
        };
    };
    const streamSubscriptions = new Map(
        streams?.map(stream => [stream, subscribeStreamTrackEvents(stream)]),
    );
    const props: TransceiverConfigProps = {
        content: content ?? 'main',
        track,
        kind,
        streams: streams ?? [],
        direction: direction ?? 'sendrecv',
        transceiver,
        allowAutoChangeOfDirection,
        relativeDirection,
        sendEncodings,
    };
    const dirty = {
        track: transceiver ? false : Boolean(track),
        streams: transceiver ? false : Boolean(streams?.length),
        sendParameters: transceiver ? false : Boolean(sendEncodings),
        direction: allowAutoChangeOfDirection,

        get dirty() {
            return (
                this.track ||
                this.streams ||
                this.direction ||
                this.sendParameters
            );
        },
    };

    const proxy = new Proxy(props, {
        // eslint-disable-next-line max-params --- from lib.dom.d
        set(target, p: keyof TransceiverConfigProps, newValue, receiver) {
            switch (p) {
                case 'track': {
                    if (newValue !== undefined && target[p] !== newValue) {
                        Reflect.set(target, p, newValue, receiver);
                        dirty[p] = true;
                    }
                    return true;
                }
                case 'streams': {
                    if (newValue !== undefined && isMediaStreams(newValue)) {
                        const setOld = new Set(target[p].map(s => s.id));
                        const setNew = new Set(newValue.map(s => s.id));
                        for (const old of setOld) {
                            if (setNew.has(old)) {
                                return true;
                            }
                        }
                        // Remove the old subscriptions
                        for (const stream of target[p]) {
                            streamSubscriptions.get(stream)?.();
                            streamSubscriptions.delete(stream);
                        }
                        // Update the new subscriptions
                        for (const stream of newValue) {
                            streamSubscriptions.set(
                                stream,
                                subscribeStreamTrackEvents(stream),
                            );
                        }
                        target[p] = newValue;
                        dirty[p] = true;
                    }
                    return true;
                }
                case 'direction': {
                    const newDirection = deriveSendDirectionFromTrack(
                        newValue,
                        props.transceiver?.sender.track ?? props.track,
                    );
                    if (target[p] !== newDirection) {
                        Reflect.set(target, p, newDirection, receiver);
                        dirty[p] = true;
                    }
                    return true;
                }
                case 'transceiver': {
                    if (
                        newValue !== undefined &&
                        newValue instanceof RTCRtpTransceiver &&
                        target[p] !== newValue
                    ) {
                        target[p]?.stop();
                        if (target.track) {
                            dirty.track = true;
                        }
                        if (target.streams.length) {
                            dirty.streams = true;
                        }
                        if (target.direction !== newValue.direction) {
                            dirty.direction = true;
                        }
                        Reflect.set(target, p, newValue, receiver);
                        onTransceiverChanged(newValue, config);
                    }
                    return true;
                }
                case 'sendEncodings': {
                    if (
                        newValue !== undefined &&
                        !compareArray(target[p], newValue, compareRecord)
                    ) {
                        Reflect.set(target, p, newValue, receiver);
                        dirty.sendParameters = true;
                    }
                    return true;
                }
                default: {
                    Reflect.set(target, p, newValue, receiver);
                    return true;
                }
            }
        },
    });

    const syncStreams: TransceiverConfig['syncStreams'] = () => {
        if (
            !CAN_SET_STREAMS ||
            !dirty.streams ||
            !props.track ||
            !props.transceiver ||
            isTransceiverObsolete(props.transceiver)
        ) {
            return;
        }
        dirty.streams = false;
        logger.debug({config, props, dirty}, 'syncStreams');
        props.transceiver.sender.setStreams(...props.streams);
    };
    const syncDirection: TransceiverConfig['syncDirection'] = () => {
        if (
            !props.allowAutoChangeOfDirection ||
            !dirty.direction ||
            !props.transceiver ||
            isTransceiverObsolete(props.transceiver)
        ) {
            return;
        }
        if (props.transceiver.direction === props.direction) {
            return;
        }
        dirty.direction = false;
        logger.debug({config, props, dirty}, 'syncDirection');
        props.transceiver.direction = props.direction;
    };
    const syncSenderTrack: TransceiverConfig['syncSenderTrack'] = async () => {
        if (
            !props.transceiver ||
            isTransceiverObsolete(props.transceiver) ||
            !dirty.track ||
            props.track === undefined ||
            props.track === props.transceiver?.sender.track
        ) {
            return;
        }
        dirty.track = false;
        logger.debug({config, props, dirty}, 'syncSenderTrack');
        await props.transceiver.sender.replaceTrack(props.track);
        syncStreams();
    };

    const syncSenderParameters: TransceiverConfig['syncSenderParameters'] =
        async () => {
            const encodings = props.sendEncodings;
            const params = props.transceiver?.sender.getParameters();
            // https://developer.mozilla.org/en-US/docs/Web/API/RTCRtpSender/setParameters#currently_compatible_implementation
            if (params && !params.encodings) {
                params.encodings = [{}];
            }
            if (
                !props.transceiver ||
                isTransceiverObsolete(props.transceiver) ||
                !dirty.sendParameters ||
                encodings === undefined ||
                params === undefined ||
                params.encodings.every((param, idx) =>
                    compareRecord(param, encodings[idx]),
                )
            ) {
                return;
            }
            dirty.sendParameters = false;
            logger.debug({config, props, dirty}, 'syncSenderTrack');
            await props.transceiver.sender.setParameters({
                ...params,
                encodings: merge(params.encodings, encodings),
            });
        };

    const isDirty = () =>
        dirty.dirty ||
        !props.transceiver ||
        isTransceiverObsolete(props.transceiver);

    const release = () => {
        for (const unsubscribe of streamSubscriptions.values()) {
            unsubscribe();
        }
        streamSubscriptions.clear();
    };

    const config: TransceiverConfig = {
        get content() {
            return props.content;
        },
        get dirty() {
            return isDirty();
        },
        get kind() {
            return props.kind;
        },
        get allowAutoChangeOfDirection() {
            return props.allowAutoChangeOfDirection;
        },
        set allowAutoChangeOfDirection(allowed: boolean) {
            props.allowAutoChangeOfDirection = allowed;
        },
        get relativeDirection() {
            return props.relativeDirection;
        },
        set relativeDirection(relative) {
            props.relativeDirection = relative;
        },
        get direction() {
            return props.direction;
        },
        set direction(direction: MediaDirection) {
            proxy.direction = direction;
        },
        get sendEncodings() {
            return props.sendEncodings;
        },
        set sendEncodings(encodings) {
            proxy.sendEncodings = encodings;
        },
        get streams() {
            return props.streams;
        },
        set streams(streams) {
            proxy.streams = streams;
        },
        get remoteStreams() {
            return props.remoteStreams;
        },
        set remoteStreams(streams) {
            props.remoteStreams = streams;
        },
        get transceiver() {
            return props.transceiver;
        },
        set transceiver(transceiver) {
            proxy.transceiver = transceiver;
        },
        get track() {
            return props.track;
        },
        set track(track) {
            proxy.track = track;
        },
        release,
        syncStreams,
        syncDirection,
        syncSenderTrack,
        syncSenderParameters,
        syncTransceiver: async (peer, option = {}) => {
            if (option.track !== undefined) {
                proxy.track = option.track;
            }
            if (option.streams) {
                proxy.streams = option.streams;
            }
            if (option.direction) {
                proxy.direction = option.direction;
            }
            if (option.sendEncodings) {
                proxy.sendEncodings = option.sendEncodings;
            }

            if (!isDirty()) {
                return;
            }

            if (
                props.transceiver &&
                !isTransceiverObsolete(props.transceiver)
            ) {
                await syncSenderTrack();
                syncDirection();
                await syncSenderParameters();
            } else {
                logger.debug({config, props, dirty}, 'addTransceiver');
                dirty.track = false;
                dirty.streams = false;
                dirty.direction = false;
                proxy.transceiver = peer.addTransceiver(
                    props.track ?? props.kind,
                    {
                        direction: props.direction,
                        streams: props.streams,
                        sendEncodings: props.sendEncodings,
                    },
                );
            }
        },
        toString() {
            return [this.content, this.kind, this.transceiver?.mid]
                .filter(Boolean)
                .join('-');
        },
    };
    return config;
};

export const isTransceiverInit = (t: unknown): t is TransceiverInit => {
    if (typeof t === 'object' && t && 'kindOrTrack' in t) {
        return true;
    }
    return false;
};
export const isTransceiverConfig = (t: unknown): t is TransceiverConfig => {
    if (
        typeof t === 'object' &&
        t &&
        'kind' in t &&
        typeof t.kind === 'string' &&
        ['audio', 'video'].includes(t.kind)
    ) {
        return true;
    }
    return false;
};

export const isDataChannelInit = (t: unknown): t is DataChannelInit => {
    if (
        typeof t === 'object' &&
        t &&
        'label' in t &&
        typeof t.label === 'string'
    ) {
        return true;
    }
    return false;
};
export const isDataChannelConfig = (t: unknown): t is DataChannelConfig => {
    if (
        typeof t === 'object' &&
        t &&
        'kind' in t &&
        typeof t.kind === 'string' &&
        t.kind === 'application'
    ) {
        return true;
    }
    return false;
};

export const isMediaInit = (t: unknown): t is MediaInit =>
    isTransceiverInit(t) || isDataChannelInit(t);
export const isMediaConfig = (t: unknown): t is MediaConfig =>
    isTransceiverConfig(t) || isDataChannelConfig(t);

export const createMediaConfigs = (
    mediaInits?: MediaInit[],
    onTransceiverChanged?: OnTransceiverChangeHandler,
) => {
    const map = new Map<
        RTCRtpTransceiver | RTCDataChannel,
        TransceiverConfig | DataChannelConfig
    >();
    const handleTransceiverChanged = (
        trans: RTCRtpTransceiver,
        config: TransceiverConfig,
    ) => {
        map.set(trans, config);
        logger.debug(
            {map, transceiver: trans, config},
            'handleTransceiverChanged',
        );
        onTransceiverChanged?.();
    };

    let mediaConfigs =
        mediaInits?.map(init => {
            if (isTransceiverInit(init)) {
                const config = createTransceiverConfig(
                    init,
                    handleTransceiverChanged,
                );
                return config;
            }
            return createDataChannelConfig(init);
        }) ?? [];

    function getConfig(key: RTCRtpTransceiver): TransceiverConfig | undefined;
    function getConfig(key: RTCDataChannel): DataChannelConfig | undefined;
    function getConfig(
        key: RTCRtpTransceiver | RTCDataChannel,
    ): TransceiverConfig | DataChannelConfig | undefined {
        return map.get(key);
    }

    function addConfig(
        peer: RTCPeerConnection,
        initOrConfig: DataChannelInit | DataChannelConfig,
    ): DataChannelConfig;
    function addConfig(
        peer: RTCPeerConnection,
        initOrConfig: TransceiverInit | TransceiverConfig,
    ): TransceiverConfig;
    function addConfig(
        peer: RTCPeerConnection,
        initOrConfig:
            | TransceiverInit
            | TransceiverConfig
            | DataChannelInit
            | DataChannelConfig,
    ): TransceiverConfig | DataChannelConfig {
        if (isTransceiverInit(initOrConfig)) {
            const trackOrKind = initOrConfig.kindOrTrack;
            const transceiver = initOrConfig.transceiver
                ? initOrConfig.transceiver
                : peer.addTransceiver(trackOrKind, initOrConfig);
            const config = createTransceiverConfig(
                {...initOrConfig, transceiver},
                handleTransceiverChanged,
            );
            mediaConfigs.push(config);
            handleTransceiverChanged(transceiver, config);
            return config;
        }
        if (isTransceiverConfig(initOrConfig)) {
            const trackOrKind = initOrConfig.track ?? initOrConfig.kind;
            const transceiver =
                initOrConfig.transceiver ??
                peer.addTransceiver(trackOrKind, initOrConfig);
            mediaConfigs.push(initOrConfig);
            handleTransceiverChanged(transceiver, initOrConfig);
            return initOrConfig;
        }
        if (isDataChannelInit(initOrConfig)) {
            const dataChannel =
                initOrConfig.dataChannel ??
                peer.createDataChannel(initOrConfig.label, initOrConfig);
            const config = createDataChannelConfig({
                ...initOrConfig,
                dataChannel,
            });
            mediaConfigs.push(config);
            return config;
        }
        if (!initOrConfig.dataChannel) {
            peer.createDataChannel(
                initOrConfig.options.label,
                initOrConfig.options,
            );
        }
        mediaConfigs.push(initOrConfig);
        return initOrConfig;
    }

    return {
        get configs() {
            return mediaConfigs;
        },
        addConfig,
        getConfig,
        release() {
            for (const config of mediaConfigs) {
                config.release();
            }
            mediaConfigs = [];
        },
        find: (
            predicate: (
                config: TransceiverConfig | DataChannelConfig,
            ) => boolean,
        ) => mediaConfigs.find(predicate),
    };
};

/**
 * Try to derive the direction from `currentDirection` and the `intendedDirection`
 */
export const changeTransceiverDirection = (
    currentDirection: RTCRtpTransceiverDirection,
    intendedDirection: 'send' | 'recv' | MediaDirection = 'send',
): MediaDirection => {
    if (intendedDirection !== 'send' && intendedDirection !== 'recv') {
        return intendedDirection;
    }
    switch (currentDirection) {
        case 'inactive': {
            return intendedDirection === 'send' ? 'sendonly' : 'recvonly';
        }
        case 'recvonly': {
            return intendedDirection === 'send' ? 'sendrecv' : 'recvonly';
        }
        case 'sendonly': {
            return intendedDirection === 'send' ? 'sendonly' : 'sendrecv';
        }
        case 'sendrecv':
        default:
            return 'sendrecv';
    }
};

/**
 * Get the relative direction so you can get the correct direction, e.g.
 * `"sendonly" <--> "recvonly"`
 *
 * From https://www.rfc-editor.org/rfc/rfc3264.html#section-6.1
 * \> If a stream is offered as sendonly, the corresponding stream MUST be
 * \> marked as recvonly or inactive in the answer.  If a media stream is
 * \> listed as recvonly in the offer, the answer MUST be marked as
 * \> sendonly or inactive in the answer.  If an offered media stream is
 * \> listed as sendrecv (or if there is no direction attribute at the
 * \> media or session level, in which case the stream is sendrecv by
 * \> default), the corresponding stream in the answer MAY be marked as
 * \> sendonly, recvonly, sendrecv, or inactive.  If an offered media
 * \> stream is listed as inactive, it MUST be marked as inactive in the
 * \> answer.
 */
export const getRelativeDirection = (
    remoteDirection: MediaDirection | undefined,
): MediaDirection => {
    switch (remoteDirection) {
        case 'sendonly':
            return 'recvonly';
        case 'recvonly':
            return 'sendonly';
        case 'inactive':
            return 'inactive';
        case 'sendrecv':
        default:
            // if there is no direction attribute at the media or session level,
            // the stream is `sendrecv` by default
            return 'sendrecv';
    }
};

export const deriveSendDirectionFromTrack = (
    intendedDirection: MediaDirection,
    track: MediaStreamTrack | undefined | null,
): MediaDirection => {
    const hasTrack = Boolean(track);
    if (hasTrack || !intendedDirection.includes('send')) {
        return intendedDirection;
    }
    switch (intendedDirection) {
        case 'sendonly':
            return 'inactive';
        case 'sendrecv':
            return 'recvonly';
        default:
            return intendedDirection;
    }
};

/**
 * Create a queue to handle buffering and trigger provided `callback` being
 * called in order.
 *
 * @param callback - The callback being called
 */
export const createEventQueue = <T>(callback: (item: T) => void) => {
    const queue: T[] = [];
    let buffering = true;

    return {
        get buffering() {
            return buffering;
        },
        set buffering(value) {
            buffering = value;
        },
        get length() {
            return queue.length;
        },
        get items() {
            return [...queue];
        },
        /**
         * Put an item to the end of the queue. When the attribute `buffering`
         * is `false`, it triggers the `callback` immediately instead of putting
         * the item into the queue.
         *
         * @param item - The item to put into the queue
         */
        enqueue: (item: T) => {
            if (buffering) {
                queue.push(item);
            } else {
                for (const item of queue) {
                    callback(item);
                }
                callback(item);
            }
        },
        /**
         * Empty the queue by running the callback with all the items in the
         * queue in-order, and returns the items;
         */
        flush: () =>
            queue.splice(0, queue.length).map(item => {
                callback(item);
                return item;
            }),
        /**
         * Discard all the items without any side effects
         */
        discard: () => {
            queue.length = 0;
        },
    };
};

export const subscribePCEvents = (
    pc: RTCPeerConnection,
    listeners: RTCPeerConnectionEventListeners,
) => {
    listeners.forEach(({event, listener, options}) => {
        // To make tsc happy
        switch (event) {
            case 'datachannel': {
                pc.addEventListener(event, listener, options);
                break;
            }
            case 'icecandidate': {
                pc.addEventListener(event, listener, options);
                break;
            }
            case 'track': {
                pc.addEventListener(event, listener, options);
                break;
            }
            default: {
                pc.addEventListener(event, listener, options);
                break;
            }
        }
    });
    pc.addEventListener(
        'close',
        () => {
            listeners.forEach(({event, listener, options}) => {
                // To make tsc happy
                switch (event) {
                    case 'datachannel': {
                        pc.removeEventListener(event, listener, options);
                        break;
                    }
                    case 'icecandidate': {
                        pc.removeEventListener(event, listener, options);
                        break;
                    }
                    case 'track': {
                        pc.removeEventListener(event, listener, options);
                        break;
                    }
                    default: {
                        pc.removeEventListener(event, listener, options);
                        break;
                    }
                }
            });
        },
        {once: true},
    );
};

/**
 * A wrapper to create a RTCPeerConnection instance and subscribe the events
 * declared from the provided list of `listeners`.
 *
 * @param options - The configuration for the `RTCPeerConnection`
 * @param listeners - A list of listeners to listen on the Peer Connection
 * events
 * @param existingPC - Instead of creating a new Peer Connection, it always uses
 * this instance instead.
 */
export const createRTCPeerConnection = (
    options?: RTCConfiguration,
    listeners?: RTCPeerConnectionEventListeners,
    existingPC?: RTCPeerConnection,
) => {
    const pc = (existingPC ??
        new RTCPeerConnection(options)) as ExtendedRTCPeerConnection;
    listeners && subscribePCEvents(pc, listeners);
    return pc;
};
