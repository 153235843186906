import cx from 'classnames';
import React, {useCallback, useState, useEffect, useRef} from 'react';

import type {
    FloatRootOverflow,
    AlignWithFloatRootTrigger,
} from '@pexip/components';
import {Draggable} from '@pexip/components';

import {
    desktopToolbarWidth,
    interfacesMargin,
    sidePanelPlusDesktopToolbarWidth,
} from '../../constants';
import {
    isControlsOverlapTruthy,
    isOverlappingSidePanel,
    useControlsRelativePosition,
    useSidePanelTransform,
} from '../../hooks';
import type {AutoHideButtonCallbacks} from '../../types';

import styles from './InMeetingDraggable.module.scss';

export interface InMeetingDraggablePosition {
    floatRootOverflow: FloatRootOverflow;
}

export const InMeetingDraggable = React.forwardRef<
    HTMLDivElement,
    React.ComponentProps<'div'> & {
        draggableAriaLabel: string;
        autoHideProps?: AutoHideButtonCallbacks;
        isMoveableWithArrowKeys?: boolean;
        showFocus?: boolean;
        isSidePanelVisible: boolean;
        isSidePanelTransformEnabled?: boolean;
        isDisabled?: boolean;
        alwaysAllowStyles?: boolean;
        floatRoot?: React.RefObject<HTMLDivElement>;
        center?: boolean;
        isPhone?: boolean;
        beforeRepositionSideEffect?: () => void;
        onRepositionSideEffect?: (
            el: HTMLDivElement,
            position: InMeetingDraggablePosition,
        ) => void;
        shouldCaptureClick?: boolean;
        alignWithFloatRootTrigger?: AlignWithFloatRootTrigger;
        pointerCapture?: boolean;
        pointerCaptureTarget?: HTMLElement | null;
        style?: React.CSSProperties;
    }
>(
    (
        {
            draggableAriaLabel = '',
            autoHideProps,
            center = false,
            isMoveableWithArrowKeys,
            isSidePanelVisible,
            isSidePanelTransformEnabled = true,
            isDisabled,
            alwaysAllowStyles = false,
            floatRoot,
            isPhone,
            className,
            beforeRepositionSideEffect,
            onRepositionSideEffect,
            shouldCaptureClick = true,
            showFocus = true,
            alignWithFloatRootTrigger,
            pointerCapture,
            pointerCaptureTarget,
            children,
            style,
        },
        ref,
    ) => {
        let componentRef = useRef<HTMLDivElement>(null);
        componentRef = (ref as React.RefObject<HTMLDivElement>) ?? componentRef;

        const [initiallyCentered, setCenterInitially] = useState(false);
        const [isDraggableControlled, setIsDraggableControlled] =
            useState(false);

        const {controlsOverlap, update: updateControlsRelativePosition} =
            useControlsRelativePosition(componentRef);

        const setNoSidePanelLeft = useSidePanelTransform(
            isSidePanelVisible,
            componentRef,
            isSidePanelTransformEnabled,
        );

        const onPositionChange = useCallback(
            (el: HTMLDivElement) => {
                updateControlsRelativePosition();
                const elementRect = el.getBoundingClientRect();
                if (isOverlappingSidePanel(elementRect)) {
                    setNoSidePanelLeft(interfacesMargin + desktopToolbarWidth);
                } else {
                    setNoSidePanelLeft(undefined);
                }
            },
            [updateControlsRelativePosition, setNoSidePanelLeft],
        );

        const beforeReposition = useCallback(() => {
            if (componentRef.current) {
                componentRef.current.style.removeProperty('transform');
            }
            setIsDraggableControlled(true);
            if (beforeRepositionSideEffect) {
                beforeRepositionSideEffect();
            }
        }, [componentRef, beforeRepositionSideEffect]);

        const onReposition = useCallback(
            (el: HTMLDivElement, floatRootOverflow: FloatRootOverflow) => {
                onPositionChange(el);

                // don't overlap toolbar when side panel is open
                if (
                    isSidePanelVisible &&
                    el.offsetLeft < sidePanelPlusDesktopToolbarWidth
                ) {
                    el.style.left = `${
                        (sidePanelPlusDesktopToolbarWidth / window.innerWidth) *
                        100
                    }%`;
                }
                if (onRepositionSideEffect) {
                    onRepositionSideEffect(el, {floatRootOverflow});
                }
            },
            [onPositionChange, onRepositionSideEffect, isSidePanelVisible],
        );

        useEffect(() => {
            const element = componentRef.current;
            if (element) {
                if (controlsOverlap.header || controlsOverlap.footer) {
                    element.style.removeProperty('top');
                    element.style.removeProperty('bottom');
                }
                if (controlsOverlap.desktopToolbar) {
                    element.style.removeProperty('left');
                }
            }
        }, [controlsOverlap]);

        useEffect(() => {
            if (isDraggableControlled && componentRef.current) {
                const el = componentRef.current;

                el.style.removeProperty('transition');
                el.ontransitionend = event => {
                    // we should ideally not leave inline transforms after the animation
                    // as it blocks class styles and doesn't behave well with window resizing
                    if (event.propertyName !== 'transform') {
                        el.ontransitionend = null;
                        return;
                    }
                    let animationFrameTS: number;

                    requestAnimationFrame(ts => {
                        animationFrameTS = ts;
                        el.style.transition = 'none';
                        el.style.left = `${(el.getBoundingClientRect().left / window.innerWidth) * 100}%`;
                        el.style.removeProperty('transform');
                    });

                    const removeInlineTransition = () => {
                        requestAnimationFrame(ts => {
                            if (ts <= animationFrameTS) {
                                // make sure this runs after the requested frame that sets transition to 'none'
                                removeInlineTransition();
                            } else {
                                updateControlsRelativePosition();
                                el.style.removeProperty('transition');
                            }
                        });
                    };
                    removeInlineTransition();

                    el.ontransitionend = null;
                };
            }
        }, [
            isDraggableControlled,
            isSidePanelVisible,
            updateControlsRelativePosition,
        ]);

        useEffect(() => {
            if (!isDisabled) {
                updateControlsRelativePosition();
                setCenterInitially(false);
            } else {
                updateControlsRelativePosition({
                    desktopToolbar: false,
                    footer: false,
                    header: false,
                });
                setCenterInitially(true);
            }
        }, [isDisabled, onPositionChange, updateControlsRelativePosition]);

        const enableStyle = (shouldEnable: boolean | undefined) =>
            (alwaysAllowStyles || !isDisabled) && shouldEnable;

        return (
            <Draggable
                ariaLabel={draggableAriaLabel}
                isMoveableWithArrowKeys={isMoveableWithArrowKeys}
                isDisabled={isDisabled}
                showFocus={showFocus}
                floatRoot={floatRoot}
                onReposition={onReposition}
                beforeReposition={beforeReposition}
                shouldCaptureClick={shouldCaptureClick}
                ref={componentRef}
                pointerCapture={pointerCapture}
                pointerCaptureTarget={pointerCaptureTarget}
                alignWithFloatRootTrigger={alignWithFloatRootTrigger}
                className={cx(
                    {
                        [styles.inMeetingDraggable]: enableStyle(true),
                        [styles.overlapsControls]: enableStyle(
                            isControlsOverlapTruthy(controlsOverlap),
                        ),
                        [styles.desktopToolbar]: enableStyle(
                            controlsOverlap.desktopToolbar,
                        ),
                        [styles.footer]: enableStyle(controlsOverlap.footer),
                        [styles.header]: enableStyle(controlsOverlap.header),
                        [styles.center]: enableStyle(
                            center &&
                                !isDraggableControlled &&
                                !initiallyCentered,
                        ),
                        [styles.centerInitial]: enableStyle(
                            center &&
                                !isDraggableControlled &&
                                initiallyCentered,
                        ),
                        [styles.sidePanelOpen]: enableStyle(isSidePanelVisible),
                        [styles.phone]: enableStyle(isPhone),
                    },
                    className,
                )}
                style={style}
                {...autoHideProps}
            >
                {children}
            </Draggable>
        );
    },
);

InMeetingDraggable.displayName = 'InMeetingDraggable';
export type InMeetingDraggableProps = React.ComponentProps<
    typeof InMeetingDraggable
>;
