import type {
    VideoProcessor,
    SegmentationTransform,
    Size,
    SegmentationModel,
} from '@pexip/media-processor';
import {
    createVideoProcessor,
    createCanvasTransform,
    createVideoTrackProcessor,
    createVideoTrackProcessorWithFallback,
    isRenderEffects,
    isSegmentationModel,
} from '@pexip/media-processor';
import type {MediaDeviceRequest} from '@pexip/media-control';
import {
    muteStreamTrack,
    extractConstraintsWithKeys,
    getValueFromConstrainNumber,
} from '@pexip/media-control';
import {isEmpty, assert} from '@pexip/utils';

import type {
    TrackProcessor,
    MediaTrack,
    VideoRenderParams,
    Segmenters,
    VideoStreamTrackProcessorAPIs,
    VideoContentHint,
} from './types';
import {createMediaTrack, getBlurKernelSize} from './utils';
import {logger, proxyWithLog} from './logger';
import {isVideoContentHint} from './typeGuard';
import {PROCESSOR_LABELS} from './constants';

interface ProcessorDeps {
    videoProcessor?: () => VideoProcessor;
    transformer?: SegmentationTransform;
    segmenters: Partial<Segmenters>;
    videoSegmentationModel?: SegmentationModel;
}

interface VideoStreamProcessOptions
    extends Partial<VideoRenderParams>,
        Omit<ProcessorDeps, 'videoProcessor'> {
    /**
     * What API to use for processing the MediaStreamTrack
     * `stream` - Use MediaStreamTrackProcessor, when available
     * `canvas` - Use Canvas
     */
    trackProcessorAPI?: () => VideoStreamTrackProcessorAPIs;
    /**
     * Whether or to enable this processor
     */
    shouldEnable: () => boolean;
    processingWidth: number;
    processingHeight: number;
    hasInitializedDeps?: boolean;
    width?: number;
    height?: number;
    label?: string;
}

interface VideoStreamProcessProps
    extends Partial<VideoRenderParams>,
        Required<ProcessorDeps> {
    hasInitialized: boolean;
    contentHint?: VideoContentHint;
}

const FEATURE_KEYS = [
    'backgroundBlurAmount',
    'backgroundImageUrl',
    'maskCombineRatio',
    'edgeBlurAmount',
    'foregroundThreshold',
    'frameRate',
    'videoSegmentation',
    'videoSegmentationModel',
    'width',
    'height',
    'pan',
    'tilt',
    'zoom',
    'contentHint',
] as const;

type FeaturePropKeys = (typeof FEATURE_KEYS)[number];
type FeatureProps = Pick<Partial<VideoStreamProcessProps>, FeaturePropKeys>;

const getVideoConstraints = extractConstraintsWithKeys(FEATURE_KEYS);

export const updateFeatureProps = (
    constraints: MediaDeviceRequest['video'],
    props: FeatureProps,
) => {
    const extracted = getVideoConstraints(constraints);

    return FEATURE_KEYS.reduce((accm, key) => {
        switch (key) {
            case 'contentHint': {
                const [[feature = ''] = []] = extracted[key];
                if (isVideoContentHint(feature) && props[key] !== feature) {
                    props[key] = feature;
                    return {...accm, [key]: feature};
                }
                return accm;
            }
            case 'videoSegmentation': {
                const [[feature] = []] = extracted[key];
                if (isRenderEffects(feature) && props[key] !== feature) {
                    props[key] = feature;
                    return {...accm, [key]: feature};
                }
                return accm;
            }
            case 'videoSegmentationModel': {
                const [[feature] = []] = extracted[key];
                if (isSegmentationModel(feature) && props[key] !== feature) {
                    props[key] = feature;
                    return {...accm, [key]: feature};
                }
                return accm;
            }
            case 'backgroundImageUrl': {
                const [[feature] = []] = extracted[key];
                if (feature) {
                    props[key] = feature;
                    return {...accm, [key]: feature};
                }
                return accm;
            }
            case 'width':
            case 'height':
            case 'frameRate':
            case 'foregroundThreshold':
            case 'edgeBlurAmount':
            case 'maskCombineRatio':
            case 'backgroundBlurAmount': {
                const [feature] = extracted[key];
                if (feature !== undefined) {
                    const value = getValueFromConstrainNumber(feature);
                    if (props[key] !== value) {
                        props[key] = value;
                        return {
                            ...accm,
                            [key]: value,
                        };
                    }
                }
                return accm;
            }
            case 'pan':
            case 'tilt':
            case 'zoom': {
                const [feature] = extracted[key];
                if (feature !== undefined && props[key] !== feature) {
                    props[key] = feature;
                    return {...accm, [key]: feature};
                }
                return accm;
            }
        }
    }, {} as FeatureProps);
};

const applyFeatures = (
    transformer: SegmentationTransform,
    features: FeatureProps,
) => {
    Object.keys(features).forEach(key => {
        const k = key as keyof typeof features;
        switch (k) {
            case 'edgeBlurAmount':
            case 'maskCombineRatio':
            case 'foregroundThreshold': {
                const value = features[k];
                if (value !== undefined) {
                    transformer[k] = value;
                }
                return;
            }
            case 'backgroundBlurAmount': {
                const value = features[k];
                if (value !== undefined) {
                    transformer[k] = getBlurKernelSize(
                        value,
                        transformer.height,
                    );
                }
                return;
            }
            case 'videoSegmentation': {
                const value = features[k];
                if (value && value !== transformer.effects) {
                    transformer.effects = value;
                }
                return;
            }
            case 'backgroundImageUrl': {
                const value = features[k];
                if (value && value !== transformer.backgroundImageUrl) {
                    transformer.backgroundImageUrl = value;
                }
                return;
            }
            default: {
                return;
            }
        }
    });
};

const adjustResolution = async (
    track: MediaTrack,
    features: FeatureProps,
    processingSize: Size,
) => {
    if (features.videoSegmentation) {
        const videoSettings = track.getSettings();
        const constraints = updateFeatureProps(track.getConstraints(), {});
        switch (features.videoSegmentation) {
            case 'blur':
            case 'overlay': {
                if (videoSettings?.height !== processingSize.height) {
                    try {
                        await track.applyConstraints({
                            width: processingSize.width,
                            height: processingSize.height,
                        });
                        const postVideoSettings = track.getSettings();
                        if (
                            postVideoSettings?.height !== processingSize.height
                        ) {
                            // Workaround Firefox 16:9 ratio https://bugzilla.mozilla.org/show_bug.cgi?id=1193640
                            await track.applyConstraints({height: 720});
                        }
                    } catch (error: unknown) {
                        // Workaround Firefox 16:9 ratio https://bugzilla.mozilla.org/show_bug.cgi?id=1193640
                        await track.applyConstraints({height: 720});
                    }
                }
                break;
            }
            case 'none': {
                if (
                    constraints.height &&
                    constraints.height !== videoSettings?.height
                ) {
                    await track.applyConstraints({height: constraints.height});
                }
                break;
            }
        }
    }
};

const getTrackProcessor = (
    shouldUseStreamTrackProcessor: boolean,
    ...params: Parameters<typeof createVideoTrackProcessorWithFallback>
) => {
    if (
        shouldUseStreamTrackProcessor &&
        'MediaStreamTrackProcessor' in window
    ) {
        return createVideoTrackProcessor();
    }
    return createVideoTrackProcessorWithFallback(...params);
};

export const createVideoStreamProcess = ({
    trackProcessorAPI = () => 'stream',
    processingWidth,
    processingHeight,
    shouldEnable,
    frameRate,
    //backgroundBlurAmount,
    videoSegmentation,
    //edgeBlurAmount,
    foregroundThreshold,
    backgroundImageUrl,
    maskCombineRatio,
    edgeBlurAmount,
    label = PROCESSOR_LABELS.VideoProcessor,
    ...options
}: VideoStreamProcessOptions): TrackProcessor => {
    const videoSegmentationModel = options.videoSegmentationModel ?? 'selfie';
    const segmenter = options.segmenters[videoSegmentationModel];
    if (!segmenter) {
        throw new Error('Segmenter is undefined');
    }
    const backgroundBlurAmount =
        options.backgroundBlurAmount &&
        getBlurKernelSize(options.backgroundBlurAmount, processingHeight);
    const transformer =
        options.transformer ??
        createCanvasTransform(segmenter, {
            width: processingWidth,
            height: processingHeight,
            effects: videoSegmentation,
            foregroundThreshold,
            backgroundBlurAmount,
            edgeBlurAmount,
            backgroundImageUrl,
            maskCombineRatio,
        });
    const proxy = proxyWithLog(logger, label);
    let _videoProcessor: VideoProcessor | undefined = undefined;
    const props: VideoStreamProcessProps = {
        videoSegmentationModel,
        segmenters: {
            selfie:
                options.segmenters.selfie &&
                proxy(options.segmenters.selfie, 'Segmenter'),
            deeplabV3:
                options.segmenters.deeplabV3 &&
                proxy(options.segmenters.deeplabV3, 'Segmenter'),
        },
        transformer: proxy(transformer, 'Transformer'),
        videoProcessor: () => {
            if (!_videoProcessor) {
                _videoProcessor = proxy(
                    createVideoProcessor(
                        [transformer],
                        getTrackProcessor(trackProcessorAPI() === 'stream', {
                            width: processingWidth,
                            height: processingHeight,
                            frameRate,
                        }),
                    ),
                    'VideoProcessor',
                );
            }
            return _videoProcessor;
        },
        videoSegmentation,
        backgroundBlurAmount,
        edgeBlurAmount,
        foregroundThreshold,
        frameRate,
        backgroundImageUrl,
        maskCombineRatio,
        hasInitialized: options.hasInitializedDeps ?? false,
    };

    return async prevMediaTrack => {
        const features = updateFeatureProps(
            prevMediaTrack.getConstraints(),
            props,
        );
        const shouldEnabled = shouldEnable();
        if (!shouldEnabled || !prevMediaTrack.track) {
            logger.debug(
                {label, features, shouldEnabled},
                'Video processing is skipped',
            );
            return prevMediaTrack;
        }
        if (!props.hasInitialized) {
            await props.videoProcessor().open();
            props.hasInitialized = true;
        }
        applyFeatures(props.transformer, features);
        await adjustResolution(prevMediaTrack, features, {
            width: processingWidth,
            height: processingHeight,
        });
        const model =
            features.videoSegmentationModel &&
            props.segmenters[features.videoSegmentationModel];
        if (
            features.videoSegmentationModel &&
            features.videoSegmentationModel !==
                props.transformer.segmenter.modelAsset.modelName &&
            model
        ) {
            props.transformer.segmenter = model;
        }
        const stream = await props
            .videoProcessor()
            // FIXME: Change process to accept MediaStreamTrack instead of MediaStream
            .process(new MediaStream([prevMediaTrack.track]));
        const track = stream.getVideoTracks().at(0);
        assert(track, 'Video track should be there');
        // Inherit contentHint from previous track
        track.contentHint = prevMediaTrack.track.contentHint;

        const release = async () => {
            props.videoProcessor().close();
            await prevMediaTrack.release();
            _videoProcessor = undefined;
            props.hasInitialized = false;
        };
        const mute = (mute: boolean) => {
            props.transformer.effects = mute
                ? 'none'
                : props.videoSegmentation ?? 'none';
            prevMediaTrack.mute(mute);
            muteStreamTrack(stream)(mute, 'video');
        };

        return createMediaTrack({
            label,
            kind: 'videoinput',
            constraints: prevMediaTrack.getConstraints(),
            previousMediaTrack: prevMediaTrack,
            input: prevMediaTrack.input,
            expectedInput: prevMediaTrack.expectedInput,
            track,
            mute,
            release,
            applyConstraints: async constraints => {
                if (isEmpty(constraints)) {
                    return;
                }
                const features = updateFeatureProps(constraints, props);
                logger.debug(
                    {label, constraints: constraints, features},
                    'apply video constraints',
                );
                if (isEmpty(features)) {
                    return;
                }
                applyFeatures(props.transformer, features);
                await adjustResolution(prevMediaTrack, features, {
                    width: processingWidth,
                    height: processingHeight,
                });
                const model =
                    features.videoSegmentationModel &&
                    props.segmenters[features.videoSegmentationModel];
                if (
                    model &&
                    features.videoSegmentationModel !==
                        props.transformer.segmenter.modelAsset.modelName
                ) {
                    props.transformer.segmenter = model;
                }
            },
            getSettings: () => {
                const contentHint =
                    (track.contentHint as VideoContentHint) ?? '';
                return {
                    videoSegmentation: transformer.effects,
                    foregroundThreshold: transformer.foregroundThreshold,
                    // Background blur amount is a function of height
                    backgroundBlurAmount: props.backgroundBlurAmount,
                    edgeBlurAmount: transformer.edgeBlurAmount,
                    maskCombineRatio: transformer.maskCombineRatio,
                    backgroundImageUrl: transformer.backgroundImageUrl,
                    videoSegmentationModel:
                        transformer.segmenter.modelAsset.modelName,
                    pan: props.pan,
                    tilt: props.tilt,
                    zoom: props.zoom,
                    contentHint,
                };
            },
        });
    };
};
