import type {MediaDeviceInfoLike, IndexedDevices} from '@pexip/media-control';

/**
 * Selects the saved audio output or a default if exist.
 *
 * It check first if a saved audio output `localStorage` is exist in current devices and
 * if it's not exist, it fetches the first audio output as default.
 */
export const getAudioOutput = (
    devices: IndexedDevices,
    savedAudioOutput?: MediaDeviceInfoLike,
) =>
    getPreferredAudioOutput(devices, savedAudioOutput) ??
    getDefaultAudioOutput(devices) ??
    getFirstAudioOutput(devices);

export const getPreferredAudioOutput = (
    devices: IndexedDevices,
    savedAudioOutput?: MediaDeviceInfoLike,
) => {
    // @see Infinity-connect/src/config.ts, `audioOutput` is saved initially
    // as an empty object
    return !savedAudioOutput || !('kind' in savedAudioOutput)
        ? undefined
        : devices.get(savedAudioOutput)[0];
};

const getDefaultAudioOutput = (devices: IndexedDevices) =>
    devices.get({kind: 'audiooutput', deviceId: 'default'})[0];

const getFirstAudioOutput = (devices: IndexedDevices) =>
    devices.first('audiooutput');
