import {v4 as uuid} from 'uuid';

import * as API from '@pexip/infinity-api';
import type {Detach} from '@pexip/signal';
import type {InfinityErrorMessage} from '@pexip/infinity-api';
import {getErrorCode} from '@pexip/infinity-api';
import type {Bandwidth} from '@pexip/peer-connection';
import {createPCSignals} from '@pexip/peer-connection';
import {hasOwn} from '@pexip/utils';

import type {
    Call,
    CallSignals,
    Client,
    InfinityClient,
    InfinitySignals,
    Participant,
    RequestClient,
    CallUuid,
    ConferenceStatus,
    ExtendedInfinityErrorMessage,
    GetEndpointResponse,
    EndpointResponse,
    ConferenceFeatureFlags,
    Stun,
    Turn,
    DisconnectReason,
    Stats,
    ThemeSchema,
    MediaType,
    CurrentServiceType,
    GetEndpointParams,
    BreakoutRoom,
    MessageEvent,
    NormalizedPresentationEvent,
    MediaPriority,
} from './types';
import {ClientCallType} from './types';
import {createRequestClient} from './requestClient';
import {createCall} from './call';
import {createEventSourceManager, eventSignals} from './eventSource';
import {logger, redact} from './logger';
import {APPLICATION_JSON, NONE, SDP_OFFER_IGNORED} from './constants';
import {
    captureNoRequestClient,
    isCriticalAction,
    toClientCallType,
    normalizeParticipant,
    normalizeConferenceState,
    getBandwidth,
    createMainStatsSignals,
    normalizePresentationEvent,
    buildSsoTargetUrl,
} from './utils';

type EventHandler<T> = (roomId: string, participant: T) => void;

type Fetch = typeof window.fetch;

/**
 * Fetch with builtin logging the request and response
 */
const logFetch: Fetch = async (input, init) => {
    const url = input instanceof Request ? input.url : input;
    // eslint-disable-next-line @typescript-eslint/no-base-to-string --- false positive
    logger.debug({init}, `Request [${url}]`);
    const response = await window.fetch(input, init);
    const statusInfo = {
        status: response.status,
        statusText: response.statusText,
    };
    if (
        response.headers.get('content-type')?.startsWith('application/json') &&
        response.status !== 204
    ) {
        try {
            const clonedResponse = response.clone();
            const body = (await clonedResponse.json()) as unknown;
            if (body && hasOwn(body, 'result')) {
                redact(body.result, 'token');
                redact(body.result, 'conference_name');
            }
            logger.debug(
                {...statusInfo, body},
                // eslint-disable-next-line @typescript-eslint/no-base-to-string --- false positive
                `Response [${url}]`,
            );
            // Replay the response
            return response;
        } catch {
            return response;
        }
    } else {
        logger.debug(
            statusInfo,
            // eslint-disable-next-line @typescript-eslint/no-base-to-string --- false positive
            `Response [${url}]`,
        );
        return response;
    }
};

const createParticipants = () => {
    const participants = new Map([['main', new Map<string, Participant>()]]);

    const get = (roomID: string, uuid: string) => {
        return participants.get(roomID)?.get(uuid);
    };
    const set = (roomID: string, participant?: Participant) => {
        if (!participant) {
            participants.set(roomID, new Map());
            return;
        }
        const roomParticipants = participants.get(roomID);
        if (roomParticipants) {
            roomParticipants.set(participant.uuid, participant);
        } else {
            participants.set(
                roomID,
                new Map([[participant.uuid, participant]]),
            );
        }
    };
    const remove = (roomID: string, uuid?: string) => {
        if (uuid) {
            return participants.get(roomID)?.delete(uuid);
        }
        return participants.delete(roomID);
    };
    const getAll = (roomID: string) => {
        const list = participants.get(roomID);
        if (!list) {
            return [];
        }
        return Array.from(list.values());
    };
    return {
        get,
        set,
        remove,
        getAll,
    };
};

export const createInfinityClient = (
    signals: InfinitySignals,
    callSignals: CallSignals,
): InfinityClient => {
    let requestClient: RequestClient | undefined;
    let eventSourceManager:
        | ReturnType<typeof createEventSourceManager>
        | undefined;
    let currentCall: Call | undefined;
    let currentCallMediaType: MediaType | undefined;
    let currentCallType = ClientCallType.AudioVideo;
    let currentCallTypeQueryParameter: string | undefined;
    let currentCallUuid: CallUuid;
    let currentConferenceAlias: string;
    let currentParticipantUuid: string;
    let currentHost: string;
    let currentPin: string | undefined;
    let currentConferenceExtension: string | undefined;
    let currentChosenIdp = NONE;
    let currentSsoToken = NONE;
    let currentServiceType: CurrentServiceType | undefined;
    let currentCallTag: string | undefined;
    let currentBreakoutUuid: string | undefined;
    let currentPresenterUuid: string | undefined;
    let detachSignals: Detach[] = [];
    let participants = createParticipants();
    let conferenceStatus = new Map<string, ConferenceStatus>();
    let conferenceFeatureFlags: ConferenceFeatureFlags | undefined;
    let secureCheckCode = '';
    let stun: Stun;
    let turn: Turn;
    let dataChannelId: number | undefined;
    let latestStats: Stats | undefined;
    let useRelayCandidatesOnly: boolean | undefined;
    let disconnectPromise: ReturnType<typeof disconnectRequest> | undefined;
    let restartCallPromise: ReturnType<typeof restartCallRequest> | undefined;
    let themeSchema: ThemeSchema | undefined;
    let breakoutRooms = new Map<string, BreakoutRoom>();
    const presentationCache = new Map<string, NormalizedPresentationEvent>();
    let bandwidthIn: number | undefined;
    let bandwidthOut: number | undefined;
    let feccSupported = false;

    let retryList: Array<() => Promise<EndpointResponse>> = [];

    const timerIDs = {
        eventSrcReconnect: -1,
        update: -1,
        pushStatistics: -1,
    };

    const setPushStatisticsTimer = () => {
        if (requestClient?.statsUpdateInterval) {
            timerIDs.pushStatistics = window.setTimeout(
                pushStatistics,
                requestClient.statsUpdateInterval,
            );
        }
    };

    const getCurrentCallUuid = () => currentCallUuid;
    const getBreakoutParticipantUuid = (
        breakoutUuid: typeof currentBreakoutUuid,
    ) =>
        breakoutUuid
            ? breakoutRooms.get(breakoutUuid)?.participant_uuid
            : undefined;

    const setServiceType = (serviceType: CurrentServiceType) => {
        currentServiceType = serviceType;
        signals.onServiceType.emit(currentServiceType);
    };

    type GenerateEndpointParams<T extends keyof Client> =
        | {
              func: (
                  reqParams: GetEndpointParams<T>,
              ) => Promise<GetEndpointResponse<T>>;
              funcName: keyof Client;
              retriable?: boolean;
              requiresToken: false;
          }
        | {
              func: (
                  reqParams: GetEndpointParams<T>,
                  fetcher: Fetch,
              ) => Promise<GetEndpointResponse<T>>;
              funcName: T;
              retriable?: boolean;
              requiresToken: true;
          };
    function generateEndpoint<T extends keyof Client>(
        opts: GenerateEndpointParams<T>,
    ) {
        const wrappedFunc = async (...args: GetEndpointParams<T>) => {
            try {
                args.forEach(arg => {
                    redact(arg, 'pin');
                    redact(arg, 'token');
                    redact(arg, 'ssoToken');
                    redact(arg, 'displayName');
                });
            } catch (e) {
                logger.error(
                    {error: e},
                    `Error redacting sensitive information from request`,
                );
            }
            const {func, funcName, requiresToken, retriable} = opts;

            const flushRetryQueue = async () => {
                /**
                 * Although it is generally not a great idea to make retries dependent on another request being successful as it might never happen,
                 * because of our unique context (video as main feature) we should always try to renegotiate the call on network changes.
                 * Without A/V the app effectively has no use.
                 * This renegotiation after network changes is therefore a reliable proxy for network availability as long as it is implemented.
                 */
                if (
                    retryList.length > 0 &&
                    (await requestClient?.refreshToken())
                ) {
                    const promise = Promise.allSettled(
                        retryList.map(func => func()),
                    );
                    retryList = [];
                    const res = await promise;
                    const hasFailure = res.find(
                        settled =>
                            settled.status === 'fulfilled' &&
                            settled.value === undefined,
                    );
                    if (!hasFailure) {
                        signals.onRetryQueueFlushed.emit();
                    }
                }
            };

            let endpointPromise;
            try {
                if (requiresToken) {
                    if (!requestClient) {
                        captureNoRequestClient();
                        return;
                    }
                    await flushRetryQueue();
                    endpointPromise = await func(args, requestClient.fetcher);
                } else {
                    endpointPromise = await func(args);
                }
            } catch (e) {
                if (retriable) {
                    // if anything fails again it will be added back to the queue. Order is lost
                    logger.warn(
                        {error: e},
                        `Adding failed request '${funcName}' to retry queue.`,
                    );
                    retryList.push(() => wrappedFunc(...args));
                } else {
                    logger.error(
                        {error: e},
                        `Request '${funcName}' threw an Error.`,
                    );
                    if (isCriticalAction(funcName)) {
                        cleanupAndDisconnect(
                            'Could not execute critical network action',
                        );
                        return endpointPromise;
                    }
                }
                signals.onFailedRequest.emit(funcName);
                throw e;
            }
            return endpointPromise;
        };
        return wrappedFunc;
    }

    const startCall = ({
        bandwidth,
        mediaStream,
        callType = ClientCallType.AudioVideo,
        networkPriorities,
        encodingPriorities,
    }: Pick<GetEndpointParams<'call'>[0], 'mediaStream'> & {
        bandwidth: Bandwidth;
        callType?: ClientCallType;
        /**
         * DSCP markings for each media line
         * This has the same effect as priority, except that it only affects
         * the DSCP markings of the generated packets, as described in
         * [rfc8835] section 4.2.
         */
        networkPriorities?: MediaPriority;
        /**
         * Encoding priority for each media line
         * Indicates the priority of an RTCRtpSender, which influences the
         * bandwidth allocation among RTCRtpSender objects. It is specified in
         * [rfc8835], Section 4. The user agent is free to sub-allocate
         * bandwidth between the encodings of an RTCRtpSender.
         */
        encodingPriorities?: MediaPriority;
    }) => {
        currentCallType = toClientCallType(currentCallMediaType) & callType;
        logger.info(
            {callType: ClientCallType[callType], bandwidth, mediaStream},
            'starting call with CallType',
        );
        currentCall = createCall({
            sendOffer,
            ack,
            newCandidate,
            update,
            takeFloor,
            releaseFloor,
            getCurrentCallUuid,
            signals,
            eventSignals,
            callSignals,
            networkPriorities,
            encodingPriorities,
            peerOptions: {
                allow1080p: conferenceFeatureFlags?.allow1080p,
                allow4kPreso: conferenceFeatureFlags?.isDirectMedia,
                allowCodecSdpMunging: !conferenceFeatureFlags?.isDirectMedia,
                allowVP9: conferenceFeatureFlags?.allowVP9,
                bandwidth,
                rtcConfig: {
                    bundlePolicy: 'max-bundle',
                    iceServers: [
                        ...(stun
                            ? [
                                  {
                                      urls: stun.map(stun => stun.url),
                                  },
                              ]
                            : []),
                        ...(turn ? turn : []),
                    ],
                    ...(useRelayCandidatesOnly && {
                        iceTransportPolicy: 'relay',
                    }),
                },
            },
            dataChannelId,
            mediaStream:
                typeof mediaStream === 'function' ? mediaStream() : mediaStream,
            isDirectMedia: Boolean(conferenceFeatureFlags?.isDirectMedia),
            callType: currentCallType,
            pcMainSignals: createPCSignals(
                [
                    'onIceCandidate',
                    'onIceConnectionStateChange',
                    'onReceiveIceCandidate',
                    'onRemoteStreams',
                    'onConnectionStateChange',
                    'onTransceiverChange',
                    'onSecureCheckCode',
                ],
                `call:pc:main`,
            ),
            mainStatsSignals: createMainStatsSignals(),
        });
    };

    const restartCallRequest = async ({
        bandwidth,
        ...opt
    }: Parameters<InfinityClient['restartCall']>[0]) => {
        await disconnectRequest({
            /**
             * There is a specific case for direct media call when
             * one peer disconnects we need to restart webrtc call
             * and wait for another participant to arrive
             * so we need to keep EventSource open to wait for the
             * ping from mcu for that.
             */
            callback: async () => {
                if (
                    currentCall?.presoState.send === 'connected' ||
                    currentCall?.presoState.send === 'connecting'
                ) {
                    await currentCall?.stopPresenting();
                }
                currentCall?.disconnect();
                currentCall = undefined;
                currentCallUuid = undefined;
            },
            // Reuse existing token for this usecase
            release: () => {
                return Promise.resolve();
            },
        });
        startCall({
            ...opt,
            bandwidth: {
                in: getBandwidth(bandwidth, bandwidthIn),
                out: getBandwidth(bandwidth, bandwidthOut),
            },
        });
    };

    const restartCall = async (
        ...params: Parameters<typeof restartCallRequest>
    ) => {
        restartCallPromise = restartCallRequest(...params);
        return restartCallPromise;
    };

    const call = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'call'>) => {
            const {
                bandwidth,
                conferenceAlias,
                directMedia = true,
                displayName,
                node = window.location.host,
                host = `${window.location.protocol}//${node}`,
                mediaStream,
                pin,
                chosenIdp,
                ssoToken,
                token,
                conferenceExtension,
                callTag,
                callTypeQueryParameter,
                callType,
                clientId,
                networkPriorities,
                encodingPriorities,
                supportsDirectChat = false,
            } = reqParams[0];

            if (restartCallPromise) {
                await restartCallPromise;
            }
            if (disconnectPromise) {
                await disconnectPromise;
            }

            const pinNow = pin ?? currentPin;
            const chosenIdpNow = chosenIdp ?? currentChosenIdp;
            const ssoTokenNow = ssoToken ?? currentSsoToken;
            const conferenceExtensionNow =
                conferenceExtension ?? currentConferenceExtension;
            const callTagNow = callTag ?? currentCallTag;

            const callTypeQueryParameterNow =
                callTypeQueryParameter ?? currentCallTypeQueryParameter;

            // Build SSO redirect target URL if conferenceAlias is provided
            let sso_redirect_target: string | undefined;

            if (conferenceAlias) {
                sso_redirect_target = buildSsoTargetUrl({
                    conferenceAlias,
                    displayName,
                    callTag: callTagNow,
                    callTypeQueryParameter: callTypeQueryParameterNow,
                });
            }

            const res = await API.requestToken({
                fetcher: pinNow ? API.withPin(logFetch, pinNow) : logFetch,
                body: {
                    display_name: displayName,
                    chosen_idp: chosenIdpNow,
                    direct_media: directMedia,
                    sso_token: ssoTokenNow,
                    sso_redirect_target,
                    node,
                    token,
                    conference_extension: conferenceExtensionNow,
                    call_tag: callTagNow,
                    client_id: clientId,
                    supports_direct_chat: supportsDirectChat,
                },
                params: {
                    conferenceAlias,
                },
                host,
            });

            switch (res.status) {
                case 200:
                    if (pin) {
                        currentPin = pin;
                    }
                    if (chosenIdp) {
                        currentChosenIdp = chosenIdp;
                    }
                    if (ssoToken) {
                        currentSsoToken = ssoToken;
                    }
                    if (conferenceExtension) {
                        currentConferenceExtension = conferenceExtension;
                    }
                    if (callTag) {
                        currentCallTag = callTag;
                    }
                    conferenceFeatureFlags = {
                        chatEnabled: Boolean(res.data.result.chat_enabled),
                        isDirectMedia: Boolean(res.data.result.direct_media),
                        guestsCanPresent: Boolean(
                            res.data.result.guests_can_present,
                        ),
                        allow1080p: Boolean(res.data.result.allow_1080p),
                        allowVP9: Boolean(res.data.result.vp9_enabled),
                        callType: res.data.result.call_type ?? 'video',
                        breakoutRoomsEnabled: Boolean(
                            res.data.result.breakout_rooms,
                        ),
                    };
                    currentCallMediaType = res.data.result.call_type;
                    currentCallType =
                        toClientCallType(currentCallMediaType) & callType;
                    currentConferenceAlias = conferenceAlias;
                    currentParticipantUuid = res.data.result.participant_uuid;
                    currentHost = host;

                    setServiceType(res.data.result.current_service_type);

                    requestClient = createRequestClient({
                        fetcher: logFetch,
                        conferenceAlias,
                        token: res.data.result.token,
                        expires: Number(res.data.result.expires),
                        host,
                        clientStatsUpdateInterval:
                            res.data.result.client_stats_update_interval,
                        tokenExpiredCb: () => {
                            cleanupAndDisconnect(
                                'Could not reconnect to the meeting',
                            );
                        },
                    });
                    themeSchema = await requestTheme({});
                    eventSourceManager =
                        createEventSourceManager(requestClient);
                    await eventSourceManager.connect(host, conferenceAlias);

                    if (detachSignals.length > 0) {
                        cleanupSignals();
                    }

                    detachSignals = subscribeSignals();
                    stun = res.data.result.stun;
                    turn = res.data.result.turn;
                    dataChannelId = res.data.result.pex_datachannel_id;
                    useRelayCandidatesOnly =
                        res.data.result.use_relay_candidates_only;
                    bandwidthOut = res.data.result.bandwidth_out;
                    bandwidthIn = res.data.result.bandwidth_in;

                    setPushStatisticsTimer();

                    signals.onAuthenticatedWithConference.emit({
                        conferenceAlias: currentConferenceAlias,
                        conferenceName: res.data.result.conference_name,
                    });
                    logger.debug({currentCall}, 'Creates a new call');
                    startCall({
                        bandwidth: {
                            in: getBandwidth(bandwidth, bandwidthIn),
                            out: getBandwidth(bandwidth, bandwidthOut),
                        },
                        mediaStream,
                        callType: currentCallType,
                        networkPriorities,
                        encodingPriorities,
                    });

                    break;

                case 403:
                case 415:
                    // For this response string is likely considered as error
                    if (typeof res.data.result === 'string') {
                        signals.onError.emit({
                            error: res.data.result,
                            errorCode: getErrorCode(res.data.result),
                        });
                    } else if (
                        'pin' in res.data.result &&
                        'guest_pin' in res.data.result
                    ) {
                        if (conferenceExtension) {
                            currentConferenceExtension = conferenceExtension;
                        }
                        signals.onPinRequired.emit({
                            hasHostPin: res.data.result.pin === 'required',
                            hasGuestPin:
                                res.data.result.guest_pin === 'required',
                        });
                    } else if ('idp' in res.data.result) {
                        if (pin) {
                            currentPin = pin;
                        }
                        const idps = res.data.result.idp.map(
                            ({disable_popup_flow, ...rest}) => ({
                                disablePopupFlow: disable_popup_flow,
                                ...rest,
                            }),
                        );
                        signals.onIdp.emit(idps);
                    } else if (
                        'redirect_url' in res.data.result &&
                        'redirect_idp' in res.data.result
                    ) {
                        signals.onRedirect.emit({
                            redirectUrl: res.data.result.redirect_url,
                            redirectIdp: res.data.result.redirect_idp,
                            disablePopupFlow:
                                res.data.result.disable_popup_flow,
                        });
                    } else if (
                        'conference_extension' in res.data.result &&
                        'conference_extension_type' in res.data.result
                    ) {
                        signals.onExtension.emit(
                            res.data.result.conference_extension_type,
                        );
                    }
                    break;
                case 404:
                    signals.onError.emit({
                        error: res.data.result,
                        errorCode: getErrorCode(res.data.result),
                    });
                    break;
                case 502:
                case 503:
                case 504:
                case 529:
                    signals.onError.emit({
                        error: res.data.result,
                        errorCode: getErrorCode(res.data.result),
                    });
                    break;
            }
            return res;
        },
        funcName: 'call',
        requiresToken: false,
    });

    const pushStatistics = () => {
        const convertBitrate = (bitrate?: number) =>
            bitrate ? bitrate / 1000 : undefined;
        const convertJitter = (jitter?: number) =>
            jitter ? jitter * 1000 : undefined;
        const convertPercentage = (percentage?: number) =>
            percentage ? percentage * 100 : undefined;

        void statistics({
            audio: {
                rx_bitrate: convertBitrate(latestStats?.inbound.audio?.bitrate),
                rx_codec: latestStats?.inbound.audio?.codec?.replace(
                    'audio/',
                    '',
                ),
                rx_historic_packet_loss: convertPercentage(
                    latestStats?.inbound.audio?.totalPercentageLost,
                ),
                rx_jitter: convertJitter(latestStats?.inbound.audio?.jitter),
                rx_packets_lost: latestStats?.inbound.audio?.packetsLost,
                rx_packets_received:
                    latestStats?.inbound.audio?.packetsTransmitted,
                rx_windowed_packet_loss: convertPercentage(
                    latestStats?.inbound.audio?.recentPercentageLost,
                ),
                tx_bitrate: convertBitrate(
                    latestStats?.outbound.audio?.bitrate,
                ),
                tx_codec: latestStats?.outbound.audio?.codec?.replace(
                    'audio/',
                    '',
                ),
                tx_historic_packet_loss: convertPercentage(
                    latestStats?.outbound.audio?.totalPercentageLost,
                ),
                tx_packets_sent:
                    latestStats?.outbound.audio?.packetsTransmitted,
                tx_rb_jitter: convertJitter(
                    latestStats?.outbound.audio?.jitter,
                ),
                tx_rb_packetslost: latestStats?.outbound.audio?.packetsLost,
                tx_windowed_packet_loss: convertPercentage(
                    latestStats?.outbound.audio?.recentPercentageLost,
                ),
            },
            video: {
                rx_bitrate: convertBitrate(latestStats?.inbound.video?.bitrate),
                rx_codec: latestStats?.inbound.video?.codec?.replace(
                    'video/',
                    '',
                ),
                rx_fps: latestStats?.inbound.video?.framesPerSecond,
                rx_historic_packet_loss: convertPercentage(
                    latestStats?.inbound.video?.totalPercentageLost,
                ),
                rx_packets_lost: latestStats?.inbound.video?.packetsLost,
                rx_packets_received:
                    latestStats?.inbound.video?.packetsTransmitted,
                rx_resolution: latestStats?.inbound.video?.resolution,
                rx_windowed_packet_loss: convertPercentage(
                    latestStats?.inbound.video?.recentPercentageLost,
                ),
                tx_bitrate: convertBitrate(
                    latestStats?.outbound.video?.bitrate,
                ),
                tx_codec: latestStats?.outbound.video?.codec?.replace(
                    'video/',
                    '',
                ),
                tx_fps: latestStats?.outbound.video?.framesPerSecond,
                tx_historic_packet_loss: convertPercentage(
                    latestStats?.outbound.video?.totalPercentageLost,
                ),
                tx_packets_sent:
                    latestStats?.outbound.video?.packetsTransmitted,
                tx_rb_packetslost: latestStats?.outbound.video?.packetsLost,
                tx_resolution: latestStats?.outbound.video?.resolution,
                tx_windowed_packet_loss: convertPercentage(
                    latestStats?.outbound.video?.recentPercentageLost,
                ),
            },
            presentation: {
                rx_bitrate: convertBitrate(latestStats?.inbound.preso?.bitrate),
                rx_codec: latestStats?.inbound.preso?.codec?.replace(
                    'video/',
                    '',
                ),
                rx_fps: latestStats?.inbound.preso?.framesPerSecond,
                rx_historic_packet_loss: convertPercentage(
                    latestStats?.inbound.preso?.totalPercentageLost,
                ),
                rx_packets_lost: latestStats?.inbound.preso?.packetsLost,
                rx_packets_received:
                    latestStats?.inbound.preso?.packetsTransmitted,
                rx_resolution: latestStats?.inbound.preso?.resolution,
                rx_windowed_packet_loss: convertPercentage(
                    latestStats?.inbound.preso?.recentPercentageLost,
                ),
                tx_bitrate: convertBitrate(
                    latestStats?.outbound.preso?.bitrate,
                ),
                tx_codec: latestStats?.outbound.preso?.codec?.replace(
                    'video/',
                    '',
                ),
                tx_fps: latestStats?.outbound.preso?.framesPerSecond,
                tx_historic_packet_loss: convertPercentage(
                    latestStats?.outbound.preso?.totalPercentageLost,
                ),
                tx_packets_sent:
                    latestStats?.outbound.preso?.packetsTransmitted,
                tx_rb_packetslost: latestStats?.outbound.preso?.packetsLost,
                tx_resolution: latestStats?.outbound.preso?.resolution,
                tx_windowed_packet_loss: convertPercentage(
                    latestStats?.outbound.preso?.recentPercentageLost,
                ),
            },
        });
        setPushStatisticsTimer();
    };

    const sendOffer = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'sendOffer'>, fetcher) => {
            const {
                sdp,
                conferenceAlias = currentConferenceAlias,
                breakoutUuid = currentBreakoutUuid,
                host = currentHost,
                participantUuid = currentParticipantUuid,
            } = reqParams[0];
            const breakoutParticipantUuid =
                getBreakoutParticipantUuid(breakoutUuid);

            const res =
                breakoutUuid && breakoutParticipantUuid
                    ? await API.breakoutCallsWebrtcParticipant({
                          fetcher,
                          body: {
                              call_type: 'WEBRTC',
                              sdp,
                              media_type: currentCallMediaType,
                              fecc_supported: feccSupported,
                          },
                          params: {
                              conferenceAlias,
                              participantUuid: breakoutParticipantUuid,
                              breakoutUuid,
                          },
                          host,
                      })
                    : await API.callsWebrtcParticipant({
                          fetcher,
                          body: {
                              call_type: 'WEBRTC',
                              sdp,
                              media_type: currentCallMediaType,
                              fecc_supported: feccSupported,
                          },
                          params: {
                              conferenceAlias,
                              participantUuid,
                          },
                          host,
                      });

            if (res.status === 200) {
                currentCallUuid = res.data.result.call_uuid;
                signals.onAnswer.emit(res.data.result);
            } else if (res.status === 403) {
                signals.onError.emit({
                    error: res.data.result,
                    errorCode: getErrorCode(res.data.result),
                });
            }

            return res;
        },
        funcName: 'sendOffer',
        requiresToken: true,
    });

    const ack = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'ack'>, fetcher) => {
            const {
                callUuid = currentCallUuid,
                conferenceAlias = currentConferenceAlias,
                host = currentHost,
                participantUuid = currentParticipantUuid,
                breakoutUuid = currentBreakoutUuid,
                sdp,
                offerIgnored,
            } = reqParams[0];

            if (!callUuid) {
                return;
            }

            const breakoutParticipantUuid =
                getBreakoutParticipantUuid(breakoutUuid);

            if (breakoutUuid && breakoutParticipantUuid) {
                return API.breakoutAck({
                    fetcher,
                    params: {
                        conferenceAlias,
                        participantUuid: breakoutParticipantUuid,
                        breakoutUuid,
                        callUuid,
                    },
                    body: {
                        sdp: offerIgnored ? SDP_OFFER_IGNORED : sdp,
                        offer_ignored: offerIgnored,
                    },
                    host,
                });
            }

            return API.ack({
                fetcher,
                params: {
                    conferenceAlias,
                    participantUuid,
                    callUuid,
                },
                body: {
                    sdp: offerIgnored ? SDP_OFFER_IGNORED : sdp,
                    offer_ignored: offerIgnored,
                },
                host,
            });
        },
        funcName: 'ack',
        requiresToken: true,
    });

    const newCandidate = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'newCandidate'>, fetcher) => {
            const {
                candidate,
                callUuid = currentCallUuid,
                conferenceAlias = currentConferenceAlias,
                host = currentHost,
                participantUuid = currentParticipantUuid,
                breakoutUuid = currentBreakoutUuid,
            } = reqParams[0];

            if (!callUuid) {
                return;
            }

            const breakoutParticipantUuid =
                getBreakoutParticipantUuid(breakoutUuid);

            if (breakoutUuid && breakoutParticipantUuid) {
                return API.breakoutNewCandidate({
                    fetcher,
                    params: {
                        conferenceAlias,
                        participantUuid: breakoutParticipantUuid,
                        callUuid,
                        breakoutUuid,
                    },
                    body: candidate,
                    host,
                });
            }

            return API.newCandidate({
                fetcher,
                params: {
                    conferenceAlias,
                    participantUuid,
                    callUuid,
                },
                body: candidate,
                host,
            });
        },
        funcName: 'newCandidate',
        requiresToken: true,
    });

    const update = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'update'>, fetcher) => {
            const {
                sdp,
                callUuid = currentCallUuid,
                conferenceAlias = currentConferenceAlias,
                host = currentHost,
                participantUuid = currentParticipantUuid,
                breakoutUuid = currentBreakoutUuid,
                abortSignal,
            } = reqParams[0];

            const doUpdate = async () => {
                if (!callUuid) {
                    clearTimeout(timerIDs.update);
                    return;
                }

                try {
                    const breakoutParticipantUuid =
                        getBreakoutParticipantUuid(breakoutUuid);

                    const res =
                        breakoutUuid && breakoutParticipantUuid
                            ? await API.breakoutUpdate({
                                  fetcher,
                                  body: {
                                      sdp,
                                      fecc_supported: feccSupported,
                                  },
                                  params: {
                                      conferenceAlias,
                                      participantUuid: breakoutParticipantUuid,
                                      breakoutUuid,
                                      callUuid,
                                  },
                                  host,
                                  init: {signal: abortSignal},
                              })
                            : await API.update({
                                  fetcher,
                                  body: {
                                      sdp,
                                      fecc_supported: feccSupported,
                                  },
                                  params: {
                                      conferenceAlias,
                                      participantUuid,
                                      callUuid,
                                  },
                                  host,
                                  init: {signal: abortSignal},
                              });

                    if (res.status === 200) {
                        clearTimeout(timerIDs.update);
                        // aggressively refresh token and reconnect event source after successful update
                        // as it could be a result of connectivity issues.
                        // This works around the fact that backoff retry could be too big at this point and cause us to expire the token
                        await requestClient?.refreshToken();
                        if (
                            !present &&
                            eventSourceManager?.eventSource?.readyState === 2
                        ) {
                            void eventSourceManager.connect(
                                host,
                                conferenceAlias,
                            );
                        }

                        if (typeof res.data.result === 'string') {
                            res.data.result &&
                                signals.onAnswer.emit({
                                    sdp: res.data.result,
                                    call_uuid: callUuid,
                                });
                        } else if (res.data.result.sdp) {
                            signals.onAnswer.emit({
                                sdp: res.data.result.sdp,
                                call_uuid: res.data.result.call_uuid,
                            });
                        }
                    }
                    return res;
                } catch (error) {
                    if (error instanceof Error && error.name !== 'AbortError') {
                        logger.error({error}, 'Failed /update attempt');
                        timerIDs.update = window.setTimeout(() => {
                            void doUpdate();
                        }, 1000);
                    }
                }
            };

            clearTimeout(timerIDs.update);
            return doUpdate();
        },
        funcName: 'update',
        requiresToken: true,
    });

    const takeFloor = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'takeFloor'>, fetcher) => {
            const {
                participantUuid = currentParticipantUuid,
                conferenceAlias = currentConferenceAlias,
                host = currentHost,
                breakoutUuid = currentBreakoutUuid,
            } = reqParams[0];

            const breakoutParticipantUuid =
                getBreakoutParticipantUuid(breakoutUuid);

            return breakoutUuid && breakoutParticipantUuid
                ? API.breakoutTakeFloor({
                      fetcher,
                      params: {
                          conferenceAlias,
                          participantUuid: breakoutParticipantUuid,
                          breakoutUuid,
                      },
                      host,
                  })
                : API.takeFloor({
                      fetcher,
                      params: {
                          conferenceAlias,
                          participantUuid,
                      },
                      host,
                  });
        },
        funcName: 'takeFloor',
        requiresToken: true,
    });

    const releaseFloor = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'releaseFloor'>, fetcher) => {
            const {
                participantUuid = currentParticipantUuid,
                conferenceAlias = currentConferenceAlias,
                host = currentHost,
                breakoutUuid = currentBreakoutUuid,
            } = reqParams[0];

            const breakoutParticipantUuid =
                getBreakoutParticipantUuid(breakoutUuid);

            return breakoutUuid && breakoutParticipantUuid
                ? API.breakoutReleaseFloor({
                      fetcher,
                      params: {
                          conferenceAlias,
                          participantUuid: breakoutParticipantUuid,
                          breakoutUuid,
                      },
                      host,
                  })
                : API.releaseFloor({
                      fetcher,
                      params: {
                          conferenceAlias,
                          participantUuid,
                      },
                      host,
                  });
        },
        funcName: 'releaseFloor',
        requiresToken: true,
        retriable: true,
    });

    const cleanupSignals = () => {
        detachSignals.forEach(detach => detach());
        detachSignals = [];
    };

    const cleanup = () => {
        logger.debug('Cleanup');
        currentCall?.disconnect();
        currentCall = undefined;
        eventSourceManager?.close();
        eventSourceManager = undefined;
        currentCallUuid = undefined;
        currentBreakoutUuid = undefined;
        currentPresenterUuid = undefined;
        dataChannelId = undefined;
        latestStats = undefined;
        useRelayCandidatesOnly = undefined;
        participants = createParticipants();
        breakoutRooms = new Map();
        secureCheckCode = '';
        conferenceStatus = new Map();
        currentPin = undefined;
        currentChosenIdp = NONE;
        currentSsoToken = NONE;
        currentConferenceExtension = undefined;
        currentCallTag = undefined;
        currentCallTypeQueryParameter = undefined;
        cleanupSignals();
        Object.values(timerIDs).map(timer => clearTimeout(timer));
    };

    const releaseToken = async (reason?: DisconnectReason) => {
        await requestClient?.cleanup(reason);
        requestClient = undefined;
    };

    const cleanupAndDisconnect = (error: ExtendedInfinityErrorMessage) => {
        signals.onDisconnected.emit({
            error,
            errorCode: getErrorCode(error as InfinityErrorMessage), // Match API error or get a default errorCode
        });
        cleanup();
        void releaseToken();
    };

    const disconnectRequest = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'disconnect'>, fetcher) => {
            const {
                callUuid = currentCallUuid,
                conferenceAlias = currentConferenceAlias,
                breakoutUuid = currentBreakoutUuid,
                host = currentHost,
                participantUuid = currentParticipantUuid,
                reason,
                callback,
                release = releaseToken,
            } = reqParams[0];

            if (callback) {
                await callback();
            } else {
                cleanup();
            }

            const breakoutParticipantUuid =
                getBreakoutParticipantUuid(breakoutUuid);

            if (callUuid && breakoutUuid && breakoutParticipantUuid) {
                await API.breakoutDisconnectCall({
                    fetcher,
                    params: {
                        conferenceAlias,
                        breakoutUuid,
                        callUuid,
                        participantUuid: breakoutParticipantUuid,
                    },
                    host,
                });
                return;
            }

            if (callUuid && reason !== 'Browser closed') {
                try {
                    await API.disconnectCall({
                        fetcher,
                        params: {
                            conferenceAlias,
                            participantUuid,
                            callUuid,
                        },
                        host,
                    });
                } catch (reason) {
                    logger.warn({reason}, 'Unable to disconnect a call');
                }
            }

            await release(reason);
        },
        funcName: 'disconnect',
        requiresToken: true,
    });

    const disconnect = async (
        ...params: Parameters<typeof disconnectRequest>
    ) => {
        if (restartCallPromise) {
            await restartCallPromise;
        }
        disconnectPromise = disconnectRequest(...params);
        return disconnectPromise;
    };

    const handleParticipantCreate: EventHandler<Participant> = (
        id,
        participant,
    ) => {
        const isCurrentParticipant =
            participant.uuid ===
            (id === 'main'
                ? currentParticipantUuid
                : getBreakoutParticipantUuid(id));

        if (isCurrentParticipant && participant.serviceType) {
            setServiceType(participant.serviceType);
        }
        participants.set(id, participant);

        if (isCurrentParticipant) {
            signals.onMe.emit({id, participant});
        }
        signals.onParticipantJoined.emit({id, participant});
        signals.onParticipants.emit({
            id,
            participants: participants.getAll(id),
        });
    };

    const handleParticipantUpdate: EventHandler<Participant> = (
        id,
        participant,
    ) => {
        const isCurrentParticipant =
            participant.uuid ===
            (id === 'main'
                ? currentParticipantUuid
                : getBreakoutParticipantUuid(id));

        if (isCurrentParticipant) {
            const muteChanged =
                participant.isMuted !==
                participants.get(id, participant.uuid)?.isMuted;

            if (participant.canMute && muteChanged) {
                signals.onMyselfMuted.emit(participant.isMuted);
            }

            if (participant.serviceType) {
                setServiceType(participant.serviceType);
            }
        } else if (
            participants.get(id, participant.uuid) &&
            participant.raisedHand !==
                participants.get(id, participant.uuid)?.raisedHand
        ) {
            signals.onRaiseHand.emit({id, participant});
        }

        participants.set(id, participant);

        if (isCurrentParticipant) {
            signals.onMe.emit({id, participant});
        }
        signals.onParticipants.emit({
            id,
            participants: participants.getAll(id),
        });
    };

    const handleParticipantDelete: EventHandler<Participant> = (
        id,
        participant,
    ) => {
        participants.remove(id, participant.uuid);
        signals.onParticipantLeft.emit({
            id,
            participant,
        });
        signals.onParticipants.emit({
            id,
            participants: participants.getAll(id),
        });
    };

    const handleOnMessage: EventHandler<MessageEvent> = (
        roomId,
        {type, uuid: userId, payload: message, direct, origin},
    ) => {
        const meta = {
            at: new Date(),
            id: uuid(),
            roomId,
            displayName:
                (userId && participants.get('main', userId)?.displayName) ||
                origin,
            userId,
            direct,
        };

        const [payloadType] = (type ?? '').split(';');
        if (payloadType === 'text/plain') {
            signals.onMessage.emit({
                ...meta,
                message,
            });
        } else {
            try {
                signals.onApplicationMessage.emit({
                    ...meta,
                    message: JSON.parse(message) as Record<string, unknown>,
                });
            } catch (error) {
                logger.error(
                    {error},
                    'Could not parse application message payload',
                );
            }
        }
    };

    const handleConferenceStatus: EventHandler<ConferenceStatus> = (
        roomId,
        status,
    ) => {
        conferenceStatus.set(roomId, status);
        signals.onConferenceStatus.emit({id: roomId, status});
    };

    const subscribeSignals = () => [
        eventSignals.onPresentationStart.add(event => {
            const currentRoom = currentBreakoutUuid ?? 'main';
            const presenterUuid = event.presenterUuid;
            if (presenterUuid && currentRoom === 'main') {
                currentPresenterUuid = presenterUuid;
                void currentCall?.receivePresentation(event);
            }
            presentationCache.set('main', event);
        }),
        eventSignals.onPresentationStop.add(() => {
            const currentRoom = currentBreakoutUuid ?? 'main';
            if (currentPresenterUuid && currentRoom === 'main') {
                currentPresenterUuid = undefined;
                currentCall?.stopReceivingPresentation();
            }
            presentationCache.delete('main');
        }),
        eventSignals.onCallDisconnected.add(({call_uuid: callUuid}) => {
            logger.debug({callUuid, currentCallUuid}, 'onCallDisconnected');
            currentCall?.disconnect();
            currentCallUuid = undefined;
            signals.onCallDisconnected.emit({call_uuid: callUuid});
        }),
        eventSignals.onBreakoutBegin.add(room => {
            breakoutRooms.set(room.breakout_uuid, room);
            signals.onBreakoutBegin.emit(room);
        }),
        eventSignals.onBreakoutEvent.add(event => {
            switch (event.event) {
                case 'participant_sync_begin': {
                    participants.set(event.breakout_uuid);
                    break;
                }
                case 'participant_create': {
                    handleParticipantCreate(
                        event.breakout_uuid,
                        normalizeParticipant(event.data),
                    );
                    break;
                }
                case 'participant_update': {
                    handleParticipantUpdate(
                        event.breakout_uuid,
                        normalizeParticipant(event.data),
                    );
                    break;
                }
                case 'participant_delete': {
                    const participant = participants.get(
                        event.breakout_uuid,
                        event.data.uuid,
                    );
                    if (participant) {
                        handleParticipantDelete(
                            event.breakout_uuid,
                            participant,
                        );
                    }
                    break;
                }
                case 'participant_sync_end': {
                    break;
                }
                case 'message_received': {
                    handleOnMessage(event.breakout_uuid, event.data);
                    break;
                }
                case 'conference_update': {
                    handleConferenceStatus(
                        event.breakout_uuid,
                        normalizeConferenceState(event.data),
                    );
                    break;
                }

                case 'presentation_start': {
                    if (event.breakout_uuid === currentBreakoutUuid) {
                        void currentCall?.receivePresentation(
                            normalizePresentationEvent(event.data),
                        );
                    }
                    // Cache presentation_start event if presentation is started
                    presentationCache.set(
                        event.breakout_uuid,
                        normalizePresentationEvent(event.data),
                    );
                    break;
                }

                case 'presentation_stop': {
                    if (event.breakout_uuid === currentBreakoutUuid) {
                        currentCall?.stopReceivingPresentation();
                    }
                    // Clear cached presentation_start event if presentation is stopped
                    presentationCache.delete(event.breakout_uuid);
                    break;
                }

                case 'live_captions': {
                    if (event.breakout_uuid === currentBreakoutUuid) {
                        signals.onLiveCaptions.emit({
                            data: event.data.data,
                            isFinal: event.data.is_final,
                        });
                    }
                    break;
                }
            }
        }),
        eventSignals.onBreakoutEnd.add(room => {
            breakoutRooms.delete(room.breakout_uuid);
            participants.remove(room.breakout_uuid);
            signals.onBreakoutEnd.emit(room);
        }),
        eventSignals.onParticipantCreate.add(participant => {
            handleParticipantCreate('main', participant);
        }),
        eventSignals.onParticipantUpdate.add(participant => {
            handleParticipantUpdate('main', participant);
        }),
        eventSignals.onParticipantDelete.add(uuid => {
            const leftParticipant = participants.get('main', uuid);
            if (leftParticipant) {
                handleParticipantDelete('main', leftParticipant);
            }
        }),
        eventSignals.onParticipantSyncBegin.add(() => {
            participants.set('main');
        }),
        eventSignals.onMessage.add(event => {
            handleOnMessage('main', event);
        }),
        eventSignals.onLayoutUpdate.add(event => {
            signals.onRequestedLayout.emit({
                primaryScreen: {
                    hostLayout:
                        event.requested_layout?.primary_screen.chair_layout,
                    guestLayout:
                        event.requested_layout?.primary_screen.guest_layout,
                },
            });
            signals.onLayoutOverlayTextEnabled.emit(
                !!event.overlay_text_enabled,
            );
            signals.onLayoutUpdate.emit(event);
        }),
        eventSignals.onStageUpdate.add(stages =>
            signals.onStage.emit(
                stages.map(stage => ({
                    userId: stage.participant_uuid,
                    stageIndex: stage.stage_index,
                    vad: stage.vad,
                })),
            ),
        ),
        eventSignals.onDisconnect.add(({reason}) => {
            cleanupAndDisconnect(reason);
        }),
        eventSignals.onConferenceUpdate.add(status => {
            conferenceStatus.set('main', status);
            signals.onConferenceStatus.emit({id: 'main', status});
            handleConferenceStatus('main', status);
        }),
        eventSignals.onRefer.add(details =>
            signals.onTransfer.emit({
                ...details,
                callTag: currentCallTag,
                breakoutName: details.breakout_name,
                target: details.target,
            }),
        ),
        eventSignals.onCancelRefer.add(signals.onCancelTransfer.emit),
        eventSignals.onLiveCaptions.add(captions => {
            signals.onLiveCaptions.emit({
                data: captions.data,
                isFinal: captions.is_final,
            });
        }),
        eventSignals.onSplashScreen.add(result => {
            if (!result || !('screen_key' in result)) {
                signals.onSplashScreen.emit();
                return;
            }
            const splashScreen = themeSchema?.[result.screen_key];
            if (splashScreen) {
                signals.onSplashScreen.emit({
                    screenKey: result.screen_key,
                    text: splashScreen.elements[0]?.text ?? '',
                    background: getBackgroundUrl(splashScreen.background.path),
                    displayDuration: result.display_duration * 1000,
                });
            }
        }),
        eventSignals.onNewOffer.add(({sdp}) => {
            signals.onNewOffer.emit(sdp);
        }),
        eventSignals.onUpdateSdp.add(({sdp}) => {
            signals.onUpdateSdp.emit(sdp);
        }),
        eventSignals.onNewCandidate.add(
            ({candidate, mid: sdpMid, ufrag: usernameFragment}) => {
                signals.onIceCandidate.emit(
                    new RTCIceCandidate({candidate, sdpMid, usernameFragment}),
                );
            },
        ),
        eventSignals.onPeerDisconnect.add(() => {
            callSignals.onSecureCheckCode.emit('');
            signals.onPeerDisconnect.emit();
        }),
        eventSignals.onRefreshToken.add(() => {
            void requestClient?.refreshToken();
        }),
        eventSignals.onBreakoutRefer.add(
            ({breakout_uuid, breakout_name, requester_uuid}) =>
                signals.onBreakoutRefer.emit({
                    breakoutUuid: breakout_uuid,
                    breakoutName: breakout_name,
                    requesterUuid: requester_uuid,
                }),
        ),
        eventSignals.onFecc.add(feccEvent => {
            signals.onFecc.emit(feccEvent);
        }),
        callSignals.onSecureCheckCode.add(code => {
            secureCheckCode = code;
        }),
        callSignals.onRtcStats.add(stats => {
            latestStats = {
                inbound: {
                    audio: stats.inbound.audio ?? latestStats?.inbound.audio,
                    video: stats.inbound.video ?? latestStats?.inbound.video,
                    preso: stats.inbound.preso ?? latestStats?.inbound.preso,
                },
                outbound: {
                    audio: stats.outbound.audio ?? latestStats?.outbound.audio,
                    video: stats.outbound.video ?? latestStats?.outbound.video,
                    preso: stats.outbound.preso ?? latestStats?.outbound.preso,
                },
            };
        }),
        callSignals.onCallConnected.add(signals.onConnected.emit),
    ];

    const present = (stream?: MediaStream) => {
        void currentCall?.present(stream);
    };

    const stopPresenting = () => {
        void currentCall?.stopPresenting();
    };

    const setStream = (stream: MediaStream) => {
        currentCall?.setStream(stream);
    };

    const setBandwidth = (bandwidth: number) => {
        currentCall?.setBandwidth({
            in: getBandwidth(bandwidth, bandwidthIn),
            out: getBandwidth(bandwidth, bandwidthOut),
        });
    };

    const setLayout = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'setLayout'>, fetcher) => {
            const {
                transforms,
                conferenceAlias = currentConferenceAlias,
                host = currentHost,
                breakoutUuid = currentBreakoutUuid,
            } = reqParams[0];

            if (breakoutUuid) {
                return API.breakoutTransformLayout({
                    fetcher,
                    params: {
                        conferenceAlias,
                        breakoutUuid,
                    },
                    host,
                    body: {
                        transforms,
                    },
                });
            }
            return API.transformLayout({
                fetcher,
                params: {
                    conferenceAlias,
                },
                host,
                body: {
                    transforms,
                },
            });
        },
        funcName: 'setLayout',
        requiresToken: true,
    });

    const raiseHand = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'raiseHand'>, fetcher) => {
            const {
                raise,
                conferenceAlias = currentConferenceAlias,
                host = currentHost,
                participantUuid = getMe()?.uuid ?? currentParticipantUuid,
                breakoutUuid = currentBreakoutUuid,
            } = reqParams[0];

            if (breakoutUuid) {
                return API[
                    raise
                        ? 'breakoutBuzzParticipant'
                        : 'breakoutClearbuzzParticipant'
                ]({
                    fetcher,
                    params: {
                        conferenceAlias,
                        participantUuid,
                        breakoutUuid,
                    },
                    host,
                });
            }
            return API[raise ? 'buzzParticipant' : 'clearbuzzParticipant']({
                fetcher,
                params: {
                    conferenceAlias,
                    participantUuid,
                },
                host,
            });
        },
        funcName: 'raiseHand',
        requiresToken: true,
    });

    const lowerAllRaisedHands = generateEndpoint({
        func: async (
            reqParams: GetEndpointParams<'lowerAllRaisedHands'>,
            fetcher,
        ) => {
            const {
                conferenceAlias = currentConferenceAlias,
                host = currentHost,
                breakoutUuid = currentBreakoutUuid,
            } = reqParams[0];

            if (breakoutUuid) {
                return API.breakoutClearAllBuzz({
                    fetcher,
                    params: {
                        conferenceAlias,
                        breakoutUuid,
                    },
                    host,
                });
            }
            return API.clearAllBuzz({
                fetcher,
                params: {
                    conferenceAlias,
                },
                host,
            });
        },
        funcName: 'lowerAllRaisedHands',
        requiresToken: true,
    });

    const spotlight = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'spotlight'>, fetcher) => {
            const {
                enable,
                participantUuid,
                conferenceAlias = currentConferenceAlias,
                breakoutUuid = currentBreakoutUuid,
                host = currentHost,
            } = reqParams[0];

            if (breakoutUuid) {
                return API[
                    enable
                        ? 'breakoutSpotlightonParticipant'
                        : 'breakoutSpotlightoffParticipant'
                ]({
                    fetcher,
                    params: {
                        conferenceAlias,
                        breakoutUuid,
                        participantUuid,
                    },
                    host,
                });
            }

            return API[
                enable ? 'spotlightonParticipant' : 'spotlightoffParticipant'
            ]({
                fetcher,
                params: {
                    conferenceAlias,
                    participantUuid,
                },
                host,
            });
        },
        funcName: 'spotlight',
        requiresToken: true,
    });

    const sendMessageRequest = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'sendMessage'>, fetcher) => {
            const {
                payload,
                type,
                participantUuid,
                conferenceAlias = currentConferenceAlias,
                host = currentHost,
                breakoutUuid = currentBreakoutUuid,
            } = reqParams[0];

            if (currentCall && conferenceFeatureFlags?.isDirectMedia) {
                // Use Logical OR: Fallback display name if the participant don't have a display name explicitly set
                const origin =
                    participants.get('main', currentParticipantUuid)
                        ?.displayName || 'User'; // empty string should be replaced
                currentCall.sendDataChannelEvent({
                    type: 'message',
                    body: {
                        type,
                        origin,
                        uuid: currentParticipantUuid,
                        payload,
                    },
                });
                return Promise.resolve({
                    status: 200,
                    data: {status: 'success', result: true},
                } as GetEndpointResponse<'sendMessage'>);
            } else {
                const common = {
                    fetcher,
                    params: {
                        conferenceAlias,
                        participantUuid,
                    },
                    host,
                    body: {
                        payload,
                        type,
                    },
                };
                if (participantUuid) {
                    return API.messageParticipant({
                        ...common,
                        params: {
                            conferenceAlias,
                            participantUuid,
                        },
                    });
                }
                if (breakoutUuid) {
                    return API.breakoutMessageConference({
                        ...common,
                        params: {
                            conferenceAlias,
                            breakoutUuid,
                        },
                    });
                }
                return API.messageConference({
                    ...common,
                    params: {
                        conferenceAlias,
                    },
                });
            }
        },
        funcName: 'sendMessage',
        requiresToken: true,
        retriable: true,
    });

    const sendMessage = async (
        params: Parameters<InfinityClient['sendMessage']>[0],
    ) => {
        return sendMessageRequest({...params, type: 'text/plain'});
    };

    const sendApplicationMessage = async (
        params: Parameters<InfinityClient['sendApplicationMessage']>[0],
    ) => {
        try {
            const payload = JSON.stringify(params.payload);
            return sendMessageRequest({
                ...params,
                payload,
                type: 'application/json',
            });
        } catch (error) {
            logger.error({error}, 'Could not stringify application message');
        }
    };

    const admit = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'admit'>, fetcher) => {
            const {
                participantUuid,
                conferenceAlias = currentConferenceAlias,
                host = currentHost,
                breakoutUuid = currentBreakoutUuid,
            } = reqParams[0];

            if (breakoutUuid) {
                return API.breakoutUnlockParticipant({
                    fetcher,
                    params: {
                        conferenceAlias,
                        participantUuid,
                        breakoutUuid,
                    },
                    host,
                });
            }

            return API.unlockParticipant({
                fetcher,
                params: {
                    conferenceAlias,
                    participantUuid,
                },
                host,
            });
        },
        funcName: 'admit',
        requiresToken: true,
    });

    const mute = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'mute'>, fetcher) => {
            const {
                mute,
                participantUuid = getMe()?.uuid ?? currentParticipantUuid,
                conferenceAlias = currentConferenceAlias,
                breakoutUuid = currentBreakoutUuid,
                host = currentHost,
            } = reqParams[0];

            if (breakoutUuid) {
                return API[
                    mute
                        ? 'breakoutMuteParticipant'
                        : 'breakoutUnmuteParticipant'
                ]({
                    fetcher,
                    params: {
                        conferenceAlias,
                        participantUuid,
                        breakoutUuid,
                    },
                    host,
                });
            }

            return API[mute ? 'muteParticipant' : 'unmuteParticipant']({
                fetcher,
                params: {
                    conferenceAlias,
                    participantUuid,
                },
                host,
            });
        },
        funcName: 'mute',
        requiresToken: true,
    });

    const clientMute = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'mute'>, fetcher) => {
            const {
                mute,
                participantUuid = getMe()?.uuid ?? currentParticipantUuid,
                conferenceAlias = currentConferenceAlias,
                breakoutUuid = currentBreakoutUuid,
                host = currentHost,
            } = reqParams[0];

            if (breakoutUuid) {
                return API[
                    mute
                        ? 'breakoutClientMuteParticipant'
                        : 'breakoutClientUnmuteParticipant'
                ]({
                    fetcher,
                    params: {
                        conferenceAlias,
                        participantUuid,
                        breakoutUuid,
                    },
                    host,
                });
            }

            return API[
                mute ? 'clientMuteParticipant' : 'clientUnmuteParticipant'
            ]({
                fetcher,
                params: {
                    conferenceAlias,
                    participantUuid,
                },
                host,
            });
        },
        funcName: 'mute',
        requiresToken: true,
    });

    const muteAllGuests = generateEndpoint({
        func: async (
            reqParams: GetEndpointParams<'muteAllGuests'>,
            fetcher,
        ) => {
            const {
                mute,
                conferenceAlias = currentConferenceAlias,
                host = currentHost,
            } = reqParams[0];

            return API[mute ? 'muteguests' : 'unmuteguests']({
                fetcher,
                params: {
                    conferenceAlias,
                },
                host,
            });
        },
        funcName: 'muteAllGuests',
        requiresToken: true,
    });

    const guestsCanUnmute = generateEndpoint({
        func: async (
            reqParams: GetEndpointParams<'guestsCanUnmute'>,
            fetcher,
        ) => {
            const {
                setting,
                conferenceAlias = currentConferenceAlias,
                host = currentHost,
            } = reqParams[0];

            return API.setGuestsCanUnmute({
                fetcher,
                params: {
                    conferenceAlias,
                },
                host,
                body: {
                    setting,
                },
            });
        },
        funcName: 'guestsCanUnmute',
        requiresToken: true,
    });

    const muteVideo = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'muteVideo'>, fetcher) => {
            const {
                muteVideo,
                participantUuid = getMe()?.uuid ?? currentParticipantUuid,
                conferenceAlias = currentConferenceAlias,
                breakoutUuid = currentBreakoutUuid,
                host = currentHost,
            } = reqParams[0];

            if (breakoutUuid) {
                return API[
                    muteVideo
                        ? 'breakoutVideoMuteParticipant'
                        : 'breakoutVideoUnmuteParticipant'
                ]({
                    fetcher,
                    params: {
                        breakoutUuid,
                        conferenceAlias,
                        participantUuid,
                    },
                    host,
                });
            }

            return API[
                muteVideo ? 'videoMuteParticipant' : 'videoUnmuteParticipant'
            ]({
                fetcher,
                params: {
                    conferenceAlias,
                    participantUuid,
                },
                host,
            });
        },
        funcName: 'muteVideo',
        requiresToken: true,
    });

    const lock = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'lock'>, fetcher) => {
            const {
                lock,
                conferenceAlias = currentConferenceAlias,
                host = currentHost,
                breakoutUuid = currentBreakoutUuid,
            } = reqParams[0];

            if (breakoutUuid) {
                return API[lock ? 'breakoutLock' : 'breakoutUnlock']({
                    fetcher,
                    params: {
                        conferenceAlias,
                        breakoutUuid,
                    },
                    host,
                });
            }
            return API[lock ? 'lock' : 'unlock']({
                fetcher,
                params: {
                    conferenceAlias,
                },
                host,
            });
        },
        funcName: 'lock',
        requiresToken: true,
    });

    const disconnectAll = generateEndpoint({
        func: async (
            reqParams: GetEndpointParams<'disconnectAll'>,
            fetcher,
        ) => {
            const {
                conferenceAlias = currentConferenceAlias,
                host = currentHost,
            } = reqParams[0];

            return API.disconnect({
                fetcher,
                params: {
                    conferenceAlias,
                },
                host,
            });
        },
        funcName: 'disconnectAll',
        requiresToken: true,
    });

    const kick = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'kick'>, fetcher) => {
            const {
                participantUuid,
                conferenceAlias = currentConferenceAlias,
                host = currentHost,
                breakoutUuid = currentBreakoutUuid,
            } = reqParams[0];

            if (breakoutUuid) {
                return API.breakoutDisconnectParticipant({
                    fetcher,
                    params: {
                        conferenceAlias,
                        participantUuid,
                        breakoutUuid,
                    },
                    host,
                });
            }

            return API.disconnectParticipant({
                fetcher,
                params: {
                    conferenceAlias,
                    participantUuid,
                },
                host,
            });
        },
        funcName: 'kick',
        requiresToken: true,
    });

    const dial = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'dial'>, fetcher) => {
            const {
                conferenceAlias = currentConferenceAlias,
                host = currentHost,
                protocol = 'auto',
                ...body
            } = reqParams[0];

            return API.dial({
                fetcher,
                params: {
                    conferenceAlias,
                },
                body: {
                    protocol,
                    ...body,
                },
                host,
            });
        },
        funcName: 'dial',
        requiresToken: true,
    });

    const transfer = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'transfer'>, fetcher) => {
            const {
                destination,
                pin,
                role,
                participantUuid,
                conferenceAlias = currentConferenceAlias,
                host = currentHost,
                breakoutUuid = currentBreakoutUuid,
            } = reqParams[0];

            if (breakoutUuid) {
                return API.breakoutTransferParticipant({
                    fetcher,
                    body: {
                        conference_alias: destination,
                        pin,
                        role,
                    },
                    params: {
                        conferenceAlias,
                        breakoutUuid,
                        participantUuid,
                    },
                    host,
                });
            }

            return API.transferParticipant({
                fetcher,
                body: {
                    conference_alias: destination,
                    pin,
                    role,
                },
                params: {
                    conferenceAlias,
                    participantUuid,
                },
                host,
            });
        },
        funcName: 'transfer',
        requiresToken: true,
    });

    const liveCaptions = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'liveCaptions'>, fetcher) => {
            const {
                enable,
                conferenceAlias = currentConferenceAlias,
                participantUuid = currentParticipantUuid,
                breakoutUuid = currentBreakoutUuid,
            } = reqParams[0];

            const breakoutParticipantUuid =
                getBreakoutParticipantUuid(breakoutUuid);

            return breakoutUuid && breakoutParticipantUuid
                ? API[
                      enable
                          ? 'breakoutShowLiveCaptions'
                          : 'breakoutHideLiveCaptions'
                  ]({
                      fetcher,
                      params: {
                          conferenceAlias,
                          participantUuid: breakoutParticipantUuid,
                          breakoutUuid,
                      },
                      host: currentHost,
                  })
                : API[enable ? 'showLiveCaptions' : 'hideLiveCaptions']({
                      fetcher,
                      params: {
                          conferenceAlias,
                          participantUuid,
                      },
                      host: currentHost,
                  });
        },
        funcName: 'liveCaptions',
        requiresToken: true,
    });

    const setRole = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'setRole'>, fetcher) => {
            const {
                role,
                participantUuid,
                conferenceAlias = currentConferenceAlias,
            } = reqParams[0];

            await API.roleParticipant({
                fetcher,
                body: {
                    role,
                },
                params: {
                    conferenceAlias,
                    participantUuid,
                },
                host: currentHost,
            });
        },
        funcName: 'setRole',
        requiresToken: true,
    });

    const setPin = (pin = NONE) => {
        currentPin = pin;
    };

    const setSsoToken = (ssoToken = NONE) => {
        currentSsoToken = ssoToken;
    };

    const setConferenceExtension = (conferenceExtension?: string) => {
        currentConferenceExtension = conferenceExtension;
    };

    const setCallTag = (callTag?: string) => {
        currentCallTag = callTag;
    };

    const statistics = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'statistics'>, fetcher) => {
            const {
                audio,
                video,
                presentation,
                conferenceAlias = currentConferenceAlias,
                participantUuid = currentParticipantUuid,
                callUuid = currentCallUuid,
                host = currentHost,
            } = reqParams[0];

            if (!callUuid) {
                return;
            }

            await API.statistics({
                fetcher,
                body: {
                    audio,
                    video,
                    presentation,
                },
                params: {
                    conferenceAlias,
                    participantUuid,
                    callUuid,
                },
                host,
            });
        },
        funcName: 'statistics',
        requiresToken: true,
    });

    const requestTheme = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'requestTheme'>, fetcher) => {
            const {
                conferenceAlias = currentConferenceAlias,
                host = currentHost,
            } = reqParams[0];

            try {
                const response = await API.theme({
                    fetcher,
                    params: {
                        conferenceAlias,
                    },
                    host,
                });

                if (response.status !== 200) {
                    return;
                }

                return response.data.result;
            } catch (error) {
                logger.error({error}, `Can't request theme.`);
            }
        },
        funcName: 'requestTheme',
        requiresToken: true,
    });

    const setTextOverlay = generateEndpoint({
        func: async (
            reqParams: GetEndpointParams<'setTextOverlay'>,
            fetcher,
        ) => {
            const {
                text,
                conferenceAlias = currentConferenceAlias,
                host = currentHost,
                participantUuid,
            } = reqParams[0];

            return API.overlaytextParticipant({
                fetcher,
                params: {
                    conferenceAlias,
                    participantUuid,
                },
                body: {
                    text,
                },
                host,
            });
        },
        funcName: 'setTextOverlay',
        requiresToken: true,
    });

    const sendDTMF = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'sendDTMF'>, fetcher) => {
            const {
                digits,
                participantUuid,
                callUuid,
                conferenceAlias = currentConferenceAlias,
                breakoutUuid = currentBreakoutUuid,
                host = currentHost,
            } = reqParams[0];

            const common = {
                fetcher,
                body: {
                    digits,
                },
                host,
            };

            if (!participantUuid) {
                if (!currentCallUuid) {
                    throw new Error('Call uuid is not present');
                }
                return API.dtmf({
                    ...common,
                    params: {
                        conferenceAlias,
                        participantUuid: currentParticipantUuid,
                        callUuid: currentCallUuid,
                    },
                });
            }

            if (callUuid) {
                return API.dtmf({
                    ...common,
                    params: {
                        conferenceAlias,
                        participantUuid,
                        callUuid,
                    },
                });
            }

            if (breakoutUuid) {
                return API.breakoutDtmfParticipant({
                    ...common,
                    params: {
                        conferenceAlias,
                        breakoutUuid,
                        participantUuid,
                    },
                });
            }

            return API.dtmfParticipant({
                ...common,
                params: {
                    conferenceAlias,
                    participantUuid,
                },
            });
        },
        funcName: 'sendDTMF',
        requiresToken: true,
    });

    const requestAspectRatio = (
        reqParams: Parameters<Client['requestAspectRatio']>[0],
    ) => {
        if (conferenceFeatureFlags?.isDirectMedia) {
            return Promise.resolve(undefined);
        }

        const fetcher = requestClient?.fetcher;

        if (!fetcher) {
            return Promise.reject(
                new Error('No fetcher defined to call requestAspectRatio'),
            );
        }

        const {
            aspectRatio,
            conferenceAlias = currentConferenceAlias,
            host = currentHost,
            participantUuid = currentParticipantUuid,
            abortSignal,
        } = reqParams;

        return API.preferredAspectRatio({
            fetcher,
            params: {
                conferenceAlias,
                participantUuid,
            },
            body: {
                aspect_ratio: aspectRatio,
            },
            host,
            init: {signal: abortSignal},
        });
    };

    const breakout = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'breakout'>, fetcher) => {
            const res = await API.breakouts({
                fetcher,
                params: {
                    conferenceAlias: currentConferenceAlias,
                },
                body: reqParams[0],
                host: currentHost,
            });
            return res.data.result;
        },
        funcName: 'breakout',
        requiresToken: true,
    });

    const breakoutMoveParticipants = generateEndpoint({
        func: async (
            reqParams: GetEndpointParams<'breakoutMoveParticipants'>,
            fetcher,
        ) => {
            const {fromBreakoutUuid, toRoomUuid, participants} = reqParams[0];

            fromBreakoutUuid
                ? await API.breakoutMoveParticipants({
                      fetcher,
                      params: {
                          conferenceAlias: currentConferenceAlias,
                          breakoutUuid: fromBreakoutUuid,
                      },
                      body: {
                          breakout_uuid: toRoomUuid,
                          participants: participants,
                      },
                      host: currentHost,
                  })
                : await API.breakoutMoveParticipantsFromMain({
                      fetcher,
                      params: {
                          conferenceAlias: currentConferenceAlias,
                      },
                      body: {
                          breakout_uuid: toRoomUuid,
                          participants: participants,
                      },
                      host: currentHost,
                  });
            return;
        },
        funcName: 'breakoutMoveParticipants',
        requiresToken: true,
    });

    const closeBreakouts = generateEndpoint({
        func: async (
            _reqParams: GetEndpointParams<'closeBreakouts'>,
            fetcher,
        ) => {
            return API.breakoutsDisconnect({
                fetcher,
                params: {
                    conferenceAlias: currentConferenceAlias,
                },
                host: currentHost,
            });
        },
        funcName: 'closeBreakouts',
        requiresToken: true,
    });

    const closeBreakoutRoom = generateEndpoint({
        func: async (
            reqParams: GetEndpointParams<'closeBreakoutRoom'>,
            fetcher,
        ) => {
            return API.breakoutDisconnect({
                fetcher,
                params: {
                    conferenceAlias: currentConferenceAlias,
                    breakoutUuid: reqParams[0].breakoutUuid,
                },
                host: currentHost,
            });
        },
        funcName: 'closeBreakoutRoom',
        requiresToken: true,
    });

    const emptyBreakouts = generateEndpoint({
        func: async (
            _reqParams: GetEndpointParams<'emptyBreakouts'>,
            fetcher,
        ) => {
            return API.breakoutsEmpty({
                fetcher,
                params: {
                    conferenceAlias: currentConferenceAlias,
                },
                host: currentHost,
            });
        },
        funcName: 'emptyBreakouts',
        requiresToken: true,
    });

    // TODO: use generateEndpoint?
    const breakoutTransferHost = async (reqParams: {breakoutUuid?: string}) => {
        const bandwidth = (currentCall?.bandwidth as Bandwidth) ?? {
            in: 0,
            out: 0,
        };
        const mediaStream = currentCall?.mediaStream;
        await disconnectRequest({
            /**
             * There is a specific case for direct media call when
             * one peer disconnects we need to restart webrtc call
             * and wait for another participant to arrive
             * so we need to keep EventSource open to wait for the
             * ping from mcu for that.
             */
            callback: async () => {
                if (
                    currentCall?.presoState.send === 'connected' ||
                    currentCall?.presoState.send === 'connecting'
                ) {
                    await currentCall?.stopPresenting();
                }
                currentCall?.disconnect();
                currentCall = undefined;
                currentCallUuid = undefined;
            },
            // Reuse existing token for this use case
            release: () => {
                return Promise.resolve();
            },
        });
        currentBreakoutUuid =
            reqParams.breakoutUuid === 'main'
                ? undefined
                : reqParams.breakoutUuid;
        startCall({
            bandwidth,
            mediaStream,
        });
        // Propagate presentation event if room has a running presentation
        // We assume that we join main room if currentBreakoutUuid is undefined
        const presentationEvent = presentationCache.get(
            currentBreakoutUuid ?? 'main',
        );
        if (presentationEvent) {
            await currentCall?.receivePresentation(presentationEvent);
        }
    };
    const joinBreakoutRoom = (opt: {breakoutUuid?: string}) =>
        breakoutTransferHost({
            breakoutUuid: opt.breakoutUuid,
        });

    const guestLeaveBreakoutRoom = generateEndpoint({
        func: async (
            _reqParams: GetEndpointParams<'guestLeaveBreakoutRoom'>,
            fetcher,
        ) => {
            return API.guestLeaveBreakout({
                fetcher,
                params: {
                    conferenceAlias: currentConferenceAlias,
                },
                host: currentHost,
            });
        },
        funcName: 'guestLeaveBreakoutRoom',
        requiresToken: true,
    });

    const sendConferenceRequest = generateEndpoint({
        func: async (
            reqParams: GetEndpointParams<'sendConferenceRequest'>,
            fetcher,
        ) => {
            const {path, method, payload} = reqParams[0];
            const uri = `${currentHost}/api/client/v2/conferences/${currentConferenceAlias}/${path}`;
            try {
                const body = payload ? JSON.stringify(payload) : undefined;
                const res = await fetcher(uri, {
                    method,
                    body,
                    headers: {
                        'Content-Type': APPLICATION_JSON,
                    },
                });

                const responseContentType = res.headers.get('Content-Type');
                if (responseContentType !== APPLICATION_JSON) {
                    logger.error(
                        {responseContentType, APPLICATION_JSON},
                        `Response Content-Type is not ${APPLICATION_JSON}`,
                    );
                    return {status: res.status, data: undefined};
                }
                try {
                    const data: unknown = await res.json();
                    return {status: res.status, data};
                } catch (error) {
                    logger.error({error}, 'Could not get response data: ');
                }
            } catch (error) {
                logger.error({error}, 'Could not stringify payload');
            }
        },
        funcName: 'sendConferenceRequest',
        requiresToken: true,
    });

    const requestParticipants = generateEndpoint({
        func: async (
            reqParams: GetEndpointParams<'requestParticipants'>,
            fetcher,
        ) => {
            const {
                conferenceAlias = currentConferenceAlias,
                host = currentHost,
            } = reqParams[0];

            try {
                return await API.participants({
                    fetcher,
                    params: {
                        conferenceAlias,
                    },
                    host,
                });
            } catch (error) {
                logger.error({error}, `Can't request participants.`);
            }
        },
        funcName: 'requestParticipants',
        requiresToken: true,
    });

    const startConference = generateEndpoint({
        func: async (
            reqParams: GetEndpointParams<'startConference'>,
            fetcher,
        ) => {
            const {
                conferenceAlias = currentConferenceAlias,
                host = currentHost,
            } = reqParams[0];

            return API.startConference({
                fetcher,
                params: {
                    conferenceAlias,
                },
                host,
            });
        },
        funcName: 'startConference',
        requiresToken: true,
    });

    const fecc = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'fecc'>, fetcher) => {
            const {target, action, movement, timeout} = reqParams[0];

            if (target === currentParticipantUuid) {
                signals.onFecc.emit({action, movement, timeout});
                return Promise.resolve({
                    status: 200,
                    data: {status: 'success', result: true},
                } as GetEndpointResponse<'fecc'>);
            }

            if (currentCall && conferenceFeatureFlags?.isDirectMedia) {
                currentCall.sendDataChannelEvent({
                    type: 'fecc',
                    body: {
                        action,
                        movement,
                        timeout,
                    },
                });
                return Promise.resolve({
                    status: 200,
                    data: {status: 'success', result: true},
                } as GetEndpointResponse<'fecc'>);
            }

            if (!target) {
                if (!currentCallUuid) {
                    throw new Error('Call uuid is not present');
                }
                return API.fecc({
                    fetcher,
                    params: {
                        conferenceAlias: currentConferenceAlias,
                        participantUuid: currentParticipantUuid,
                        callUuid: currentCallUuid,
                    },
                    body: {
                        action,
                        movement,
                        timeout,
                    },
                    host: currentHost,
                });
            }

            if (currentBreakoutUuid) {
                return API.breakoutFeccParticipant({
                    fetcher,
                    params: {
                        conferenceAlias: currentConferenceAlias,
                        breakoutUuid: currentBreakoutUuid,
                        participantUuid: target,
                    },
                    body: {
                        action,
                        target,
                        movement,
                        timeout,
                    },
                    host: currentHost,
                });
            }

            return API.feccParticipant({
                fetcher,
                params: {
                    conferenceAlias: currentConferenceAlias,
                    participantUuid: target,
                },
                body: {
                    action,
                    target,
                    movement,
                    timeout,
                },
                host: currentHost,
            });
        },
        funcName: 'fecc',
        requiresToken: true,
    });

    const breakoutAskForHelp = generateEndpoint({
        func: async (
            reqParams: GetEndpointParams<'breakoutAskForHelp'>,
            fetcher,
        ) => {
            const {
                conferenceAlias = currentConferenceAlias,
                breakoutUuid = currentBreakoutUuid,
                host = currentHost,
            } = reqParams[0];

            if (breakoutUuid && breakoutUuid !== 'main') {
                return API.hostBreakoutBuzz({
                    fetcher,
                    params: {
                        conferenceAlias,
                        breakoutUuid,
                    },
                    host,
                });
            }

            return API.guestBreakoutBuzz({
                fetcher,
                params: {
                    conferenceAlias,
                },
                host,
            });
        },
        funcName: 'breakoutAskForHelp',
        requiresToken: true,
    });

    const breakoutRemoveAskForHelp = generateEndpoint({
        func: async (
            reqParams: GetEndpointParams<'breakoutRemoveAskForHelp'>,
            fetcher,
        ) => {
            const {
                conferenceAlias = currentConferenceAlias,
                breakoutUuid = currentBreakoutUuid,
                host = currentHost,
            } = reqParams[0];

            if (breakoutUuid && breakoutUuid !== 'main') {
                return API.hostClearBreakoutBuzz({
                    fetcher,
                    params: {
                        conferenceAlias,
                        breakoutUuid,
                    },
                    host,
                });
            }

            return API.guestClearBreakoutBuzz({
                fetcher,
                params: {
                    conferenceAlias,
                },
                host,
            });
        },
        funcName: 'breakoutRemoveAskForHelp',
        requiresToken: true,
    });

    const availableLayouts = generateEndpoint({
        func: async (
            reqParams: GetEndpointParams<'availableLayouts'>,
            fetcher,
        ) => {
            const {
                conferenceAlias = currentConferenceAlias,
                host = currentHost,
            } = reqParams[0];

            const res = await API.availableLayouts({
                fetcher,
                params: {
                    conferenceAlias,
                },
                host,
            });

            return res.data.result;
        },
        funcName: 'availableLayouts',
        requiresToken: true,
    });

    const layoutSvgs = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'layoutSvgs'>, fetcher) => {
            const {
                conferenceAlias = currentConferenceAlias,
                host = currentHost,
            } = reqParams[0];

            const res = await API.layoutSvgs({
                fetcher,
                params: {
                    conferenceAlias,
                },
                host,
            });

            return res.data.result;
        },
        funcName: 'layoutSvgs',
        requiresToken: true,
    });

    const presInMix = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'presInMix'>, fetcher) => {
            const {
                state,
                conferenceAlias = currentConferenceAlias,
                participantUuid = currentParticipantUuid,
                host = currentHost,
            } = reqParams[0];

            const res = await API.presInMixParticipant({
                fetcher,
                params: {
                    conferenceAlias,
                    participantUuid,
                },
                body: {
                    state,
                },
                host,
            });

            return res;
        },
        funcName: 'presInMix',
        requiresToken: true,
    });

    const notifyNotAFK = generateEndpoint({
        func: async (reqParams: GetEndpointParams<'notifyNotAFK'>, fetcher) => {
            const {
                conferenceAlias = currentConferenceAlias,
                participantUuid = currentParticipantUuid,
                host = currentHost,
            } = reqParams[0];

            const res = await API.notifyNotAFK({
                fetcher,
                params: {
                    conferenceAlias,
                    participantUuid,
                },
                host,
            });

            return res;
        },
        funcName: 'notifyNotAFK',
        requiresToken: true,
    });

    const getBackgroundUrl = (path: string) =>
        `${currentHost}/api/client/v2/conferences/${currentConferenceAlias}/theme/${path}?token=${requestClient?.token}`;

    const getMe = (roomId?: string) => {
        if (roomId === 'main') {
            return participants.get(roomId, currentParticipantUuid);
        }
        const breakoutParticipantUuid = getBreakoutParticipantUuid(
            roomId ?? currentBreakoutUuid,
        );
        return participants.get(
            currentBreakoutUuid || 'main',
            breakoutParticipantUuid
                ? breakoutParticipantUuid
                : currentParticipantUuid,
        );
    };

    return {
        get roomId() {
            return currentBreakoutUuid ?? 'main';
        },
        get breakoutRooms() {
            return breakoutRooms;
        },
        get conferenceStatus() {
            return conferenceStatus;
        },
        get conferenceFeatureFlags() {
            return conferenceFeatureFlags;
        },
        get secureCheckCode() {
            return secureCheckCode;
        },
        get latestStats() {
            return latestStats;
        },
        get serviceType() {
            return currentServiceType;
        },
        getParticipants(roomID: string) {
            return participants.getAll(roomID);
        },
        setFeccSupported(canFecc: boolean) {
            feccSupported = canFecc;
        },
        getMe,
        admit,
        call,
        dial,
        disconnect,
        disconnectAll,
        kick,
        liveCaptions,
        lock,
        mute,
        clientMute,
        guestsCanUnmute,
        muteAllGuests,
        muteVideo,
        present,
        raiseHand,
        lowerAllRaisedHands,
        restartCall,
        sendMessage,
        sendApplicationMessage,
        setBandwidth,
        setConferenceExtension,
        setCallTag,
        setLayout,
        setPin,
        setSsoToken,
        setRole,
        setStream,
        spotlight,
        stopPresenting,
        transfer,
        setTextOverlay,
        sendDTMF,
        requestAspectRatio,
        sendConferenceRequest,
        breakout,
        breakoutMoveParticipants,
        joinBreakoutRoom,
        guestLeaveBreakoutRoom,
        closeBreakouts,
        closeBreakoutRoom,
        emptyBreakouts,
        requestParticipants,
        startConference,
        fecc,
        availableLayouts,
        layoutSvgs,
        presInMix,
        breakoutAskForHelp,
        breakoutRemoveAskForHelp,
        notifyNotAFK,
    };
};
