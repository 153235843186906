import React, {useEffect} from 'react';

import {infinity} from '../services/InfinityClient.service';
import {config} from '../config';
import {mediaSignals} from '../signals/Media.signals';

import {InfinityContext} from './infinity.context';

export const InfinityContextProvider: React.FC<React.PropsWithChildren> = ({
    children,
}) => {
    useEffect(() => {
        let subscriptions = [
            config.subscribe('bandwidth', bandwidth => {
                infinity.setBandwidth(Number(bandwidth));
            }),
            mediaSignals.onMediaChanged.add(media => {
                if (media?.stream) {
                    infinity.setStream(media.stream);
                }
            }),
        ];
        return () => {
            subscriptions.forEach(unsubscribe => unsubscribe());
            subscriptions = [];
        };
    }, []);

    return (
        <InfinityContext.Provider value={infinity}>
            {children}
        </InfinityContext.Provider>
    );
};
