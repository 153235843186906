import type {Signal} from '@pexip/signal';
import type {
    ConferenceStatusMap,
    InfinityErrorCode,
    InfinityErrorMessage,
    ParticipantsMap,
    TransformLayoutMap,
} from '@pexip/infinity-api';

import type {createPopupManager} from './utils';
import type {InfinityClient} from './types.internal';

export interface PluginMeta {
    id: string;
    version: number;
}

export interface PluginElement {
    /**
     * @throws  UIError
     */
    remove: () => Promise<void>;
}

export interface Button<
    K extends keyof ButtonRPCPayload = keyof ButtonRPCPayload,
> extends PluginElement {
    /**
     * When the toolbar button has a group, it does not have a click event. It's click event is instead used to show the group
     * @param buttonId - Identifies which group button was clicked. Obsolete when the toolbar button does not have a group.
     */
    onClick: Signal<
        ButtonRPCPayload[K]['onClick']['input'] extends undefined
            ? // eslint-disable-next-line @typescript-eslint/no-invalid-void-type -- this is for a function argument
              void
            : ButtonRPCPayload[K]['onClick']['input']
    >;
    /**
     * @throws  UIError
     */
    update: (payload: ButtonRPCPayload[K]['add']) => Promise<void>;
}

export interface Participant extends InfinityParticipant {
    disconnect: () => Promise<RPCCalls['participant:disconnect']['reply']>;
    onDisconnect: Signal<void>;
}

export interface Form<
    P extends RPCCallPayload<'ui:form:open'> = RPCCallPayload<'ui:form:open'>,
> extends PluginElement {
    onInput: Signal<MapFormReturn<P['form']['elements']>>;
}

export interface Prompt extends PluginElement {
    onInput: Signal<Event<'ui:prompt:input'>['payload']['input']>;
}

export interface Widget extends PluginElement {
    id: string;
    onLoad: Signal<undefined>;
    onToggle: Signal<Event<'ui:widget:onToggle'>['payload']>;
    toggle: () => Promise<void>;
}

export type MapFormReturn<
    P extends RPCCallPayload<'ui:form:open'>['form']['elements'] = Record<
        string,
        FormElement
    >,
> = {
    [K in keyof P]: 'number' extends P[K]['type']
        ? number
        : 'checklist' extends P[K]['type']
          ? Record<string, boolean>
          : string;
};

export type UIError = {reason: string};

export interface Plugin {
    app: {
        setDisconnectDestination: (
            payload: RPCCallPayload<'app:setDisconnectDestination'>,
        ) => Promise<RPCCallReply<'app:setDisconnectDestination'>>;
    };
    ui: {
        /**
         * @throws  UIError
         */
        addButton: <K extends GetButtonRPCPayload<'add'>>(
            payload: K,
        ) => Promise<Button<K['position']>>;
        /**
         * @throws  UIError
         */
        addForm: <P extends RPCCallPayload<'ui:form:open'>>(
            payload: P,
        ) => Promise<Form<P>>;
        /**
         * @throws  UIError
         */
        showForm: <K extends RPCCallPayload<'ui:form:open'>>(
            payload: K,
        ) => Promise<MapFormReturn<K['form']['elements']>>;
        /**
         * @throws  UIError
         */
        addPrompt: (
            payload: RPCCallPayload<'ui:prompt:open'>,
        ) => Promise<Prompt>;
        /**
         * @throws  UIError
         */
        showPrompt: (
            payload: RPCCallPayload<'ui:prompt:open'>,
        ) => Promise<EventPayload<'ui:prompt:input'>['input']>;
        /**
         * @throws  UIError
         */
        showToast: (payload: RPCCallPayload<'ui:toast:show'>) => Promise<void>;
        /**
         * @throws  UIError
         */
        addWidget: (
            payload: RPCCallPayload<'ui:widget:add'>,
        ) => Promise<Widget>;
    };
    conference: {
        /**
         * @throws Error
         */
        dialOut: (
            payload: RPCCallPayload<'conference:dialOut'>,
        ) => Promise<Participant>;
        sendMessage: (
            payload: RPCCallPayload<'conference:sendMessage'>,
        ) => Promise<RPCCallReply<'conference:sendApplicationMessage'>>;
        sendApplicationMessage: (
            payload: RPCCallPayload<'conference:sendApplicationMessage'>,
        ) => Promise<RPCCallReply<'conference:sendApplicationMessage'>>;
        lock: (
            payload: RPCCallPayload<'conference:lock'>,
        ) => Promise<RPCCallReply<'conference:lock'>>;
        muteAllGuests: (
            payload: RPCCallPayload<'conference:muteAllGuests'>,
        ) => Promise<RPCCallReply<'conference:muteAllGuests'>>;
        setBandwidth: (
            payload: RPCCallPayload<'conference:setBandwidth'>,
        ) => Promise<RPCCallReply<'conference:setBandwidth'>>;
        setLayout: (
            payload: RPCCallPayload<'conference:setLayout'>,
        ) => Promise<RPCCallReply<'conference:setLayout'>>;
        disconnectAll: (
            payload: RPCCallPayload<'conference:disconnectAll'>,
        ) => Promise<RPCCallReply<'conference:disconnectAll'>>;
        requestParticipants: (
            payload: RPCCallPayload<'conference:requestParticipants'>,
        ) => Promise<RPCCallReply<'conference:requestParticipants'>>;
        transfer: (
            payload: RPCCallPayload<'participant:transfer'>,
        ) => Promise<RPCCallReply<'participant:transfer'>>;
        mute: (
            payload: RPCCallPayload<'participant:mute'>,
        ) => Promise<RPCCallReply<'participant:mute'>>;
        muteVideo: (
            payload: RPCCallPayload<'participant:muteVideo'>,
        ) => Promise<RPCCallReply<'participant:muteVideo'>>;
        spotlight: (
            payload: RPCCallPayload<'participant:spotlight'>,
        ) => Promise<RPCCallReply<'participant:spotlight'>>;
        admit: (
            payload: RPCCallPayload<'participant:admit'>,
        ) => Promise<RPCCallReply<'participant:admit'>>;
        raiseHand: (
            payload: RPCCallPayload<'participant:raiseHand'>,
        ) => Promise<RPCCallReply<'participant:raiseHand'>>;
        setRole: (
            payload: RPCCallPayload<'participant:setRole'>,
        ) => Promise<RPCCallReply<'participant:setRole'>>;
        setTextOverlay: (
            payload: RPCCallPayload<'participant:setTextOverlay'>,
        ) => Promise<RPCCallReply<'participant:setTextOverlay'>>;
        sendDTMF: (
            payload: RPCCallPayload<'participant:sendDTMF'>,
        ) => Promise<RPCCallReply<'participant:sendDTMF'>>;
        disconnect: (
            payload: RPCCallPayload<'participant:disconnect'>,
        ) => Promise<RPCCallReply<'participant:disconnect'>>;
        breakout: (
            payload: RPCCallPayload<'conference:breakout'>,
        ) => Promise<RPCCallReply<'conference:breakout'>>;
        joinBreakoutRoom: (
            payload: RPCCallPayload<'conference:joinBreakoutRoom'>,
        ) => Promise<RPCCallReply<'conference:joinBreakoutRoom'>>;
        closeBreakouts: () => Promise<
            RPCCallReply<'conference:closeBreakouts'>
        >;
        closeBreakoutRoom: (
            payload: RPCCallPayload<'conference:closeBreakoutRoom'>,
        ) => Promise<RPCCallReply<'conference:closeBreakoutRoom'>>;
        emptyBreakouts: () => Promise<
            RPCCallReply<'conference:emptyBreakouts'>
        >;
        breakoutMoveParticipants: (
            payload: RPCCallPayload<'conference:breakoutMoveParticipants'>,
        ) => Promise<RPCCallReply<'conference:breakoutMoveParticipants'>>;
        getCurrentRoomId: () => Promise<
            RPCCallReply<'conference:currentRoomId'>
        >;
    };
    events: {
        authenticatedWithConference: Signal<
            Event<'event:conference:authenticated'>['payload']
        >;
        breakoutBegin: Signal<Event<'event:breakoutBegin'>['payload']>;
        breakoutEnd: Signal<Event<'event:breakoutEnd'>['payload']>;
        breakoutRefer: Signal<Event<'event:breakoutRefer'>['payload']>;
        conferenceStatus: Signal<Event<'event:conferenceStatus'>['payload']>;
        connected: Signal<Event<'event:connected'>['payload']>;
        disconnected: Signal<Event<'event:disconnected'>['payload']>;
        userInitiatedDisconnect: Signal<
            Event<'event:userInitiatedDisconnect'>['payload']
        >;
        me: Signal<Event<'event:me'>['payload']>;
        message: Signal<Event<'event:message'>['payload']>;
        directMessage: Signal<Event<'event:directMessage'>['payload']>;
        applicationMessage: Signal<
            Event<'event:applicationMessage'>['payload']
        >;
        transfer: Signal<Event<'event:transfer'>['payload']>;
        cancelTransfer: Signal<Event<'event:cancelTransfer'>['payload']>;
        stage: Signal<Event<'event:stage'>['payload']>;
        participants: Signal<Event<'event:participants'>['payload']>;
        participantJoined: Signal<Event<'event:participantJoined'>['payload']>;
        participantLeft: Signal<Event<'event:participantLeft'>['payload']>;
        raiseHand: Signal<Event<'event:raiseHand'>['payload']>;
        presentationConnectionStateChange: Signal<
            Event<'event:presentationConnectionStateChange'>['payload']
        >;
        layoutUpdate: Signal<Event<'event:layoutUpdate'>['payload']>;
        languageSelect: Signal<Event<'event:languageSelect'>['payload']>;
    };
}

export interface PluginWidget extends Omit<Plugin, 'ui'> {
    ui: Omit<Plugin['ui'], 'addWidget'>;
}

export interface EventMessage {
    'participant:disconnected': {
        payload: {
            participantUuid: string;
        };
    };
    'ui:button:click': {
        payload: GetButtonRPCPayload<'onClick'>;
    };
    'ui:form:input': {
        payload: {
            modalId: string;
            input: FormInput;
        };
    };
    'ui:prompt:input': {
        payload: {
            modalId: string;
            input: string;
        };
    };
    'ui:widget:onLoad': {
        payload: {
            widgetId: string;
        };
    };
    'ui:widget:onToggle': {
        payload: {
            widgetId: string;
            isVisible: boolean;
            isRequest: boolean;
            trigger: 'anotherSidePanel' | 'headerCloseButton' | 'self';
        };
    };
    'event:conference:authenticated': {
        payload: {conferenceAlias: string; conferenceName?: string};
    };
    'event:conferenceStatus': {
        payload: {id: RoomID; status: ConferenceStatus};
    };
    'event:connected': {
        payload: undefined;
    };
    'event:disconnected': {
        payload: {
            error: string;
            errorCode: ExtendedInfinityErrorCode;
        };
    };
    'event:userInitiatedDisconnect': {
        payload: undefined;
    };
    'event:me': {
        payload: {id: RoomID; participant: InfinityParticipant};
    };
    'event:message': {
        payload: Message;
    };
    'event:directMessage': {
        payload: Message;
    };
    'event:applicationMessage': {
        payload: ApplicationMessage;
    };
    'event:transfer': {
        payload: TransferDetails;
    };
    'event:cancelTransfer': {
        payload: Record<string, string>;
    };
    'event:stage': {
        payload: Stage[];
    };
    'event:participants': {
        payload: {id: RoomID; participants: InfinityParticipant[]};
    };
    'event:participantJoined': {
        payload: {id: RoomID; participant: InfinityParticipant};
    };
    'event:participantLeft': {
        payload: {id: RoomID; participant: InfinityParticipant};
    };
    'event:raiseHand': {
        payload: {id: RoomID; participant: InfinityParticipant};
    };
    'event:presentationConnectionStateChange': {
        payload: {
            send: RTCPeerConnectionState;
            recv: RTCPeerConnectionState;
        };
    };
    'event:layoutUpdate': {
        payload: LayoutEvent;
    };
    'event:breakoutBegin': {
        payload: BreakoutRoom;
    };
    'event:breakoutEnd': {
        payload: BreakoutRoom;
    };
    'event:breakoutRefer': {
        payload: BreakoutReferDetails;
    };
    'event:languageSelect': {
        payload: string;
    };
}
export type Event<T extends keyof EventMessage = keyof EventMessage> =
    T extends keyof EventMessage
        ? {event: T; payload: EventMessage[T]['payload']; chanId: string}
        : never;

export type EventPayload<T extends keyof EventMessage> = Event<T>['payload'];

interface FormElementBase {
    name: string;
    autoComplete?: string;
    isOptional?: boolean;
}
export type FormElement =
    | InputElement
    | SelectElement
    | ChecklistElement
    | TextArea
    | RadioGroupElement
    | RangeSliderElement;

export interface InputElement extends FormElementBase {
    type: 'number' | 'text' | 'password' | 'email' | 'tel' | 'url';
    placeholder?: string;
    value?: string;
}

export interface TextArea extends FormElementBase {
    type: 'textarea';
    placeholder?: string;
    value?: string;
}

export interface SelectElement extends FormElementBase {
    type: 'select';
    options:
        | Array<{id: string; label: string}>
        | Readonly<Array<{id: string; label: string}>>;
    selected?: string;
}

export type ChecklistOption = {
    id: string;
    label: string;
    checked?: boolean;
    required?: boolean;
};
export interface ChecklistElement extends FormElementBase {
    type: 'checklist';
    options: ChecklistOption[] | Readonly<ChecklistOption[]>;
}

export type RadioOption = {
    id: string;
    label: string;
    value: string;
    isDisabled?: boolean;
};

export interface RadioGroupElement extends FormElementBase {
    type: 'radio';
    options: RadioOption[] | Readonly<RadioOption[]>;
    groupName: string;
    checked?: string;
}

export interface RangeSliderElement
    extends Omit<FormElementBase, 'isOptional'> {
    type: 'range';
    max?: number;
    min?: number;
    step?: number;
    selectedValue?: number;
}

export type FormInputValue = string | number | Record<string, unknown>;
export type FormInput = Record<string, FormInputValue>;
export type FormInputId = string;

export interface RPCCallMeta {
    chanId: string;
    id: string;
}

export type ParamsWithOmittedConferenceMeta<T extends keyof InfinityClient> =
    InfinityClient[T] extends (...args: never) => unknown
        ? Omit<Parameters<InfinityClient[T]>[0], 'conferenceAlias' | 'host'>
        : never;

export type InfinityEndpointReply<T extends keyof InfinityClient> =
    InfinityClient[T] extends (...args: never) => unknown
        ? Awaited<ReturnType<InfinityClient[T]>>
        : never;

export type ButtonRPCReply<
    T extends Record<string, unknown> | undefined = undefined,
> =
    | {status: 'ok'; id: string; data: T}
    | {status: 'failed'; reason: 'Invalid Icon name'; id: string};

export type GenericRPCReply<
    T extends Record<string, unknown> | undefined = undefined,
> =
    | {status: 'ok'; id: string; data: T}
    | {status: 'failed'; reason: string; id: string};

export type CustomIcon = {main: string; hover: string};

export type PopupRequest = {openParams: Parameters<Window['open']>; id: string};

export type ToolbarButtonPayload = {
    position: 'toolbar';
    icon: string | {custom: CustomIcon};
    tooltip: string;
    roles?: Array<'chair' | 'guest'>;
    opensPopup?: PopupRequest;
    isActive?: boolean;
    badge?: {
        isVisible: boolean;
    };
};

export interface GroupButtonPayload
    extends Omit<ToolbarButtonPayload, 'position'> {
    id: string;
}

export interface ButtonRPCPayload {
    toolbar: {
        add: ToolbarButtonPayload & {
            group?: GroupButtonPayload[];
        };
        onClick: {
            buttonId: string;
            input: {buttonId: string};
        };
    };
    participantActions: {
        add: {
            position: 'participantActions';
            participantIDs?: string[];
            label: string;
            opensPopup?: PopupRequest;
        };
        onClick: {
            buttonId: string;
            input: {
                participantUuid: string;
            };
        };
    };
    settingsMenu: {
        add: {
            position: 'settingsMenu';
            label: string;
            inMeetingOnly: boolean;
            roles?: Array<'chair' | 'guest'>;
            opensPopup?: PopupRequest;
        };
        onClick: {
            buttonId: string;
            input: undefined;
        };
    };
}

export type GetButtonRPCPayload<
    P extends keyof ButtonRPCPayload[K],
    K extends keyof ButtonRPCPayload = keyof ButtonRPCPayload,
> = ButtonRPCPayload[K][P];

export type FormPayload = {
    title: string;
    description?: string;
    form: {
        elements: Record<string, FormElement>;
        submitBtnTitle: string;
    };
    opensPopup?: PopupRequest;
};

export interface AppRPCCalls {
    'app:setDisconnectDestination': {
        payload: {url: string};
        reply: GenericRPCReply;
    };
}
export interface UIRPCCalls {
    'ui:button:add': {
        payload: GetButtonRPCPayload<'add'>;
        reply: ButtonRPCReply;
    };
    'ui:button:update': {
        payload: GetButtonRPCPayload<'add'> & {targetId: string};
        reply: ButtonRPCReply;
    };
    'ui:form:open': {
        payload: FormPayload;
        reply: GenericRPCReply;
    };
    'ui:prompt:open': {
        payload: {
            title: string;
            description?: string;
            prompt: {
                primaryAction: string;
                secondaryAction?: string;
            };
            opensPopup?: PopupRequest;
        };
        reply: GenericRPCReply;
    };
    'ui:toast:show': {
        payload: NotificationToastMessage;
        reply: GenericRPCReply;
    };
    'ui:removeElement': {
        payload: {
            id: string;
        };
        reply: GenericRPCReply;
    };
    'ui:widget:add': {
        payload: WidgetPayload;
        reply: GenericRPCReply;
    };
    'ui:widget:toggle': {
        payload: {
            id: string;
        };
        reply: GenericRPCReply;
    };
}

export interface RPCCalls extends UIRPCCalls, AppRPCCalls {
    // NOTE: Used to actually register the plugin, so will be sent before the
    // channel is set up on both sides
    syn: {
        payload: PluginMeta;
        reply: {ack: true} | {ack: false; reason: string};
    };
    'syn:widget': {
        payload: {parentPluginId: string};
        reply: {ack: true} | {ack: false; reason: string};
    };
    'conference:dialOut': {
        payload: ParamsWithOmittedConferenceMeta<'dial'>;
        reply: InfinityEndpointReply<'dial'>;
    };
    'conference:sendMessage': {
        payload: ParamsWithOmittedConferenceMeta<'sendMessage'>;
        reply: InfinityEndpointReply<'sendMessage'>;
    };
    'conference:sendApplicationMessage': {
        payload: ParamsWithOmittedConferenceMeta<'sendApplicationMessage'>;
        reply: InfinityEndpointReply<'sendApplicationMessage'>;
    };
    'conference:lock': {
        payload: ParamsWithOmittedConferenceMeta<'lock'>;
        reply: InfinityEndpointReply<'lock'>;
    };
    'conference:muteAllGuests': {
        payload: ParamsWithOmittedConferenceMeta<'muteAllGuests'>;
        reply: InfinityEndpointReply<'muteAllGuests'>;
    };
    'conference:setBandwidth': {
        payload: number;
        reply: InfinityEndpointReply<'setBandwidth'>;
    };
    'conference:setLayout': {
        payload: ParamsWithOmittedConferenceMeta<'setLayout'>;
        reply: InfinityEndpointReply<'setLayout'>;
    };
    'conference:sendRequest': {
        payload: ParamsWithOmittedConferenceMeta<'sendConferenceRequest'>;
        reply: InfinityEndpointReply<'sendConferenceRequest'>;
    };
    'conference:disconnectAll': {
        payload: ParamsWithOmittedConferenceMeta<'disconnectAll'>;
        reply: InfinityEndpointReply<'disconnectAll'>;
    };
    'conference:requestParticipants': {
        payload: ParamsWithOmittedConferenceMeta<'requestParticipants'>;
        reply: InfinityEndpointReply<'requestParticipants'>;
    };
    'conference:breakout': {
        payload: ParamsWithOmittedConferenceMeta<'breakout'>;
        reply: InfinityEndpointReply<'breakout'>;
    };
    'conference:joinBreakoutRoom': {
        payload: ParamsWithOmittedConferenceMeta<'joinBreakoutRoom'>;
        reply: InfinityEndpointReply<'joinBreakoutRoom'>;
    };
    'conference:closeBreakouts': {
        payload: ParamsWithOmittedConferenceMeta<'closeBreakouts'>;
        reply: InfinityEndpointReply<'closeBreakouts'>;
    };
    'conference:closeBreakoutRoom': {
        payload: ParamsWithOmittedConferenceMeta<'closeBreakoutRoom'>;
        reply: InfinityEndpointReply<'closeBreakoutRoom'>;
    };
    'conference:emptyBreakouts': {
        payload: ParamsWithOmittedConferenceMeta<'emptyBreakouts'>;
        reply: InfinityEndpointReply<'emptyBreakouts'>;
    };
    'conference:breakoutMoveParticipants': {
        payload: ParamsWithOmittedConferenceMeta<'breakoutMoveParticipants'>;
        reply: InfinityEndpointReply<'breakoutMoveParticipants'>;
    };
    'conference:currentRoomId': {
        payload: undefined;
        reply: string;
    };
    'participant:transfer': {
        payload: ParamsWithOmittedConferenceMeta<'transfer'>;
        reply: InfinityEndpointReply<'transfer'>;
    };
    'participant:mute': {
        payload: ParamsWithOmittedConferenceMeta<'mute'>;
        reply: InfinityEndpointReply<'mute'>;
    };
    'participant:muteVideo': {
        payload: ParamsWithOmittedConferenceMeta<'muteVideo'>;
        reply: InfinityEndpointReply<'muteVideo'>;
    };
    'participant:spotlight': {
        payload: ParamsWithOmittedConferenceMeta<'spotlight'>;
        reply: InfinityEndpointReply<'spotlight'>;
    };
    'participant:admit': {
        payload: ParamsWithOmittedConferenceMeta<'admit'>;
        reply: InfinityEndpointReply<'admit'>;
    };
    'participant:raiseHand': {
        payload: ParamsWithOmittedConferenceMeta<'raiseHand'>;
        reply: InfinityEndpointReply<'raiseHand'>;
    };
    'participant:setRole': {
        payload: ParamsWithOmittedConferenceMeta<'setRole'>;
        reply: InfinityEndpointReply<'setRole'>;
    };
    'participant:setTextOverlay': {
        payload: ParamsWithOmittedConferenceMeta<'setTextOverlay'>;
        reply: InfinityEndpointReply<'setTextOverlay'>;
    };
    'participant:disconnect': {
        payload: ParamsWithOmittedConferenceMeta<'kick'>;
        reply: InfinityEndpointReply<'kick'>;
    };
    'participant:sendDTMF': {
        payload: ParamsWithOmittedConferenceMeta<'sendDTMF'>;
        reply: InfinityEndpointReply<'sendDTMF'>;
    };
}

export type RPCCall<T extends keyof RPCCalls = keyof RPCCalls> =
    T extends keyof RPCCalls
        ? {
              chanId: string;
              rpc: T;
              id: string;
              payload: RPCCalls[T]['payload'];
          }
        : never;

export type RPCCallPayload<T extends keyof RPCCalls> = RPCCall<T>['payload'];

export type RPCCallReply<T extends keyof RPCCalls> = RPCReply<T>['payload'];

export type RPCReply<T extends keyof RPCCalls = keyof RPCCalls> =
    T extends keyof RPCCalls
        ? {
              rpc: T;
              chanId: string;
              replyTo: string;
              payload: RPCCalls[T]['reply'];
          }
        : never;

export type PluginMessage = Event | RPCReply;

export type NotificationToastMessage = {
    message: string;
    isDanger?: boolean;
    /**
     * A toast that can interrupt will jump the queue and remove the
     * currently rendering toast and be rendered instead of it. Currently
     * rendering toasts that have a timeout of 0 cannot be interrupted, the
     * toast that tries to interrupt it is discarded and not queued.
     *
     * An example when this can be useful: if many
     * toasts are sent rapidly in response to UI state changes;
     * User mutes, unmute & mutes again and toasts are displayed to reflect these states,
     * the toasts will display without a long timeout between each.
     */
    isInterrupt?: boolean;
    position?: 'topCenter' | 'bottomCenter';
    timeout?: number;
    canDismiss?: boolean;
};

export type RoomID = string;

export type RTCParticipantEvent = ParticipantsMap['200']['result'][0];
export type ConferenceStatusEvent = ConferenceStatusMap['200']['result'];
export type DisconnectReason = 'Browser closed' | 'User initiated disconnect';
export enum CallType {
    audio = 'audio',
    video = 'video',
    api = 'api',
}
export interface Message {
    at: Date;
    id: string;
    message: string;
    displayName: string;
    userId: string;
}

export interface ApplicationMessage extends Omit<Message, 'message'> {
    message: Record<string, unknown>;
}

export interface TransferDetails {
    alias: string;
}

export interface Stage {
    userId: string;
    stageIndex: number;
    vad: number;
}

export interface ConferenceStatus {
    locked: boolean;
    guestsMuted: boolean;
    allMuted: boolean;
    started: boolean;
    liveCaptionsAvailable: boolean;
    breakoutRooms: boolean;
    directMedia: boolean;
    presentationAllowed: boolean;
    classification?: {
        current: number;
        levels: Record<number, string>;
    };
    breakout: boolean;
    breakoutName?: string;
    breakoutDescription?: string;
    endTime?: number;
    endAction?: 'transfer' | 'disconnect';
    rawData: ConferenceStatusEvent;
}

export type Layout = TransformLayoutMap['Body']['transforms']['layout'];

export interface LayoutEvent {
    requested_layout: {
        primary_screen: {
            chair_layout: Layout;
            guest_layout: Layout;
        };
    };
    view: Layout;
    participants: string[];
    overlay_text_enabled?: boolean;
}

export interface InfinityParticipant {
    callType: CallType;
    canControl: boolean;
    canChangeLayout: boolean;
    canDisconnect: boolean;
    canMute: boolean;
    canTransfer: boolean;
    canFecc: boolean;
    canRaiseHand: boolean;
    canSpotlight: boolean;
    displayName?: string;
    overlayText: string;
    handRaisedTime: number;
    identity: string;
    isCameraMuted: boolean;
    isConjoined: boolean;
    isEndpoint?: boolean;
    isExternal: boolean;
    isIdpAuthenticated?: boolean;
    isGateway: boolean;
    isHost: boolean;
    isMainVideoDroppedOut: boolean;
    isMuted: boolean;
    isPresenting: boolean;
    isSpotlight: boolean;
    isStreaming: boolean;
    isTransferring: boolean;
    isVideoSilent: boolean;
    isWaiting: boolean;
    needsPresentationInMix: boolean;
    protocol: RTCParticipantEvent['protocol'];
    raisedHand: boolean;
    role: RTCParticipantEvent['role'];
    rxPresentation: boolean;
    serviceType: RTCParticipantEvent['service_type'];
    spotlightOrder: number;
    startAt: Date;
    startTime: number;
    uri: string;
    uuid: string;
    vendor: RTCParticipantEvent['vendor'];
    rawData: RTCParticipantEvent;
}

export type ExtendedInfinityErrorCode =
    | InfinityErrorCode
    | '#pex128'
    | '#pex117';

export type ClientSideErrorMessage =
    | 'Could not reconnect to the meeting'
    | 'Could not execute critical network action'
    | 'Could not find ICE candidates'
    | 'WebRTC connection closed'
    | 'WebRTC connection failed';

export type ExtendedInfinityErrorMessage =
    | InfinityErrorMessage
    | ClientSideErrorMessage;

export interface BreakoutRoom {
    breakout_uuid: string;
    participant_uuid: string;
}

export interface BreakoutReferDetails {
    breakoutUuid: string;
    breakoutName: string;
    requesterUuid: string;
}

export type IframeType = 'sidePanel' | 'draggable' | 'fixed';

export type WidgetProps = {
    src: string;
    title: string;
    avoidAutoToggle?: {
        headerCloseButton?: boolean;
    };
};

export type SidePanelWidgetPayload = WidgetProps & {
    type: 'sidePanel';
};

export type FloatingWidgetPosition =
    | 'topLeft'
    | 'bottomLeft'
    | 'center'
    | 'topRight'
    | 'bottomRight';

export const breakpoints = {xs: 0, sm: 479, md: 744, lg: 1025, xl: 2000};
export type Breakpoint = keyof typeof breakpoints;

export type BreakpointDimension = Partial<
    Record<Breakpoint | 'mobilePortrait' | 'mobileLandscape', string>
>;
export type FloatingWidgetDimensions = {
    width?: string | BreakpointDimension;
    height?: string | BreakpointDimension;
};

export type FloatingWidgetPayload = WidgetProps & {
    type: 'floating';
    draggable?: boolean;
    position?: FloatingWidgetPosition;
    dimensions?: FloatingWidgetDimensions;
    isVisible?: boolean;
};

export type WidgetPayload = SidePanelWidgetPayload | FloatingWidgetPayload;
declare global {
    interface Window {
        plugin: {
            popupManager: ReturnType<typeof createPopupManager>;
        };
    }
}
