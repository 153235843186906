/**
 * A striped version of MediaDeviceInfo
 *
 * @beta
 */
export type MediaDeviceInfoLike = Omit<MediaDeviceInfo, 'toJSON'> & {
    toJSON?: MediaDeviceInfo['toJSON'];
};

export type MediaDeviceTrackInfo = MediaDeviceInfoLike & {
    id: string;
    settings: MediaTrackSettings;
};

export type IndexedDeviceMap = {
    [Key in MediaDeviceInfo['kind']]: {
        deviceId: Map<MediaDeviceInfoLike['deviceId'], MediaDeviceInfoLike>;
        label: Map<MediaDeviceInfoLike['label'], MediaDeviceInfoLike[]>;
        size: number;
    };
};

export interface IndexedDevices {
    /**
     * Get devices by kind and device id or label
     * When both `deviceId` and `label` are provided, it will use `deviceId` to
     * find the device, and when it's not found, it will use `label` to find
     *
     * When device is not provided, it returns all devices.
     *
     * @param device - the device to find
     * @returns found devices otherwise empty array
     */
    get(
        device?: Pick<
            Partial<MediaDeviceInfoLike>,
            'deviceId' | 'groupId' | 'label' | 'kind'
        >,
    ): MediaDeviceInfoLike[];
    /**
     * Size of particular kind of devices. When kind is not specified, it
     * returns the total size.
     */
    size(kind?: MediaDeviceKind): number;
    /**
     * First device of the kind
     */
    first(kind: MediaDeviceInfo['kind']): MediaDeviceInfoLike | undefined;
    toJSON(): Record<MediaDeviceKind, MediaDeviceInfoLike[]>;
    /**
     * `true` when there is any authorized device of the kind. When kind is not
     * provided, it checks for any audio or video input device.
     */
    anyAuthorizedDevice(kind?: MediaDeviceKind): boolean;
}

export type DeviceConstraint = MediaDeviceInfoLike | MediaDeviceInfoLike[];

export interface NormalizedConstraintDeviceParameters {
    exact?: MediaDeviceInfoLike[];
    ideal?: MediaDeviceInfoLike[];
}

export interface ConstraintDeviceParameters {
    exact?: DeviceConstraint;
    ideal?: DeviceConstraint;
}

export interface InputConstraintSet extends MediaTrackConstraints {
    /**
     * Same purpose as `deviceId` but it gives more information about the device
     * so that we can have extra tolerance on device selection
     */
    device?: DeviceConstraint | ConstraintDeviceParameters;
    /**
     * Whether or not using video segmentation, e.g. background
     * blur/replacement, to specify the effects, intended to be applied to the
     * segment. Available effects are `none`, `blur`, `overlay` or `remove`
     */
    videoSegmentation?: ConstrainDOMString;
    /**
     * Segmentation model to be used for video segmentation, currently only
     * supports `mediapipeSelfie` and `personify`
     */
    videoSegmentationModel?: ConstrainDOMString;
    /**
     * Whether or not using our own noise suppression
     */
    denoise?: ConstrainBoolean;
    /**
     * Voice Activity Detection
     */
    vad?: ConstrainBoolean;
    /**
     * Audio Signal Detection for the purpose of checking if the audio input is
     * hardware muted or unusable
     */
    asd?: ConstrainBoolean;
    /**
     * Audio Signal Detection for the purpose of checking if the audio input is
     * hardware muted or unusable
     */
    mixWithAdditionalMedia?: ConstrainBoolean;
    /**
     * Blur size/level parameter when using video segmentation with `blur`
     * effects
     */
    backgroundBlurAmount?: ConstrainULong;
    /**
     * Blur amount applied to the segmented person's edge
     */
    edgeBlurAmount?: ConstrainULong;
    /**
     * Erode level for edge smoothing when using video segmentation
     */
    foregroundThreshold?: ConstrainDouble;
    /**
     * Image Url that is being used for video overlay effects
     */
    backgroundImageUrl?: ConstrainDOMString;
    /**
     * The ratio to be used for smoothing segmentation mask
     */
    maskCombineRatio?: ConstrainDouble;
    /**
     * Image Url that is being used for video overlay effects
     */
    resizeMode?: ConstrainDOMString;
    /**
     * Current pan, tilt and zoom level for a PTZ controllable camera on web
     */
    pan?: boolean;
    tilt?: boolean;
    zoom?: boolean;

    /**
     * Content Hint for the track to apply
     */
    contentHint?: ConstrainDOMString;
}

export type InputDeviceConstraint =
    | DeviceConstraint
    | InputConstraintSet
    | boolean;

/**
 * The request constraints that applies to `getMediaStream`
 *
 * @beta
 */
export interface MediaDeviceRequest {
    /**
     * Either a Boolean (which indicates whether or not an audio track is
     * requested) or a {@link MediaDeviceInfoLike} object providing a hint for the
     * audio track included in the returned MediaStream
     */
    audio?: InputDeviceConstraint;
    /**
     * Either a Boolean (which indicates whether or not an video track is
     * requested) or a {@link MediaDeviceInfoLike} object providing a hint for the
     * video track included in the returned MediaStream
     */
    video?: InputDeviceConstraint;
}

export interface DeviceIdConstraints {
    deviceId: ConstrainDOMString;
}

export interface MediaConstraintRequirement extends MediaDeviceRequest {
    defaultConstraints: MediaStreamConstraints;
}

/**
 * an enum of MediaDeviceKind which can be reused
 *
 * @beta
 */
export enum MediaDeviceKinds {
    /** audio input */
    AUDIOINPUT = 'audioinput',
    /** audio output */
    AUDIOOUTPUT = 'audiooutput',
    /** video input */
    VIDEOINPUT = 'videoinput',
}

/**
 * MediaTrackConstraints.facingMode
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/MediaTrackConstraints/facingMode}
 */
export const FACING_MODE = ['user', 'environment', 'left', 'right'] as const;
export type FacingMode = (typeof FACING_MODE)[number];

/**
 * An enum of failure events for media device
 *
 * @beta
 */
export enum MediaDeviceFailure {
    /**
     * some problem (not user or permissions) occurred which prevented the device
     * from being used.
     */
    AbortError = 'AbortError',
    /**
     * Custom error for when selected audio and video device is not found
     */
    AudioAndVideoDeviceNotFoundError = 'AudioAndVideoDeviceNotFoundError',
    /**
     * Custom error for selected audio device not found
     */
    AudioInputDeviceNotFoundError = 'AudioInputDeviceNotFoundError',
    /**
     * Custom missing constraints error
     */
    MissingConstraintsError = 'MissingConstraintsError',
    /**
     * user has blocked access
     */
    NotAllowedError = 'NotAllowedError',
    /**
     * the media tracks doesn't match the constraints
     */
    NotFoundError = 'NotFoundError',
    /**
     * hardware failure after user granted access
     */
    NotReadableError = 'NotReadableError',
    /**
     * no devices met the criteria for the constraints
     */
    OverconstrainedError = 'OverconstrainedError',
    /**
     * user has blocked access old chrome
     */
    PermissionDeniedError = 'PermissionDeniedError',
    /**
     * User media support disabled by agent
     */
    SecurityError = 'SecurityError',
    /**
     * when hardware in use old chrome win os
     */
    TrackStartError = 'TrackStartError',
    /**
     * GetUserMedia invalid constraints error
     */
    TypeError = 'TypeError',
    /**
     * Custom error for selected video device not found
     */
    VideoInputDeviceNotFoundError = 'VideoInputDeviceNotFoundError',
    /**
     * action or function is not supported by the current platform
     */
    NotSupportedError = 'NotSupportedError',
    /**
     * Canvas capture is not supported
     */
    StreamTrackNotFound = 'StreamTrackNotFound',
}

/**
 * Lesser strict type of `MediaStreamTrack`
 *
 * @beta
 */
export type MediaStreamTrackLike = Pick<
    MediaStreamTrack,
    'id' | 'kind' | 'label' | 'getSettings' | 'stop'
>;

/**
 * Union types of MediaDeviceInfoLike and MediaStreamTrackLike
 */
export type DeviceOrTrack = MediaDeviceInfoLike | MediaStreamTrackLike;

/**
 * Holds user media input devices
 *
 * @beta
 */
export interface MediaInput {
    audioInput?: MediaDeviceInfoLike;
    videoInput?: MediaDeviceInfoLike;
}

export type Unsubscribe = () => void;
export type TrackEventCallback = (track: MediaStreamTrack) => void;

/**
 * An object contains the event handlers
 */
export interface StreamTrackEventHandlers {
    ended?: TrackEventCallback;
    mute?: TrackEventCallback;
    unmute?: TrackEventCallback;
}

export interface InputDevicePermission {
    audio: PermissionState;
    video: PermissionState;
}

interface DisplayVideoTrackConstraint extends MediaTrackConstraintSet {
    displaySurface?: ConstrainDOMString;
    cursor?: ConstrainDOMString;
}
interface DisplayAudioTrackConstraint extends MediaTrackConstraintSet {
    restrictOwnAudio?: ConstrainBoolean;
    suppressLocalAudioPlayback?: ConstrainBoolean;
}

interface DisplayVideoTrackConstraints extends DisplayVideoTrackConstraint {
    advanced?: DisplayVideoTrackConstraint[];
}

interface DisplayAudioTrackConstraints extends DisplayAudioTrackConstraint {
    advanced?: DisplayAudioTrackConstraint[];
}

/**
 * The `DisplayMediaStreamOptions` dictionary is used to instruct the user agent
 * what sort of `MediaStreamTracks` may be included in the `MediaStream`
 * returned by `getDisplayMedia`.
 *
 * @see {@link https://w3c.github.io/mediacapture-screen-share/#displaymediastreamoptions}
 */
export interface DisplayMediaOptions extends DisplayMediaStreamOptions {
    audio?: boolean | DisplayAudioTrackConstraints;
    video?: boolean | DisplayVideoTrackConstraints;
    selfBrowserSurface?: ConstrainDOMString;
    systemAudio?: ConstrainDOMString;
    surfaceSwitching?: ConstrainDOMString;
    monitorTypeSurfaces?: ConstrainDOMString;
}
