import {useSyncExternalStore} from 'react';

import type {Subscribe} from '@pexip/media-components';
import {
    getAudioOutput,
    useSelectDeviceChangeHandler,
    createUserMediaHooks,
    useUserMediaContext,
} from '@pexip/media-components';
import {isSendingAudio, isSendingVideo} from '@pexip/infinity';

import {mediaSignals} from '../signals/Media.signals';
import {muteAudio, muteVideo} from '../services/Media.service';
import {config, useConfig} from '../config';
import {logger} from '../logger';

export const {
    useDevices,
    useMediaStatus,
    useMediaStream,
    useAudioInput,
    useAudioMuteState,
    useVideoMuteState,
    useVideoInput,
    useToggleVideoMuted,
    useToggleAudioMuted,
    useHaveRequestedAudio,
    useHaveRequestedVideo,
    useHaveRequestedMedia,
    useSetAudioMuted,
    useSourceVideoTrack,
    useSourceAudioTrack,
    useAudioInputUnavailable,
    useVideoInputUnavailable,
    useInputDeviceStatusInfo,
} = createUserMediaHooks({signals: mediaSignals, muteAudio, muteVideo});

export const useVideoInputChangeHandler = () => {
    const userMedia = useUserMediaContext();
    const devices = useDevices();
    const selectedVideoInput = useVideoInput();

    return useSelectDeviceChangeHandler({
        currentInput: selectedVideoInput,
        devices,
        requestMedia: device => {
            if (!device) {
                return;
            }
            const constraints = {video: {device: {exact: device}}};
            logger.debug(constraints, 'Save VideoInput');
            config.set({
                key: 'videoInput',
                value: device,
                persist: true,
            });

            userMedia.getUserMedia(constraints);
        },
    });
};

export const useAudioInputChangeHandler = () => {
    const userMedia = useUserMediaContext();
    const devices = useDevices();
    const selectedAudioInput = useAudioInput();

    return useSelectDeviceChangeHandler({
        currentInput: selectedAudioInput,
        devices,
        requestMedia: device => {
            if (!device) {
                return;
            }
            const constraints = {audio: {device: {exact: device}}};
            logger.debug(constraints, 'Save AudioInput');
            config.set({
                key: 'audioInput',
                value: device,
                persist: true,
            });

            userMedia.getUserMedia(constraints);
        },
    });
};

export const useAudioOutputChangeHandler = (persist: boolean) => {
    const [savedAudioOutput, setSavedAudioOutput] = useConfig('audioOutput');
    const devices = useDevices();

    const selectedAudioOutput = getAudioOutput(devices, savedAudioOutput);
    return useSelectDeviceChangeHandler({
        currentInput: selectedAudioOutput,
        devices,
        requestMedia: device => {
            if (!device) {
                return;
            }
            setSavedAudioOutput(device, persist);
        },
    });
};

export const useCallType = (
    subscribe: Subscribe = onChange => config.subscribe('callType', onChange),
) => {
    return useSyncExternalStore(subscribe, () => config.get('callType'));
};

export const useMediaRequest = () => {
    const callType = useCallType();
    const requestAudio = isSendingAudio(callType);
    const requestVideo = isSendingVideo(callType);
    return {audio: requestAudio, video: requestVideo};
};
