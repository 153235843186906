import i18n from 'i18next';
import {v4 as uuid} from 'uuid';

import type {
    DisconnectReason,
    InfinityClient,
    Layout,
    SplashScreen,
} from '@pexip/infinity';
import {
    createInfinityClient,
    isReceivingAnyMedia,
    isGateway,
} from '@pexip/infinity';
import type {Detach, Signal} from '@pexip/signal';
import type {
    ChatMessage,
    ChatMessageID,
    DirectChatMessages,
    UnseenUnreadDirectChatMessages,
} from '@pexip/media-components';
import {MainBreakoutRoomId, toTime} from '@pexip/media-components';

import {callSignals} from '../signals/Call.signals';
import {userInitiatedDisconnectSignal} from '../signals/InMeeting.signals';
import {infinityClientSignals} from '../signals/InfinityClient.signals';
import {config} from '../config';
import {applicationConfig} from '../applicationConfig';
import {CLIENT_ID, CHARACTER_LIMIT} from '../constants';
import {logger} from '../logger';
import type {
    Transcript,
    DisconnectedParticipant,
    Idp,
    ParticipantID,
} from '../types';
import {MeetingFlow} from '../types';
import {navigateToPostMeeting} from '../router';
import {
    meetingSignal,
    stepSignal,
    remoteStreamSignal,
    pinRequiredSignal,
    endPresentationSignal,
    splashScreenSignal,
    idpSignal,
    layoutOverlayTextEnabledSignal,
    invalidPinSignal,
    infinityErrorSignal,
    chatMessagesSignal,
    unreadChatMessagesSignal,
    presentationStreamSignal,
    startPresentationSignal,
    transferSignal,
    directChatMessagesSignal,
    unreadDirectChatMessagesSignal,
    unseenUnreadDirectChatMessagesSignal,
    disconnectedParticipantsSignal,
    transcriptsSignal,
    liveCaptionsEnabledSignal,
} from '../signals/Meeting.signals';
import {mediaSignals} from '../signals/Media.signals';

import {mediaService} from './Media.service';

type CallParams = Parameters<InfinityClient['call']>[0];
type CallArgs = Omit<
    CallParams,
    | 'bandwidth'
    | 'callType'
    | 'clientId'
    | 'directMedia'
    | 'displayName'
    | 'node'
>;

type EndArgs = Parameters<InfinityClient['disconnect']>[0];
type BreakoutRoomDetail = Parameters<InfinityClient['breakout']>[0];

const subscribePageHide = (handler: (event: Event) => void) => {
    window.addEventListener('pagehide', handler);
    return () => {
        window.removeEventListener('pagehide', handler);
    };
};

/* eslint-disable @typescript-eslint/no-explicit-any -- necessary to override a primitive */
type ExtractSignalType<T extends Signal<any>> =
    T extends Signal<infer S> ? S : never;
const toFn =
    <F extends (...args: any[]) => Promise<any>>(fn: F) =>
    (...args: Parameters<F>) => {
        void fn(...args);
    };
/* eslint-enable @typescript-eslint/no-explicit-any -- necessary to override a primitive */

type CallAttributes = Pick<
    CallArgs,
    'conferenceAlias' | 'callTag' | 'conferenceExtension' | 'mediaStream'
>;
type LayoutTransforms = Parameters<
    InfinityClient['setLayout']
>[0]['transforms'];

export enum CallStage {
    New,
    EventStreamConnected,
    Connected,
    Restarting,
    Ending,
    Ended,
}
type InfinityErrorInfo = {
    type: string;
    code: string;
};

export interface MeetingAttributes {
    step: MeetingFlow;
    idps: Idp[];
    liveCaptionsEnabled: boolean;
    remoteStream?: MediaStream | undefined;
    presentationStream?: MediaStream | undefined;
    pinRequired: boolean;
    detachSignals: Detach[];
    splashScreen?: SplashScreen;
    callStage: CallStage;
    endedReason?: DisconnectReason;
    layoutOverlayTextEnabled: boolean;
    error?: InfinityErrorInfo;
    joinAsHost?: boolean;
    isFeccEnabled: boolean;
    currentHostLayout: Layout;
    chatMessages: ChatMessage[];
    unreadChatMessages: ChatMessage[];
    unseenUnreadDirectChatMessages: UnseenUnreadDirectChatMessages;
    directChatMessages: DirectChatMessages;
    unreadDirectChatMessages: DirectChatMessages;
    disconnectedParticipants: DisconnectedParticipant[];
    transcripts: Transcript[];
}
interface MeetingProps extends CallAttributes, MeetingAttributes {}
interface MeetingControls {
    call(args: Partial<CallArgs>): Promise<void>;
    endCall(args: EndArgs): Promise<void>;
    restartCall(args: CallArgs): Promise<void>;
    changeLayout(transforms: LayoutTransforms): Promise<boolean>;
}

const getCallConfigs = () => ({
    bandwidth: Number(config.get('bandwidth')),
    callType: config.get('callType'),
    callTypeQueryParameter: config.get('callTypeQueryParameter'),
    clientId: CLIENT_ID,
    directMedia: applicationConfig.directMedia,
    displayName: config.get('displayName'),
    node: applicationConfig.node,
    networkPriorities: applicationConfig.networkPriorities,
    encodingPriorities: applicationConfig.encodingPriorities,
    supportsDirectChat: true,
});

const createMeeting = ({
    infinity,
    callAttrs,
    meetingAttrs = {},
    controls,
}: {
    infinity: InfinityClient;
    callAttrs: CallAttributes;
    meetingAttrs?: Partial<MeetingAttributes>;
    controls: MeetingControls | undefined;
}) => {
    /**
     * In a gateway call we only have 2 participants so we are interested in the remote
     */
    const gatewayParticipantCanFecc = () =>
        Boolean(
            isGateway(infinity.serviceType) &&
                infinity
                    .getParticipants(infinity.roomId)
                    .find(({uuid}) => uuid !== infinity.getMe()?.uuid)?.canFecc,
        );

    const props: MeetingProps = {
        ...callAttrs,
        idps: [],
        liveCaptionsEnabled: false,
        step: MeetingFlow.Loading,
        pinRequired: false,
        callStage: CallStage.New,
        endedReason: undefined,
        layoutOverlayTextEnabled: false,
        detachSignals: [],
        isFeccEnabled: gatewayParticipantCanFecc(),
        currentHostLayout: undefined,
        chatMessages: [],
        unreadChatMessages: [],
        directChatMessages: new Map(),
        unreadDirectChatMessages: new Map(),
        unseenUnreadDirectChatMessages: [],
        disconnectedParticipants: [],
        transcripts: [],
        ...meetingAttrs,
    };

    const createSignalHandler = <T extends Signal<unknown>>(
        signal: T,
        handle: (arg: ExtractSignalType<T>) => void | Promise<void>,
        name = 'event',
    ) => {
        return async (arg: ExtractSignalType<T>) => {
            try {
                await handle(arg);
                logger.info(
                    {context: 'Meeting Signals', signal: signal.name},
                    `Handled ${signal.name ?? name}`,
                );
            } catch (error) {
                logger.error(
                    {error},
                    `Failed to handle ${signal.name ?? name}`,
                );
            }
        };
    };

    const updateIdp = (idps: Idp[]) => {
        if (props.idps === idps) {
            return;
        }
        props.idps = idps;
        idpSignal.emit();
        updateStep(MeetingFlow.Idp);
    };

    const uppdateLiveCaptionsEnabled = (enabled: boolean) => {
        if (props.liveCaptionsEnabled === enabled) {
            return;
        }
        props.liveCaptionsEnabled = enabled;
        liveCaptionsEnabledSignal.emit();
    };

    const updateLayoutOverLayTextEnabled = (enabled: boolean) => {
        if (props.layoutOverlayTextEnabled === enabled) {
            return;
        }
        props.layoutOverlayTextEnabled = enabled;
        layoutOverlayTextEnabledSignal.emit();
    };

    const updateStep = (newStep: MeetingFlow) => {
        if (props.step === newStep) {
            return;
        }
        props.step = newStep;
        logger.debug(
            {context: 'Meeting', meetingStage: MeetingFlow[props.step]},
            'Update Meeting stage',
        );
        stepSignal.emit(newStep);
    };

    const updateDisconnectedParticipants = (
        disconnectedParticipants: DisconnectedParticipant[],
    ) => {
        props.disconnectedParticipants = disconnectedParticipants;
        disconnectedParticipantsSignal.emit();
    };

    const updateSplashScreen = (splashScreen: SplashScreen | undefined) => {
        if (props.splashScreen === splashScreen) {
            return;
        }
        props.splashScreen = splashScreen;
        splashScreenSignal.emit();
    };

    const updateInfinityError = (error: string, errorCode: string) => {
        void end({});
        props.error = {type: error, code: errorCode};
        infinityErrorSignal.emit(props.error);
    };

    const updateChatMessages = (chatMessages: ChatMessage[]) => {
        if (props.chatMessages === chatMessages) {
            return;
        }
        props.chatMessages = chatMessages;
        chatMessagesSignal.emit();
    };

    const updateUnreadChatMessages = (chatMessages: ChatMessage[]) => {
        if (props.unreadChatMessages === chatMessages) {
            return;
        }
        props.unreadChatMessages = chatMessages;
        unreadChatMessagesSignal.emit();
    };

    const getDirectChatMessagesWithParticipant = (
        withParticipantID: ParticipantID,
    ) => props.directChatMessages.get(withParticipantID);

    const updateDirectChatMessages = (
        directChatMessages: DirectChatMessages,
    ) => {
        if (props.directChatMessages === directChatMessages) {
            return;
        }
        props.directChatMessages = directChatMessages;
        directChatMessagesSignal.emit();
    };

    const addDirectChatMessage = (
        withParticipantID: ParticipantID,
        chatMessage: ChatMessage,
    ) => {
        const currentMessagesWithParticipant =
            getDirectChatMessagesWithParticipant(withParticipantID) ?? [];
        const newDirectChatMessages = new Map(props.directChatMessages);
        newDirectChatMessages.delete(withParticipantID); // delete to set new map order so that we can loop over latest messages first
        newDirectChatMessages.set(withParticipantID, [
            ...currentMessagesWithParticipant,
            {...chatMessage},
        ]);
        updateDirectChatMessages(newDirectChatMessages);
    };

    const deleteDirectChatMessage = (
        withParticipantID: ParticipantID,
        chatMessageIDToDelete: string,
    ) => {
        if (!props.directChatMessages.has(withParticipantID)) {
            return;
        }
        const currentMessagesWithParticipant =
            getDirectChatMessagesWithParticipant(withParticipantID) ?? [];
        const newDirectChatMessagesWithParticipant =
            currentMessagesWithParticipant.filter(
                message => message.id !== chatMessageIDToDelete,
            );
        const newDirectChatMessages = new Map(props.directChatMessages);
        newDirectChatMessages.set(
            withParticipantID,
            newDirectChatMessagesWithParticipant,
        );
        updateDirectChatMessages(newDirectChatMessages);
    };

    const getUnreadDirectChatMessagesWithParticipant = (
        withParticipantID: ParticipantID,
    ) => props.unreadDirectChatMessages.get(withParticipantID);

    const updateUnreadDirectChatMessages = (
        directChatMessages: DirectChatMessages,
    ) => {
        if (props.unreadDirectChatMessages === directChatMessages) {
            return;
        }
        props.unreadDirectChatMessages = directChatMessages;
        unreadDirectChatMessagesSignal.emit();
    };

    const addUnseenUnreadDirectChatMessage = (messageID: ChatMessageID) => {
        props.unseenUnreadDirectChatMessages = [
            ...props.unseenUnreadDirectChatMessages,
            messageID,
        ];
        unseenUnreadDirectChatMessagesSignal.emit();
    };

    const deleteAllUnseenUnreadDirectChatMessages = () => {
        if (props.unseenUnreadDirectChatMessages.length > 0) {
            props.unseenUnreadDirectChatMessages = [];
            unseenUnreadDirectChatMessagesSignal.emit();
        }
    };

    const deleteUnseenUnreadDirectChatMessages = (
        messageIDs: ChatMessageID[],
    ) => {
        const newUnseenUnreadDirectChatMessages =
            props.unseenUnreadDirectChatMessages.filter(
                id => !messageIDs.includes(id),
            );
        if (
            newUnseenUnreadDirectChatMessages.length !==
            props.unseenUnreadDirectChatMessages.length
        ) {
            props.unseenUnreadDirectChatMessages =
                newUnseenUnreadDirectChatMessages;
            unseenUnreadDirectChatMessagesSignal.emit();
        }
    };

    const deleteAllUnseenUnreadDirectChatMessagesWithParticipant = (
        withParticipantID: ParticipantID,
    ) => {
        const unreadMessages =
            getUnreadDirectChatMessagesWithParticipant(withParticipantID) ?? [];
        deleteUnseenUnreadDirectChatMessages(unreadMessages.map(({id}) => id));
    };

    const addUnreadDirectChatMessage = (
        withParticipantID: ParticipantID,
        chatMessage: ChatMessage,
    ) => {
        const unreadMessages =
            getUnreadDirectChatMessagesWithParticipant(withParticipantID) ?? [];
        const newUnreadDirectChatMessages = new Map(
            props.unreadDirectChatMessages,
        );
        newUnreadDirectChatMessages.delete(withParticipantID); // reorder map
        newUnreadDirectChatMessages.set(withParticipantID, [
            ...unreadMessages,
            {...chatMessage},
        ]);
        updateUnreadDirectChatMessages(newUnreadDirectChatMessages);
        addUnseenUnreadDirectChatMessage(chatMessage.id);
    };

    const deleteUnreadDirectChatMessagesWithParticipant = (
        withParticipantID: ParticipantID,
    ) => {
        const newUnreadDirectChatMessages = new Map(
            props.unreadDirectChatMessages,
        );
        deleteAllUnseenUnreadDirectChatMessagesWithParticipant(
            withParticipantID,
        );
        newUnreadDirectChatMessages.delete(withParticipantID);
        updateUnreadDirectChatMessages(newUnreadDirectChatMessages);
    };

    const setRemoteStream = (remoteStream: MediaStream) => {
        if (props.remoteStream === remoteStream) {
            return;
        }
        props.remoteStream = remoteStream;
        remoteStreamSignal.emit(remoteStream);
    };

    const setPinRequired = (pinRequired: boolean) => {
        if (props.pinRequired === pinRequired) {
            return;
        }
        props.pinRequired = pinRequired;
        pinRequiredSignal.emit(pinRequired);
    };

    const release = () => {
        props.detachSignals.forEach(d => d());
        props.detachSignals = [];
    };

    const continueCall = async (args: Partial<CallArgs>) => {
        if (!controls) {
            return;
        }
        updateStep(MeetingFlow.Loading);
        await controls.call(args);
    };

    const end = async (
        args: EndArgs = {reason: 'User initiated disconnect'},
    ) => {
        if (props.callStage >= CallStage.Ending) {
            return;
        }
        props.endedReason = args.reason;
        try {
            props.callStage = CallStage.Ending;
            if (controls) {
                await controls.endCall(args);
                if (args.reason === 'User initiated disconnect') {
                    userInitiatedDisconnectSignal.emit();
                }
            }
        } finally {
            release();
            props.callStage = CallStage.Ended;
        }
    };

    const leave = async () => {
        try {
            updateStep(MeetingFlow.Loading);
            await end();
        } finally {
            navigateToPostMeeting(
                props.conferenceAlias,
                applicationConfig.disconnectDestination,
            );
        }
    };

    const present = (stream: MediaStream) => {
        infinity.present(stream);
    };
    const endPresent = () => {
        if (!controls) {
            return;
        }
        infinity.stopPresenting();
        endPresentationSignal.emit();
    };
    const raiseHand = async (raise: boolean, participantUuid?: string) => {
        await infinity.raiseHand({raise, participantUuid});
    };
    const lowerAllRaisedHands = async () => {
        await infinity.lowerAllRaisedHands({});
    };
    const _changeLayout = async (transforms: LayoutTransforms) => {
        try {
            return await controls?.changeLayout(transforms);
        } catch (error: unknown) {
            if (!(error instanceof Error)) {
                throw error;
            }
            logger.error(
                {context: 'Meeting', error, transforms},
                'failed to change layout',
            );
        }
    };
    const changeLayout = async (
        layout: Layout,
        onDone?: () => void,
        onFail?: (error: unknown) => void,
    ) => {
        try {
            await _changeLayout({
                layout,
                guest_layout:
                    infinity.serviceType === 'lecture' ? layout : undefined,
            });
        } catch (error: unknown) {
            onFail?.(error);
        } finally {
            onDone?.();
        }
    };
    const toggleLayoutOverlayTextEnabled = async () => {
        await _changeLayout({
            enable_overlay_text: !props.layoutOverlayTextEnabled,
        });
    };
    const lock = async (lock: boolean) => {
        await infinity.lock({lock});
    };
    const startConference = async () => {
        await infinity.startConference({});
    };
    const disconnectAll = async () => {
        await infinity.disconnectAll({});
    };
    const askForHelp = async (
        roomId = infinity.roomId,
        onDone?: () => void,
        onFail?: (error: unknown) => void,
    ) => {
        try {
            await infinity.breakoutAskForHelp({breakoutUuid: roomId});
            onDone?.();
        } catch (error: unknown) {
            onFail?.(error);
        }
    };
    const cancelAskingForHelp = async (
        roomId = infinity.roomId,
        onDone?: () => void,
        onFail?: (error: unknown) => void,
    ) => {
        try {
            await infinity.breakoutRemoveAskForHelp({breakoutUuid: roomId});
            onDone?.();
        } catch (error: unknown) {
            onFail?.(error);
        }
    };
    const joinBreakoutRoom = async (
        roomId: string,
        onDone?: () => void,
        onFail?: (error: unknown) => void,
    ) => {
        try {
            await infinity.joinBreakoutRoom({
                breakoutUuid: roomId === 'main' ? undefined : roomId,
            });
        } catch (error: unknown) {
            onFail?.(error);
        } finally {
            onDone?.();
        }
    };
    const closeBreakoutRoom = async (roomId: string) => {
        await infinity.closeBreakoutRoom({breakoutUuid: roomId});
    };
    const closeAllBreakoutRooms = async () => {
        await infinity.closeBreakouts();
    };
    const breakout = infinity.breakout;
    const moveParticipants = infinity.breakoutMoveParticipants;
    const openRooms = async (
        roomDetails: BreakoutRoomDetail[],
        onDone?: () => void,
        onFail?: (error: unknown) => void,
    ) => {
        try {
            for (const roomDetail of roomDetails) {
                if (roomDetail.name === MainBreakoutRoomId) {
                    throw new Error('Breakout with name "main" is not allowed');
                }
                await breakout(roomDetail);
            }
        } catch (error: unknown) {
            onFail?.(error);
        } finally {
            onDone?.();
        }
    };
    const mute = infinity.mute;
    const clientMute = infinity.clientMute;
    const guestsCanUnmute = infinity.guestsCanUnmute;
    const muteVideo = infinity.muteVideo;
    const presInMix = async (
        ...args: Parameters<typeof infinity.presInMix>
    ) => {
        if (
            infinity.conferenceFeatureFlags === undefined ||
            infinity.conferenceFeatureFlags.isDirectMedia
        ) {
            return;
        }
        await infinity.presInMix(...args);
    };
    const kick = infinity.kick;
    const admit = infinity.admit;
    const spotlight = infinity.spotlight;
    const fecc = infinity.fecc;
    const isBreakoutRoom = () =>
        infinityService.conferenceStatus.get(infinityService.roomId)
            ?.breakout ?? false;
    const setRole = async (...args: Parameters<typeof infinity.setRole>) => {
        if (isBreakoutRoom()) {
            return;
        }
        await infinity.setRole(...args);
    };
    const getParticipants = infinity.getParticipants;
    const muteAllGuests = infinity.muteAllGuests;
    const notifyNotAFK = infinity.notifyNotAFK;

    /**
     * Send message to conference or direct message to specific participant
     *
     * @param message - The message
     * @param toParticipantUuid - If provided the message will be sent to this
     * participant, if undefined the message is sent to everyone in the meeting.
     */
    const sendMessage = async (message: string, toParticipantUuid?: string) => {
        const isDirectMessage = !!toParticipantUuid;
        const me = infinity.getMe();
        if (!me) {
            return;
        }
        const chatMessage = {
            displayName: me.displayName || 'User',
            id: uuid(),
            message,
            timestamp: toTime(new Date()),
            type: 'user-message',
            userId: me.uuid,
        } as const;

        if (isDirectMessage) {
            addDirectChatMessage(toParticipantUuid, {
                ...chatMessage,
                pending: true,
            });
        } else {
            updateChatMessages([
                ...props.chatMessages,
                {...chatMessage, pending: true},
            ]);
        }

        const result = await infinity.sendMessage({
            payload: message,
            participantUuid: toParticipantUuid,
        });

        const setMsgSuccess = () => {
            if (isDirectMessage) {
                deleteDirectChatMessage(toParticipantUuid, chatMessage.id); // remove the pending direct chat message
                addDirectChatMessage(toParticipantUuid, chatMessage);
            } else {
                const messagesWithoutPending = props.chatMessages.filter(
                    ({id}) => chatMessage.id !== id,
                );
                updateChatMessages([...messagesWithoutPending, chatMessage]);
            }
        };

        if (result) {
            setMsgSuccess();
        } else {
            infinityClientSignals.onRetryQueueFlushed.addOnce(() => {
                setMsgSuccess();
            });
        }
    };
    const enableLiveCaptions = async (
        arg: Parameters<InfinityClient['liveCaptions']>[0],
        onDone?: () => void,
        onFail?: (error: unknown) => void,
    ) => {
        try {
            await infinity.liveCaptions(arg);
            onDone?.();
        } catch (error: unknown) {
            onFail?.(error);
        }
    };

    const setPin = (pin?: string) => infinity.setPin(pin);

    const subscribeEvents = () => {
        const handleOnCallConnected = createSignalHandler(
            callSignals.onCallConnected,
            () => {
                // We don't want to synchronise the local video mute state when we do ICE restart
                if (props.callStage !== CallStage.EventStreamConnected) {
                    // Initial Sync local video mute state, if undefined we send muteVideo true
                    void muteVideo({
                        muteVideo: mediaService.media?.videoMuted ?? true,
                    });
                }
                if (
                    props.callStage === CallStage.Restarting &&
                    props.presentationStream
                ) {
                    present(props.presentationStream);
                }
                props.callStage = CallStage.Connected;
                if (!isReceivingAnyMedia(config.get('callType'))) {
                    updateSplashScreen({
                        screenKey: 'custom',
                        text: i18n.t(
                            'meeting.not_receiving_media',
                            'Meeting participants and controls are shown on the left.\nYou can also share your screen from the toolbar.',
                        ),
                        background: '',
                        displayDuration: 0,
                    });
                    updateStep(MeetingFlow.InMeeting);
                }
                // Initial Sync local audio mute state, if undefined we send mute true
                void clientMute({
                    mute: mediaService.media?.audioMuted ?? true,
                });
            },
        );
        const handleOnRemoteStream = createSignalHandler(
            callSignals.onRemoteStream,
            stream => {
                updateStep(MeetingFlow.InMeeting);
                setRemoteStream(stream);
            },
        );
        const handleOnLayoutOverlayTextEnabled = createSignalHandler(
            infinityClientSignals.onLayoutOverlayTextEnabled,
            updateLayoutOverLayTextEnabled,
        );
        const handleOnConnected = createSignalHandler(
            infinityClientSignals.onConnected,
            async () => {
                props.callStage = CallStage.EventStreamConnected;
                logger.debug({context: 'Meeting'}, 'Event stream connected');
                await presInMix({state: config.get('preferPresInMix')});
            },
        );
        const handleOnPinRequired = createSignalHandler(
            infinityClientSignals.onPinRequired,
            ({
                hasHostPin,
                hasGuestPin,
            }: ExtractSignalType<
                typeof infinityClientSignals.onPinRequired
            >) => {
                logger.debug(
                    {context: 'Meeting', hasHostPin, hasGuestPin},
                    'Require pin',
                );
                if (hasHostPin && hasGuestPin) {
                    updateStep(MeetingFlow.EnterPin);
                } else {
                    updateStep(MeetingFlow.AreYouHost);
                }

                setPinRequired(hasHostPin && hasGuestPin);
            },
        );
        const handleOnSplashScreen = createSignalHandler(
            infinityClientSignals.onSplashScreen,
            splashScreen => {
                // those keys should be displayed as notifications
                if (
                    splashScreen?.text &&
                    [
                        'direct_media_escalate',
                        'direct_media_deescalate',
                    ].includes(splashScreen?.screenKey)
                ) {
                    return;
                }
                if (
                    isReceivingAnyMedia(config.get('callType')) ||
                    [
                        'direct_media_welcome',
                        'direct_media_waiting_for_host',
                    ].includes(splashScreen?.screenKey)
                ) {
                    updateSplashScreen(splashScreen);
                }
                updateStep(MeetingFlow.InMeeting);
            },
        );
        const handleOnIdp = createSignalHandler(
            infinityClientSignals.onIdp,
            idps => {
                props.idps = idps;
                updateStep(MeetingFlow.Idp);
            },
        );
        const handleOnExtension = createSignalHandler(
            infinityClientSignals.onExtension,
            () => {
                updateStep(MeetingFlow.EnterExtension);
            },
        );
        const handleOnPeerDisconnect = createSignalHandler(
            infinityClientSignals.onPeerDisconnect,
            async () => {
                props.callStage = CallStage.Restarting;
                await controls?.restartCall(callAttrs);
            },
        );
        const handleOnTransfer = createSignalHandler(
            infinityClientSignals.onTransfer,
            ({alias, token, callTag, target}) => {
                if (isReceivingAnyMedia(config.get('callType'))) {
                    updateSplashScreen(undefined);
                }

                const redirect = (reason: DisconnectReason = 'Transfer') => {
                    void end({reason}).then(() =>
                        transferSignal.emit({alias, token, callTag, target}),
                    );
                };

                if (target === 'direct' || target === 'transcoded') {
                    redirect('DirectMediaTransfer');
                } else if (target === 'conference') {
                    redirect();
                }
            },
        );
        const handleOnDisconnect = createSignalHandler(
            infinityClientSignals.onDisconnected,
            ({error, errorCode}) => {
                if (applicationConfig.disconnectDestination) {
                    return navigateToPostMeeting(
                        props.conferenceAlias,
                        applicationConfig.disconnectDestination,
                    );
                }
                if (error) {
                    updateInfinityError(error, errorCode);
                } else {
                    navigateToPostMeeting(props.conferenceAlias);
                }
            },
        );
        const handleOnError = createSignalHandler(
            infinityClientSignals.onError,
            ({error, errorCode}) => {
                if (error === 'Invalid PIN') {
                    props.error = {
                        type: error,
                        code: i18n.t('pin.invalid', 'Invalid PIN'),
                    };
                    invalidPinSignal.emit();
                    updateStep(
                        props.joinAsHost
                            ? MeetingFlow.EnterHostPin
                            : MeetingFlow.EnterPin,
                    );
                } else {
                    updateInfinityError(error, errorCode);
                }
            },
        );

        const handleOnParticipants = createSignalHandler(
            infinityClientSignals.onParticipants,
            ({id, participants}) => {
                // FIXME - Find a more reliable way to override the default displayName to use overlayText property
                participants.forEach(
                    p => (p.displayName = p.overlayText || p.displayName),
                );
                if (infinityService.roomId === id) {
                    props.isFeccEnabled = gatewayParticipantCanFecc();
                }
            },
        );

        const handleOnParticipantLeft = createSignalHandler(
            infinityClientSignals.onParticipantLeft,
            ({participant: participantWhoDisconnected}) => {
                updateDisconnectedParticipants([
                    ...props.disconnectedParticipants,
                    participantWhoDisconnected,
                ]);
            },
        );

        const handleOnRequestedLayout = createSignalHandler(
            infinityClientSignals.onRequestedLayout,
            layout => {
                props.currentHostLayout = layout.primaryScreen.hostLayout;
            },
        );

        const handleOnChatMessage = createSignalHandler(
            infinityClientSignals.onMessage,
            message => {
                if (message.message.length > CHARACTER_LIMIT) {
                    logger.warn(
                        `Message received from the server is too big. Length: ${message.message.length}`,
                    );
                    return;
                }

                const chatMessage: ChatMessage = {
                    ...message,
                    displayName: message.displayName || 'User',
                    timestamp: toTime(message.at),
                    type: 'user-message',
                };

                if (message.direct) {
                    addDirectChatMessage(message.userId, chatMessage);
                    addUnreadDirectChatMessage(message.userId, chatMessage);
                } else {
                    updateChatMessages([...props.chatMessages, chatMessage]);
                    updateUnreadChatMessages([
                        ...props.unreadChatMessages,
                        chatMessage,
                    ]);
                }
            },
        );

        const handleOnBreakoutRefer = createSignalHandler(
            infinityClientSignals.onBreakoutRefer,
            _roomUuid => {
                updateChatMessages([]);
            },
        );

        const handleAudioMuted = createSignalHandler(
            mediaSignals.onAudioMuteStateChanged,
            muted => {
                void clientMute({mute: Boolean(muted)});
                const me = infinity.getMe();
                if (
                    !muted &&
                    me?.isMuted &&
                    (me?.isHost ||
                        infinity.conferenceStatus.get(infinity.roomId)
                            ?.guestsCanUnmute)
                ) {
                    void mute({
                        mute: false,
                    });
                }
            },
        );

        const handleVideoMuted = createSignalHandler(
            mediaSignals.onVideoMuteStateChanged,
            muted => {
                void muteVideo({muteVideo: Boolean(muted)});
            },
        );

        const handlePresentationStream = createSignalHandler(
            presentationStreamSignal,
            stream => {
                props.presentationStream = stream;
                if (
                    props.presentationStream?.getAudioTracks().length &&
                    mediaService.media?.audioMuted
                ) {
                    void mute({
                        mute: false,
                    });
                }
            },
        );

        const handleOnRaiseHand = createSignalHandler(
            infinityClientSignals.onRaiseHand,
            ({participant}) => {
                participant.displayName =
                    participant.overlayText || participant.displayName;
            },
        );

        const handleOnLiveCaptions = createSignalHandler(
            infinityClientSignals.onLiveCaptions,
            ({data: text, isFinal}) => {
                const transcript = {
                    text,
                    isFinal,
                    timestamp: Date.now(),
                };

                if (
                    props.transcripts.length > 0 &&
                    !props.transcripts[props.transcripts.length - 1]?.isFinal
                ) {
                    if (
                        transcript.isFinal ||
                        transcript.text.length >=
                            (props.transcripts[props.transcripts.length - 1]
                                ?.text.length ?? 0)
                    ) {
                        props.transcripts[props.transcripts.length - 1] =
                            transcript;
                        props.transcripts = [...props.transcripts];
                    }
                } else {
                    props.transcripts = [...props.transcripts, transcript];
                }

                transcriptsSignal.emit();
            },
        );

        props.detachSignals = [
            mediaSignals.onAudioMuteStateChanged.add(handleAudioMuted),
            mediaSignals.onVideoMuteStateChanged.add(handleVideoMuted),
            presentationStreamSignal.add(handlePresentationStream),
            callSignals.onCallConnected.add(handleOnCallConnected),
            callSignals.onRemoteStream.add(handleOnRemoteStream),
            infinityClientSignals.onConnected.add(handleOnConnected),
            infinityClientSignals.onLayoutOverlayTextEnabled.add(
                handleOnLayoutOverlayTextEnabled,
            ),
            infinityClientSignals.onPinRequired.add(handleOnPinRequired),
            infinityClientSignals.onSplashScreen.add(handleOnSplashScreen),
            infinityClientSignals.onIdp.add(handleOnIdp),
            infinityClientSignals.onExtension.add(handleOnExtension),
            callSignals.onRtcStats.add(stats => {
                window.pexDebug = {...window.pexDebug, stats};
            }),
            subscribePageHide(() => {
                void end({reason: 'Browser closed'});
            }),
            infinityClientSignals.onPeerDisconnect.add(handleOnPeerDisconnect),
            infinityClientSignals.onTransfer.add(handleOnTransfer),
            infinityClientSignals.onDisconnected.add(handleOnDisconnect),
            infinityClientSignals.onError.add(handleOnError),
            config.subscribe('preferPresInMix', preferPresInMix => {
                if (props.callStage >= CallStage.EventStreamConnected) {
                    void presInMix({state: preferPresInMix});
                }
            }),
            infinityClientSignals.onParticipants.add(handleOnParticipants),
            infinityClientSignals.onParticipantLeft.add(
                handleOnParticipantLeft,
            ),
            infinityClientSignals.onRequestedLayout.add(
                handleOnRequestedLayout,
            ),
            infinityClientSignals.onMessage.add(handleOnChatMessage),
            infinityClientSignals.onBreakoutRefer.add(handleOnBreakoutRefer),
            infinityClientSignals.onRaiseHand.add(handleOnRaiseHand),
            infinityClientSignals.onLiveCaptions.add(handleOnLiveCaptions),
        ];
    };

    if (callAttrs.conferenceAlias) {
        subscribeEvents();
    }

    return {
        getMe: (roomId?: string) => infinity.getMe(roomId),
        getStep: () => props.step,
        setStep: updateStep,
        getSplashScreen: () => props.splashScreen,
        setSplashScreen: updateSplashScreen,
        getIdps: () => props.idps,
        setIdps: updateIdp,
        getLiveCaptionsEnabled: () => props.liveCaptionsEnabled,
        setLiveCaptionsEnabled: uppdateLiveCaptionsEnabled,
        getChatMessages: () => props.chatMessages,
        setChatMessages: updateChatMessages,
        getUnreadChatMessages: () => props.unreadChatMessages,
        setUnreadChatMessages: updateUnreadChatMessages,
        getDirectChatMessages: () => props.directChatMessages,
        getUnreadDirectChatMessages: () => props.unreadDirectChatMessages,
        deleteUnreadDirectChatMessages:
            deleteUnreadDirectChatMessagesWithParticipant,
        getUnseenUnreadDirectChatMessages: () =>
            props.unseenUnreadDirectChatMessages,
        setUnseenDirectChatMessagesToSeen:
            deleteAllUnseenUnreadDirectChatMessages,
        getDisconnectedParticipants: () => props.disconnectedParticipants,
        setDisconnectedParticipants: updateDisconnectedParticipants,
        getLayoutOverlayEnabled: () => props.layoutOverlayTextEnabled,
        getRemoteStream: () => props.remoteStream,
        getRoomId: () => infinity.roomId,
        getConferenceStatus: () => infinity.conferenceStatus,
        getConferenceFeatureFlags: () => infinity.conferenceFeatureFlags,
        getPinRequired: () => props.pinRequired,
        getCurrentHostLayout: () => props.currentHostLayout,
        getCurrentRoomParticipants: () =>
            infinity.getParticipants(infinity.roomId),
        getBreakoutRooms: () => infinity.breakoutRooms,
        getSecureCheckCode: () => infinity.secureCheckCode,
        getLiveCaptionsAvailability: () => {
            const status = infinity.conferenceStatus.get(infinity.roomId);
            return !!status?.liveCaptionsAvailable;
        },
        getIsFeccEnabled: () => props.isFeccEnabled,
        getConferenceAlias: () => props.conferenceAlias,
        getEndedReason: () => props.endedReason,
        getCallStage: () => props.callStage,
        getTranscripts: () => props.transcripts,
        isBreakoutRoom,
        release,
        continueCall: toFn(continueCall),
        end: toFn(end),
        leave: toFn(leave),
        present,
        endPresent,
        raiseHand: toFn(raiseHand),
        lowerAllRaisedHands: toFn(lowerAllRaisedHands),
        changeLayout: toFn(changeLayout),
        toggleLayoutOverlayTextEnabled: toFn(toggleLayoutOverlayTextEnabled),
        lock: toFn(lock),
        startConference: toFn(startConference),
        disconnectAll: toFn(disconnectAll),
        askForHelp: toFn(askForHelp),
        cancelAskingForHelp: toFn(cancelAskingForHelp),
        joinBreakoutRoom: toFn(joinBreakoutRoom),
        closeBreakoutRoom: toFn(closeBreakoutRoom),
        closeAllBreakoutRooms: toFn(closeAllBreakoutRooms),
        breakout: toFn(breakout),
        moveParticipants: toFn(moveParticipants),
        openRooms: toFn(openRooms),
        mute: toFn(mute),
        muteVideo: toFn(muteVideo),
        kick: toFn(kick),
        admit: toFn(admit),
        fecc: toFn(fecc),
        spotlight: toFn(spotlight),
        setRole: toFn(setRole),
        enableLiveCaptions: toFn(enableLiveCaptions),
        presInMix: toFn(presInMix),
        getParticipants,
        muteAllGuests: toFn(muteAllGuests),
        guestsCanUnmute: toFn(guestsCanUnmute),
        notifyNotAFK: toFn(notifyNotAFK),
        sendMessage: toFn(sendMessage),
        setPin,
        joinAsHost: () => {
            props.joinAsHost = true;
            updateStep(MeetingFlow.EnterHostPin);
        },
        joinAsGuest: () => {
            props.joinAsHost = false;
            void continueCall({pin: 'none'});
        },
        getError: () => props.error,
        removeError: () => {
            if (!props.error) {
                return;
            }
            const errorType = props.error.type;
            props.error = undefined;
            switch (errorType) {
                case 'Invalid PIN': {
                    invalidPinSignal.emit();
                    break;
                }
                default:
                    break;
            }
        },
    };
};

export type Meeting = ReturnType<typeof createMeeting>;

export type DeleteUnreadDirectChatMessages =
    Meeting['deleteUnreadDirectChatMessages'];

interface Props {
    infinityClient: InfinityClient;
    meeting: ReturnType<typeof createMeeting>;
}

export const infinityService = createInfinityClient(
    infinityClientSignals,
    callSignals,
);

const NO_CALL = '';

export const createInfinity = () => {
    const props: Props = {
        infinityClient: infinityService,
        meeting: createMeeting({
            infinity: infinityService,
            callAttrs: {conferenceAlias: NO_CALL},
            controls: undefined,
        }),
    };

    let detachCallConnected: Detach | undefined;

    const handleError = (error: unknown) => {
        if (!(error instanceof Error)) {
            throw error;
        }
        logger.error(error);
        // Send empty error since we don't want to expose the error message to the user
        infinityErrorSignal.emit({type: '\n', code: ''});
    };

    const call = async (
        callArgs: CallArgs,
        meetingArgs: Partial<MeetingAttributes>,
        presentationStream?: MediaStream,
    ) => {
        try {
            props.meeting = createMeeting({
                infinity: props.infinityClient,
                callAttrs: {
                    conferenceAlias: callArgs.conferenceAlias,
                    callTag: callArgs.callTag,
                    conferenceExtension: callArgs.conferenceExtension,
                },
                meetingAttrs: meetingArgs,
                controls: {
                    call: async (args?: Partial<CallArgs>) => {
                        const opts = {
                            ...getCallConfigs(),
                            ...callArgs,
                            ...args,
                        };
                        await props.infinityClient.call(opts);
                    },
                    endCall: end,
                    restartCall: async args => {
                        await props.infinityClient.restartCall({
                            ...getCallConfigs(),
                            ...callArgs,
                            ...args,
                        });
                    },
                    changeLayout: async transforms => {
                        const result = await props.infinityClient.setLayout({
                            transforms,
                        });
                        return result?.status === 200;
                    },
                },
            });
            meetingSignal.emit();

            if (presentationStream) {
                detachCallConnected = callSignals.onCallConnected.addOnce(
                    () => {
                        startPresentationSignal.emit({
                            stream: presentationStream,
                        });
                        detachCallConnected = undefined;
                    },
                );
            }
            await props.infinityClient.call({
                ...getCallConfigs(),
                ...callArgs,
            });
        } catch (error: unknown) {
            handleError(error);
        }
    };

    const end = async (args: EndArgs) => {
        try {
            await props.infinityClient.disconnect(args);
        } catch (error: unknown) {
            handleError(error);
        }
    };

    return Object.freeze({
        getMe: (roomId?: string) => {
            return props.meeting.getMe(roomId);
        },
        getMeeting: () => props.meeting,
        getServiceType: () => props.infinityClient.serviceType,
        call: (
            callArgs: CallArgs,
            meetingArgs: Partial<MeetingAttributes>,
            presentationStream?: MediaStream,
        ) => {
            void call(callArgs, meetingArgs, presentationStream);
        },
        endCall: () => {
            detachCallConnected?.();
            props.meeting.end();
        },
        setBandwidth: (bandwidth: number) => {
            logger.info({context: 'Meeting', bandwidth}, 'update bandwidth');
            props.infinityClient.setBandwidth(bandwidth);
        },
        setStream: (stream: MediaStream) => {
            logger.info({context: 'Meeting', stream}, 'update stream');
            props.infinityClient.setStream(stream);
        },
    });
};

export const infinity = createInfinity();

export type Infinity = ReturnType<typeof createInfinity>;
