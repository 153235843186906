import React, {useMemo} from 'react';
import cx from 'classnames';
import {useTranslation} from 'react-i18next';

import type {IconSource} from '@pexip/components';
import {Select} from '@pexip/components';
import type {MediaDeviceInfoLike, IndexedDevices} from '@pexip/media-control';
import {getDeviceId} from '@pexip/media-control';

import {mapDevicesToOptions} from '../../utils/devicesSelection';

export const DeviceSelect: React.FC<
    {
        devices: IndexedDevices;
        kind: MediaDeviceKind;
        iconType?: IconSource;
        isDisabled: boolean;
        label: string;
        selected: MediaDeviceInfoLike | undefined;
        onDeviceChange: (id: string) => void;
    } & Partial<React.ComponentProps<typeof Select>>
> = ({
    devices,
    kind,
    iconType,
    isDisabled = false,
    label,
    selected,
    onDeviceChange,
    className,
    sizeModifier = 'small',
    ...props
}) => {
    const {t} = useTranslation();

    const deviceId = selected ? getDeviceId(selected) : '';

    const options = useMemo(
        () =>
            mapDevicesToOptions({
                devices: devices.get({kind}),
                permissionLabel: t(
                    'media.waiting-for-permissions',
                    'Waiting for permissions...',
                ),
                selectedDeviceId: deviceId,
                label,
            }),
        [devices, kind, t, deviceId, label],
    );

    return (
        <Select
            value={deviceId}
            onValueChange={onDeviceChange}
            options={options}
            iconType={iconType}
            label={label}
            labelModifier="hidden"
            className={cx('mb-1', className)}
            isFullWidth
            isDisabled={isDisabled}
            sizeModifier={sizeModifier}
            {...props}
        />
    );
};
