import React from 'react';

import {Audio} from '@pexip/components';
import {findCurrentAudioOutputId} from '@pexip/media-control';

import connect from '../assets/connect.flac';
import disconnect from '../assets/disconnect.flac';
import test from '../assets/test.flac';
import {useConfig} from '../config';
import {logger} from '../logger';
import {useDevices} from '../hooks/userMedia';

const sounds = {
    connect,
    disconnect,
    test,
};

export const PlaySound: React.FC<
    React.ComponentProps<'audio'> & {
        onSuccess?: (audio: HTMLAudioElement | null) => void;
        sinkId?: string;
        sound: keyof typeof sounds;
        play: boolean;
    }
> = ({sinkId, sound, play, onSuccess, ...props}) => {
    const [selectedAudioOutput] = useConfig('audioOutput');
    const devices = useDevices();

    const selectedAudioOutputDeviceId = findCurrentAudioOutputId(
        selectedAudioOutput,
        devices.get({kind: 'audiooutput'}),
    );

    return (
        <Audio
            onFailure={logOnFailure}
            onSuccess={onSuccess}
            src={sounds[sound]}
            sinkId={sinkId ?? selectedAudioOutputDeviceId}
            play={play}
            {...props}
        />
    );
};

function logOnFailure(reason: unknown) {
    logger.warn({reason}, `failed to play sound`);
}
