import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import {
    PanelHeader,
    SettingsPanel,
    TestId as MediaTestId,
} from '@pexip/media-components';
import {ActionsRow, ThemeProvider, ToggleSwitch} from '@pexip/components';

import {BrowserCloseConfirmation} from '../types';
import {TestId} from '../../test/testIds';
import {useBrowserCloseConfirmationConfig} from '../hooks/useBrowserCloseConfirmationConfig';
import {useConfig} from '../config';

export const AdditionalSettings: React.FC<{
    onBackClick: () => void;
}> = ({onBackClick}) => {
    const {
        browserCloseConfirmation,
        setBrowserCloseConfirmation,
        shouldShowBrowserCloseConfirmation,
        isEnableBrowserCloseConfirmationByDefault,
    } = useBrowserCloseConfirmationConfig();
    const [
        showParticipantAuthenticatedState,
        setShowParticipantAuthenticatedState,
    ] = useConfig('showParticipantAuthenticatedState');

    const [enforceAudioMute, setEnforceAudioMute] =
        useConfig('enforceAudioMute');
    const [enforceVideoMute, setEnforceVideoMute] =
        useConfig('enforceVideoMute');

    const toggleBrowserClosePrevention = useCallback(() => {
        switch (browserCloseConfirmation) {
            case BrowserCloseConfirmation.Enabled:
                setBrowserCloseConfirmation(
                    BrowserCloseConfirmation.Disabled,
                    true,
                );
                break;
            case BrowserCloseConfirmation.Disabled:
                setBrowserCloseConfirmation(
                    BrowserCloseConfirmation.Enabled,
                    true,
                );
                break;
            case BrowserCloseConfirmation.Unset:
                if (isEnableBrowserCloseConfirmationByDefault) {
                    setBrowserCloseConfirmation(
                        BrowserCloseConfirmation.Disabled,
                        true,
                    );
                } else {
                    setBrowserCloseConfirmation(
                        BrowserCloseConfirmation.Enabled,
                        true,
                    );
                }
        }
    }, [
        browserCloseConfirmation,
        isEnableBrowserCloseConfirmationByDefault,
        setBrowserCloseConfirmation,
    ]);

    const {t} = useTranslation();

    return (
        <>
            <SettingsPanel
                headerContent={
                    <ThemeProvider colorScheme="light">
                        <PanelHeader
                            title={`${t(
                                'settings.additional',
                                'Additional settings',
                            )}`}
                            onBackClick={onBackClick}
                            backButtonTestId={MediaTestId.ButtonSettingsBack}
                        />
                    </ThemeProvider>
                }
            >
                <ThemeProvider colorScheme="light">
                    <ActionsRow
                        title={t(
                            'settings.browser-close-prevention',
                            'Browser close confirmation',
                        )}
                        subtitle={t(
                            'settings.browser-close-prevention-desc',
                            'You must confirm before closing the browser or tab in which the meeting is currently running.',
                        )}
                    >
                        {/* TODO: The inline style for padding-left should be removed when the settings panel is redesigned */}
                        <div style={{paddingLeft: '50px'}}>
                            <ToggleSwitch
                                onChange={toggleBrowserClosePrevention}
                                labelModifier="hidden"
                                label={''}
                                name="name-labels-overlay"
                                data-testid={
                                    TestId.ToggleBrowserCloseConformation
                                }
                                checked={shouldShowBrowserCloseConfirmation}
                            />
                        </div>
                    </ActionsRow>
                    <ActionsRow
                        title={t(
                            'settings.show-participant-authenticated-state-title',
                            'Show authenticated participants',
                        )}
                        subtitle={t(
                            'settings.show-participant-authenticated-state-desc',
                            'See indicators for participants who joined the meeting using SSO authentication.',
                        )}
                    >
                        <div style={{paddingLeft: '50px'}}>
                            <ToggleSwitch
                                onChange={isChecked =>
                                    setShowParticipantAuthenticatedState(
                                        isChecked,
                                        true,
                                    )
                                }
                                labelModifier="hidden"
                                label={''}
                                name="name-labels-overlay"
                                data-testid={
                                    TestId.ToggleShowAuthenticatedParticipants
                                }
                                checked={showParticipantAuthenticatedState}
                            />
                        </div>
                    </ActionsRow>
                    <ActionsRow
                        title={t(
                            'settings.enforce-mute-camera-by-default-title',
                            'Camera off when joining',
                        )}
                        subtitle={t(
                            'settings.enforce-mute-camera-by-default-desc',
                            'When you join the meeting, your camera will be off each time you join. You can turn the camera on at anytime during the meeting.',
                        )}
                    >
                        <div>
                            <ToggleSwitch
                                onChange={isChecked => {
                                    setEnforceVideoMute(isChecked, true);
                                }}
                                label={t(
                                    'settings.enforce-mute-camera-by-default-title',
                                    'Camera off when joining',
                                )}
                                labelModifier="hidden"
                                name="enforce-video-mute"
                                data-testid={TestId.ToggleEnforceVideoMute}
                                checked={enforceVideoMute}
                            />
                        </div>
                    </ActionsRow>
                    <ActionsRow
                        title={t(
                            'settings.enforce-mute-microphone-by-default-title',
                            'Mute microphone when joining',
                        )}
                        subtitle={t(
                            'settings.enforce-mute-microphone-by-default-desc',
                            'When you join the meeting, your microphone will be muted each time you join. You can unmute your microphone at anytime during the meeting.',
                        )}
                    >
                        <div style={{paddingLeft: '50px'}}>
                            <ToggleSwitch
                                onChange={isChecked => {
                                    setEnforceAudioMute(isChecked, true);
                                }}
                                label={t(
                                    'settings.enforce-mute-microphone-by-default-title',
                                    'Mute microphone when joining',
                                )}
                                labelModifier="hidden"
                                name="enforce-audio-mute"
                                data-testid={TestId.ToggleEnforceAudioMute}
                                checked={enforceAudioMute}
                            />
                        </div>
                    </ActionsRow>
                </ThemeProvider>
            </SettingsPanel>
        </>
    );
};
