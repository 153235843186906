import React, {
    createContext,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import i18next from 'i18next';

import type {RPCCall, RPCReply} from '@pexip/plugin-api';
import {isRPCCall, Channel} from '@pexip/plugin-api';

import {useBranding} from '../branding/Context';
import {logger} from '../logger';
import {userInitiatedDisconnectSignal} from '../signals/InMeeting.signals';
import {useInfinityContext} from '../hooks/useInfinityContext';
import type {Plugin} from '../branding';

import type {ChannelContext, PluginContext} from './types';
import {handleInfinityCall} from './infinityCalls';
import {registerInfinityClientSignals} from './signals/RegisterInfinityClient.signals';
import {
    handleAddButton,
    handleOpenForm,
    handleOpenPrompt,
    handleRemoveElement,
    handleShowToast,
    handleUpdateButton,
    handleAddWidget,
    handleToggleWidget,
} from './handleUiRPC';
import {getValidSandboxValues} from './utils/sandbox.utils';
import {handleSetDisconnectDestination} from './handleAppRPC';

export const Plugins = createContext<PluginContext>({});

export const Channels = createContext<
    React.MutableRefObject<ChannelContext> | undefined
>(undefined);

export const PluginManager: React.FC<React.PropsWithChildren> = ({
    children,
}) => {
    const {i18n} = useTranslation();
    const plugins = useBranding('plugins');
    const pluginIframes = useRef(new Array<HTMLIFrameElement | null>());
    const loadedPlugins = useRef(new Array<Plugin>());
    const activePlugins = useMemo(
        () =>
            (plugins ?? []).map(plugin => (
                // eslint-disable-next-line jsx-a11y/iframe-has-title -- the plugins are only logical and not visible
                <iframe
                    id={plugin.id}
                    key={`plugin:${plugin.src}`}
                    sandbox={getValidSandboxValues(plugin)}
                    src={plugin.src}
                    aria-hidden
                    className="b-zero"
                    ref={ref => {
                        pluginIframes.current.push(ref);
                        loadedPlugins.current.push(plugin);
                    }}
                />
            )),
        [plugins],
    );
    const channels = useRef(new Map<string, Channel>());
    const [pluginsElements, setPluginsElements] = useState<PluginContext>({});
    const infinity = useInfinityContext();

    useEffect(() => {
        channels.current.forEach(channel => {
            channel.sendEvent({
                event: 'event:languageSelect',
                payload: i18n.language,
            });
        });
    }, [i18n.language]);

    useEffect(() => {
        const detachSignals = registerInfinityClientSignals(channels.current);
        detachSignals.push(
            userInitiatedDisconnectSignal.add(() =>
                channels.current.forEach(channel => {
                    channel.sendEvent({
                        event: 'event:userInitiatedDisconnect',
                        payload: undefined,
                    });
                }),
            ),
        );
        return () => {
            detachSignals.forEach(detachSignal => detachSignal());
        };
    }, []);

    const isPluginIframe = useCallback(
        (source: MessageEventSource | null) =>
            pluginIframes.current.find(
                iframe =>
                    iframe?.contentWindow && iframe.contentWindow === source,
            ),
        [],
    );

    const findManifestPluginByChannel = useCallback(
        (chanId: string) =>
            loadedPlugins.current.find(
                plugin => plugin?.id && chanId.includes(plugin.id),
            ),

        [],
    );

    const validateSyn = useCallback(
        (
            payload: RPCCall<'syn'>['payload'],
            source: MessageEventSource | null,
        ): RPCReply<'syn'>['payload'] => {
            if (!isPluginIframe(source)) {
                return {
                    ack: false,
                    reason: 'Not a plugin. You are likely trying to register a widget as a plugin',
                };
            }

            if (channels.current.has(payload.id)) {
                return {
                    ack: false,
                    reason: 'A plugin with the same id already exists',
                };
            }

            return {ack: true};
        },
        [isPluginIframe],
    );

    const setupChannel = (
        data: RPCCall<'syn' | 'syn:widget'>,
        newChannel: Channel,
    ) => {
        channels.current.set(data.chanId, newChannel);
        setPluginsElements(pluginsElements => ({
            ...pluginsElements,
            [data.chanId]: {
                buttons: [],
                forms: [],
                prompts: [],
                widgets: [],
            },
        }));
        newChannel.replyRPC({
            rpc: data.rpc,
            replyTo: data.id,
            payload: {ack: true},
        });
    };

    const syncWidget = useCallback(
        (channel: Channel) => {
            const meeting = infinity.getMeeting();
            meeting.getConferenceStatus().forEach((status, roomId) =>
                channel.sendEvent({
                    event: 'event:conferenceStatus',
                    payload: {status, id: roomId},
                }),
            );
            channel.sendEvent({
                event: 'event:conference:authenticated',
                payload: {conferenceAlias: meeting.getConferenceAlias()},
            });
            channel.sendEvent({
                event: 'event:languageSelect',
                payload: i18next.language,
            });
            channel.sendEvent({
                event: 'event:participants',
                payload: {
                    id: 'main',
                    participants: meeting.getParticipants('main'),
                },
            });
            const meInMain = meeting.getMe('main');
            if (meInMain) {
                channel.sendEvent({
                    event: 'event:me',
                    payload: {id: 'main', participant: meInMain},
                });
            }
            for (const roomId of meeting.getBreakoutRooms().keys()) {
                channel.sendEvent({
                    event: 'event:participants',
                    payload: {
                        id: roomId,
                        participants: meeting.getParticipants('main'),
                    },
                });
                const meInBR = meeting.getMe(roomId);
                if (meInBR) {
                    channel.sendEvent({
                        event: 'event:me',
                        payload: {id: 'main', participant: meInBR},
                    });
                }
            }
        },
        [infinity],
    );

    useEffect(() => {
        const onMessage = ({source, data}: MessageEvent<RPCCall>) => {
            if (!isRPCCall(data)) {
                return;
            }
            const chanId = data.chanId;
            if (data.rpc === 'syn') {
                const newChannel = new Channel(source as Window, chanId);
                const response = validateSyn(data.payload, source);
                if (!response.ack) {
                    newChannel.replyRPC({
                        rpc: data.rpc,
                        replyTo: data.id,
                        payload: response,
                    });
                    return;
                }

                logger.debug({data}, 'Registering new plugin');
                setupChannel(data, newChannel);
                return;
            } else if (data.rpc === 'syn:widget') {
                const newChannel = new Channel(source as Window, chanId);
                if (isPluginIframe(source)) {
                    newChannel.replyRPC({
                        rpc: data.rpc,
                        replyTo: data.id,
                        payload: {
                            ack: false,
                            reason: 'Not a widget. You are likely trying to register a plugin as a widget',
                        },
                    });
                    return;
                }

                logger.debug({data}, 'Registering new widget');
                setupChannel(data, newChannel);
                syncWidget(newChannel);
                return;
            }
            const channel = channels.current.get(chanId);
            if (channel) {
                logger.debug({data}, 'Emit reply for plugin message');
                switch (data.rpc) {
                    case 'app:setDisconnectDestination': {
                        channel.replyRPC(handleSetDisconnectDestination(data));
                        break;
                    }
                    case 'ui:button:add': {
                        channel.replyRPC(
                            handleAddButton(data, setPluginsElements),
                        );
                        break;
                    }
                    case 'ui:button:update': {
                        channel.replyRPC(
                            handleUpdateButton(data, setPluginsElements),
                        );
                        break;
                    }
                    case 'ui:form:open': {
                        channel.replyRPC(
                            handleOpenForm(data, setPluginsElements),
                        );
                        break;
                    }
                    case 'ui:toast:show': {
                        channel.replyRPC(handleShowToast(data));
                        break;
                    }
                    case 'ui:prompt:open': {
                        channel.replyRPC(
                            handleOpenPrompt(data, setPluginsElements),
                        );
                        break;
                    }
                    case 'ui:removeElement': {
                        channel.replyRPC(
                            handleRemoveElement(data, setPluginsElements),
                        );
                        break;
                    }
                    case 'ui:widget:add': {
                        channel.replyRPC(
                            handleAddWidget(
                                data,
                                setPluginsElements,
                                findManifestPluginByChannel(data.chanId),
                            ),
                        );
                        break;
                    }
                    case 'ui:widget:toggle': {
                        channel.replyRPC(handleToggleWidget(data));
                        break;
                    }
                    case 'conference:dialOut':
                    case 'conference:sendMessage':
                    case 'conference:sendApplicationMessage':
                    case 'conference:lock':
                    case 'conference:muteAllGuests':
                    case 'conference:setBandwidth':
                    case 'conference:setLayout':
                    case 'conference:disconnectAll':
                    case 'conference:sendRequest':
                    case 'conference:requestParticipants':
                    case 'conference:breakout':
                    case 'conference:joinBreakoutRoom':
                    case 'conference:closeBreakouts':
                    case 'conference:closeBreakoutRoom':
                    case 'conference:emptyBreakouts':
                    case 'conference:breakoutMoveParticipants':
                    case 'conference:currentRoomId':
                    case 'participant:transfer':
                    case 'participant:mute':
                    case 'participant:muteVideo':
                    case 'participant:disconnect':
                    case 'participant:spotlight':
                    case 'participant:admit':
                    case 'participant:setRole':
                    case 'participant:raiseHand':
                    case 'participant:setTextOverlay':
                    case 'participant:sendDTMF':
                        handleInfinityCall({
                            data,
                            channel,
                            sendMessage: infinity.getMeeting().sendMessage,
                        });
                        break;
                }
            }
        };
        window.addEventListener('message', onMessage);
        return () => {
            window.removeEventListener('message', onMessage);
        };
    }, [findManifestPluginByChannel, infinity, isPluginIframe, validateSyn]);

    return (
        <>
            <Channels.Provider value={channels}>
                <Plugins.Provider value={pluginsElements}>
                    {children}
                </Plugins.Provider>
            </Channels.Provider>
            {activePlugins}
        </>
    );
};
