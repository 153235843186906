import {
    MediaDeviceFailure,
    extractConstraintsWithKeys,
    findMediaInputFromMediaStreamTrack,
    getUserMedia,
    isExactDeviceConstraint,
    isStreamingRequestedDevices,
} from '@pexip/media-control';
import type {MediaDeviceRequest, IndexedDevices} from '@pexip/media-control';
import {assert} from '@pexip/utils';

import type {MediaTrack, GetUserMediaProcess, MediaSignals} from './types';
import {UserMediaStatus} from './types';
import {
    makeDeriveDeviceStatus,
    buildMedia,
    createMediaTrack,
    findExpectedInput,
} from './utils';
import {logger} from './logger';
import {isUnknownError} from './status';
import {PROCESSOR_LABELS} from './constants';

export type UserMedia = [MediaStream | undefined, UserMediaStatus];

export type GetUserMedia<
    T extends Promise<Iterable<unknown>> = Promise<UserMedia>,
> = (constraints: MediaDeviceRequest) => T;
export type GetCurrentDevices = () => Promise<IndexedDevices>;

export const toErrorDeviceStatus = (
    error: Error,
    deriveDeviceStatus: ReturnType<typeof makeDeriveDeviceStatus>,
) => {
    switch (error.message as MediaDeviceFailure) {
        case MediaDeviceFailure.AudioInputDeviceNotFoundError:
            return UserMediaStatus.AudioDeviceNotFound;
        case MediaDeviceFailure.VideoInputDeviceNotFoundError:
            return UserMediaStatus.VideoDeviceNotFound;
        case MediaDeviceFailure.AudioAndVideoDeviceNotFoundError:
            return UserMediaStatus.AudioVideoDevicesNotFound;
        case MediaDeviceFailure.NotAllowedError:
        case MediaDeviceFailure.SecurityError:
        case MediaDeviceFailure.PermissionDeniedError:
            return deriveDeviceStatus(
                UserMediaStatus.PermissionsRejectedAudioInput,
                UserMediaStatus.PermissionsRejectedVideoInput,
                UserMediaStatus.PermissionsRejected,
            );
        case MediaDeviceFailure.NotReadableError:
        case MediaDeviceFailure.TrackStartError:
        case MediaDeviceFailure.AbortError:
            return deriveDeviceStatus(
                UserMediaStatus.AudioDeviceInUse,
                UserMediaStatus.VideoDeviceInUse,
                UserMediaStatus.DevicesInUse,
            );
        case MediaDeviceFailure.MissingConstraintsError:
        case MediaDeviceFailure.OverconstrainedError:
        case MediaDeviceFailure.NotFoundError:
            return deriveDeviceStatus(
                UserMediaStatus.NoAudioDevicesFound,
                UserMediaStatus.NoVideoDevicesFound,
                UserMediaStatus.NoDevicesFound,
            );
        case MediaDeviceFailure.TypeError:
            return deriveDeviceStatus(
                UserMediaStatus.InvalidAudioConstraints,
                UserMediaStatus.InvalidVideoConstraints,
                UserMediaStatus.InvalidConstraints,
            );
        case MediaDeviceFailure.NotSupportedError:
            return deriveDeviceStatus(
                UserMediaStatus.NotSupportedErrorOnlyAudioInput,
                UserMediaStatus.NotSupportedErrorOnlyVideoInput,
                UserMediaStatus.NotSupportedError,
            );
        default:
            return deriveDeviceStatus(
                UserMediaStatus.UnknownErrorOnlyAudioinput,
                UserMediaStatus.UnknownErrorOnlyVideoinput,
                UserMediaStatus.UnknownError,
            );
    }
};

export const mapErrorStatus =
    (status: UserMediaStatus) => (constraints: MediaDeviceRequest) => {
        const toStatus = makeDeriveDeviceStatus({
            audio: constraints.audio ?? false,
            video: constraints.video ?? false,
        });
        switch (status) {
            case UserMediaStatus.AudioVideoDevicesNotFound:
                return toStatus(
                    UserMediaStatus.AudioDeviceNotFound,
                    UserMediaStatus.VideoDeviceNotFound,
                    status,
                );
            case UserMediaStatus.DevicesInUse:
                return toStatus(
                    UserMediaStatus.AudioDeviceInUse,
                    UserMediaStatus.VideoDeviceInUse,
                    status,
                );
            case UserMediaStatus.InvalidConstraints:
                return toStatus(
                    UserMediaStatus.InvalidAudioConstraints,
                    UserMediaStatus.InvalidVideoConstraints,
                    status,
                );
            case UserMediaStatus.NoDevicesFound:
                return toStatus(
                    UserMediaStatus.NoAudioDevicesFound,
                    UserMediaStatus.NoVideoDevicesFound,
                    status,
                );
            case UserMediaStatus.NotSupportedError:
                return toStatus(
                    UserMediaStatus.NotSupportedErrorOnlyAudioInput,
                    UserMediaStatus.NotSupportedErrorOnlyVideoInput,
                    status,
                );
            case UserMediaStatus.Overconstrained:
                return toStatus(
                    UserMediaStatus.AudioOverconstrained,
                    UserMediaStatus.VideoOverconstrained,
                    status,
                );
            case UserMediaStatus.PermissionsRejected:
                return toStatus(
                    UserMediaStatus.PermissionsRejectedAudioInput,
                    UserMediaStatus.PermissionsRejectedVideoInput,
                    status,
                );
            case UserMediaStatus.UnknownError:
                return toStatus(
                    UserMediaStatus.UnknownErrorOnlyAudioinput,
                    UserMediaStatus.UnknownErrorOnlyVideoinput,
                    status,
                );
            default:
                return status;
        }
    };

export const toSameDeviceStatus = ({
    audio,
    video,
}: {
    audio: boolean;
    video: boolean;
}) => {
    if (audio) {
        if (video) {
            return UserMediaStatus.PermissionsGranted;
        }
        return UserMediaStatus.PermissionsGrantedFallbackVideoinput;
    }
    if (video) {
        return UserMediaStatus.PermissionsGrantedFallbackAudioinput;
    }
    return UserMediaStatus.PermissionsGrantedFallback;
};

export const toOnlyDeviceStatus = (
    kind: 'audioinput' | 'videoinput',
    matched: boolean,
    devices: IndexedDevices,
) => {
    const hasAnotherTypeOfAuthorizedDevice = devices.anyAuthorizedDevice(
        kind === 'audioinput' ? 'videoinput' : 'audioinput',
    );
    const hasAnotherTypeOfDevice =
        devices.size(kind === 'audioinput' ? 'videoinput' : 'audioinput') > 0;
    if (matched) {
        if (hasAnotherTypeOfDevice) {
            if (hasAnotherTypeOfAuthorizedDevice) {
                return kind === 'audioinput'
                    ? UserMediaStatus.PermissionsOnlyAudioinput
                    : UserMediaStatus.PermissionsOnlyVideoinput;
            }
            return kind === 'audioinput'
                ? UserMediaStatus.PermissionsRejectedVideoInput
                : UserMediaStatus.PermissionsRejectedAudioInput;
        }
        return kind === 'audioinput'
            ? UserMediaStatus.PermissionsOnlyAudioinputNoVideoDevices
            : UserMediaStatus.PermissionsOnlyVideoinputNoAudioDevices;
    }
    if (hasAnotherTypeOfDevice) {
        if (hasAnotherTypeOfAuthorizedDevice) {
            return kind === 'audioinput'
                ? UserMediaStatus.PermissionsOnlyAudioinputFallback
                : UserMediaStatus.PermissionsOnlyVideoinputFallback;
        }
        return kind === 'audioinput'
            ? UserMediaStatus.PermissionsRejectedVideoInput
            : UserMediaStatus.PermissionsRejectedAudioInput;
    }
    return kind === 'audioinput'
        ? UserMediaStatus.PermissionsOnlyAudioinputFallbackNoVideoDevices
        : UserMediaStatus.PermissionsOnlyVideoinputFallbackNoAudioDevices;
};

const hasLiveTrack = (kind: 'audio' | 'video', tracks: MediaStreamTrack[]) =>
    tracks.some(track => track.kind === kind && track.readyState === 'live');

/**
 * Merge the previous status with the next status and assuming requesting both
 * video and audio inputs.
 */
export const mergeStatus = (
    prevStatus: UserMediaStatus,
    nextStatus: UserMediaStatus,
    prevTracks: MediaStreamTrack[] = [],
    nextTracks: MediaStreamTrack[] = [],
): UserMediaStatus => {
    const hasPrevLiveAudio = hasLiveTrack('audio', prevTracks);
    const hasPrevLiveVideo = hasLiveTrack('video', prevTracks);
    const hasNextLiveAudio = hasLiveTrack('audio', nextTracks);
    const hasNextLiveVideo = hasLiveTrack('video', nextTracks);

    // Replace Audio Track
    if (hasNextLiveAudio) {
        assert(hasPrevLiveAudio === false, 'Only 1 live audio');
        // Replace Audio and Video tracks
        if (hasNextLiveVideo) {
            assert(hasPrevLiveVideo === false, 'Only 1 live video');
            // No need to consider the previous status
            return nextStatus;
        }
        if (hasPrevLiveVideo) {
            // Merge previous video status with next audio status
            switch (prevStatus) {
                case UserMediaStatus.PermissionsGranted:
                case UserMediaStatus.PermissionsGrantedFallbackAudioinput:
                case UserMediaStatus.PermissionsOnlyVideoinputNoAudioDevices:
                case UserMediaStatus.PermissionsOnlyVideoinput: {
                    switch (nextStatus) {
                        case UserMediaStatus.PermissionsOnlyAudioinput:
                            return UserMediaStatus.PermissionsGranted;
                        case UserMediaStatus.PermissionsGrantedFallback:
                        case UserMediaStatus.PermissionsOnlyAudioinputFallback:
                            return UserMediaStatus.PermissionsGrantedFallbackAudioinput;
                        default:
                            return nextStatus;
                    }
                }
                case UserMediaStatus.PermissionsGrantedFallbackVideoinput:
                case UserMediaStatus.PermissionsGrantedFallback:
                case UserMediaStatus.PermissionsOnlyVideoinputFallbackNoAudioDevices:
                case UserMediaStatus.PermissionsOnlyVideoinputFallback: {
                    switch (nextStatus) {
                        case UserMediaStatus.PermissionsGranted:
                        case UserMediaStatus.PermissionsOnlyAudioinput:
                            return UserMediaStatus.PermissionsGrantedFallbackVideoinput;
                        case UserMediaStatus.PermissionsGrantedFallbackAudioinput:
                        case UserMediaStatus.PermissionsOnlyAudioinputFallback:
                            return UserMediaStatus.PermissionsGrantedFallback;
                        default:
                            return nextStatus;
                    }
                }
                default:
                    return nextStatus;
            }
        }
        // No previous Live Video
        return nextStatus;
    }
    // Replace Video Track Only
    if (hasNextLiveVideo) {
        assert(hasPrevLiveVideo === false, 'Only 1 live video');
        if (hasPrevLiveAudio) {
            switch (prevStatus) {
                case UserMediaStatus.PermissionsGranted:
                case UserMediaStatus.PermissionsGrantedFallbackVideoinput:
                case UserMediaStatus.PermissionsOnlyAudioinputNoVideoDevices:
                case UserMediaStatus.PermissionsOnlyAudioinput: {
                    switch (nextStatus) {
                        case UserMediaStatus.PermissionsOnlyVideoinput:
                            return UserMediaStatus.PermissionsGranted;
                        case UserMediaStatus.PermissionsGrantedFallback:
                        case UserMediaStatus.PermissionsOnlyVideoinputFallback:
                            return UserMediaStatus.PermissionsGrantedFallbackVideoinput;
                        default:
                            return nextStatus;
                    }
                }
                case UserMediaStatus.PermissionsGrantedFallbackAudioinput:
                case UserMediaStatus.PermissionsGrantedFallback:
                case UserMediaStatus.PermissionsOnlyAudioinputFallbackNoVideoDevices:
                case UserMediaStatus.PermissionsOnlyAudioinputFallback: {
                    switch (nextStatus) {
                        case UserMediaStatus.PermissionsGranted:
                        case UserMediaStatus.PermissionsOnlyVideoinput:
                            return UserMediaStatus.PermissionsGrantedFallbackAudioinput;
                        case UserMediaStatus.PermissionsGrantedFallbackVideoinput:
                        case UserMediaStatus.PermissionsOnlyVideoinputFallback:
                            return UserMediaStatus.PermissionsGrantedFallback;
                        default:
                            return nextStatus;
                    }
                }
                default:
                    return nextStatus;
            }
        }
    }
    // Failed to get a new track
    if (hasPrevLiveAudio) {
        switch (nextStatus) {
            case UserMediaStatus.PermissionsRejected:
            case UserMediaStatus.PermissionsRejectedVideoInput: {
                switch (prevStatus) {
                    case UserMediaStatus.PermissionsGrantedFallback:
                    case UserMediaStatus.PermissionsGrantedFallbackAudioinput:
                    case UserMediaStatus.PermissionsOnlyAudioinputFallback:
                    case UserMediaStatus.PermissionsOnlyAudioinputFallbackNoVideoDevices:
                        return UserMediaStatus.PermissionsOnlyAudioinputFallback;
                    default:
                        return UserMediaStatus.PermissionsOnlyAudioinput;
                }
            }
            case UserMediaStatus.NoDevicesFound:
            case UserMediaStatus.AudioVideoDevicesNotFound:
            case UserMediaStatus.VideoDeviceNotFound:
            case UserMediaStatus.NoVideoDevicesFound:
                switch (prevStatus) {
                    case UserMediaStatus.PermissionsGrantedFallback:
                    case UserMediaStatus.PermissionsGrantedFallbackAudioinput:
                    case UserMediaStatus.PermissionsOnlyAudioinputFallback:
                    case UserMediaStatus.PermissionsOnlyAudioinputFallbackNoVideoDevices:
                        return UserMediaStatus.PermissionsOnlyAudioinputFallbackNoVideoDevices;
                    default:
                        return UserMediaStatus.PermissionsOnlyAudioinputNoVideoDevices;
                }
            case UserMediaStatus.DevicesInUse:
            case UserMediaStatus.VideoDeviceInUse:
                return UserMediaStatus.VideoDeviceInUse;
            case UserMediaStatus.Overconstrained:
            case UserMediaStatus.VideoOverconstrained:
                return UserMediaStatus.VideoOverconstrained;
            case UserMediaStatus.NotSupportedError:
            case UserMediaStatus.NotSupportedErrorOnlyVideoInput:
                return UserMediaStatus.NotSupportedErrorOnlyVideoInput;
            case UserMediaStatus.InvalidConstraints:
            case UserMediaStatus.InvalidVideoConstraints:
                return UserMediaStatus.InvalidVideoConstraints;
            case UserMediaStatus.UnknownError:
            case UserMediaStatus.UnknownErrorOnlyVideoinput:
                return UserMediaStatus.UnknownErrorOnlyVideoinput;
            default:
                return prevStatus;
        }
    }
    if (hasPrevLiveVideo) {
        switch (nextStatus) {
            case UserMediaStatus.PermissionsRejected:
            case UserMediaStatus.PermissionsRejectedAudioInput:
                switch (prevStatus) {
                    case UserMediaStatus.PermissionsGrantedFallback:
                    case UserMediaStatus.PermissionsGrantedFallbackVideoinput:
                    case UserMediaStatus.PermissionsOnlyVideoinputFallback:
                    case UserMediaStatus.PermissionsOnlyVideoinputFallbackNoAudioDevices:
                        return UserMediaStatus.PermissionsOnlyVideoinputFallback;
                    default:
                        return UserMediaStatus.PermissionsOnlyVideoinput;
                }
            case UserMediaStatus.NoDevicesFound:
            case UserMediaStatus.AudioVideoDevicesNotFound:
            case UserMediaStatus.AudioDeviceNotFound:
            case UserMediaStatus.NoAudioDevicesFound: {
                switch (prevStatus) {
                    case UserMediaStatus.PermissionsGrantedFallback:
                    case UserMediaStatus.PermissionsGrantedFallbackVideoinput:
                    case UserMediaStatus.PermissionsOnlyVideoinputFallback:
                    case UserMediaStatus.PermissionsOnlyVideoinputFallbackNoAudioDevices:
                        return UserMediaStatus.PermissionsOnlyVideoinputFallbackNoAudioDevices;
                    default:
                        return UserMediaStatus.PermissionsOnlyVideoinputNoAudioDevices;
                }
            }
            case UserMediaStatus.DevicesInUse:
            case UserMediaStatus.AudioDeviceInUse:
                return UserMediaStatus.AudioDeviceInUse;
            case UserMediaStatus.Overconstrained:
            case UserMediaStatus.AudioOverconstrained:
                return UserMediaStatus.AudioOverconstrained;
            case UserMediaStatus.NotSupportedError:
            case UserMediaStatus.NotSupportedErrorOnlyAudioInput:
                return UserMediaStatus.NotSupportedErrorOnlyAudioInput;
            case UserMediaStatus.InvalidConstraints:
            case UserMediaStatus.InvalidAudioConstraints:
                return UserMediaStatus.InvalidAudioConstraints;
            case UserMediaStatus.UnknownError:
            case UserMediaStatus.UnknownErrorOnlyAudioinput:
                return UserMediaStatus.UnknownErrorOnlyAudioinput;
            default:
                return prevStatus;
        }
    }
    switch (nextStatus) {
        case UserMediaStatus.PermissionsRejected:
        case UserMediaStatus.PermissionsRejectedAudioInput:
        case UserMediaStatus.PermissionsRejectedVideoInput:
            return UserMediaStatus.PermissionsRejected;
        case UserMediaStatus.NoDevicesFound:
        case UserMediaStatus.AudioVideoDevicesNotFound:
        case UserMediaStatus.AudioDeviceNotFound:
        case UserMediaStatus.VideoDeviceNotFound:
        case UserMediaStatus.NoVideoDevicesFound:
        case UserMediaStatus.NoAudioDevicesFound: {
            return UserMediaStatus.NoDevicesFound;
        }
        case UserMediaStatus.DevicesInUse:
        case UserMediaStatus.AudioDeviceInUse:
        case UserMediaStatus.VideoDeviceInUse:
            return UserMediaStatus.DevicesInUse;
        case UserMediaStatus.Overconstrained:
        case UserMediaStatus.AudioOverconstrained:
        case UserMediaStatus.VideoOverconstrained:
            return UserMediaStatus.Overconstrained;
        case UserMediaStatus.InvalidConstraints:
        case UserMediaStatus.InvalidVideoConstraints:
        case UserMediaStatus.InvalidAudioConstraints:
            return UserMediaStatus.InvalidConstraints;
        case UserMediaStatus.NotSupportedError:
        case UserMediaStatus.NotSupportedErrorOnlyAudioInput:
        case UserMediaStatus.NotSupportedErrorOnlyVideoInput:
            return UserMediaStatus.NotSupportedError;
        case UserMediaStatus.UnknownError:
        case UserMediaStatus.UnknownErrorOnlyAudioinput:
        case UserMediaStatus.UnknownErrorOnlyVideoinput:
            return UserMediaStatus.UnknownError;
        default:
            return nextStatus;
    }
};

/**
 * Try to come up with an error level according to provided UserMediaStatus and
 * MediaDeviceRequest
 *
 * @param error - The error thrown from media request
 * @param status - The status in result
 * @param constraints - The constraints used for the request
 */
export const deriveErrorLevel = (
    error: Error,
    status: UserMediaStatus,
    constraints: MediaDeviceRequest,
) => {
    // Only log to error level when we should pay attention, e.g.
    // UnknownError
    const errorMsg = error.message;
    return [
        (errorMessage: string) =>
            (MediaDeviceFailure.TypeError as string) === errorMessage,
        (errorMessage: string) =>
            (MediaDeviceFailure.NotSupportedError as string) === errorMessage,
        (errorMessage: string) =>
            (MediaDeviceFailure.OverconstrainedError as string) ===
                errorMessage &&
            ![constraints.audio, constraints.video].some(constraint =>
                isExactDeviceConstraint(constraint),
            ),
        () => isUnknownError(status),
    ].some(shouldUseError => shouldUseError(errorMsg))
        ? 'error'
        : 'warn';
};

export const requestUserMedia =
    (
        getCurrentDevices: GetCurrentDevices,
        getMedia = getUserMedia,
    ): GetUserMedia =>
    async constraints => {
        try {
            const stream = await getMedia(constraints);
            const devices = await getCurrentDevices();
            const deriveDeviceStatus = makeDeriveDeviceStatus({
                audio: devices.anyAuthorizedDevice('audioinput')
                    ? constraints.audio
                    : false,
                video: devices.anyAuthorizedDevice('videoinput')
                    ? constraints.video
                    : false,
            });
            const {audio, video} = isStreamingRequestedDevices(
                constraints,
                stream,
                devices,
            );
            const onlyAudioStatus = toOnlyDeviceStatus(
                'audioinput',
                audio,
                devices,
            );
            const onlyVideoStatus = toOnlyDeviceStatus(
                'videoinput',
                video,
                devices,
            );
            const status = deriveDeviceStatus(
                onlyAudioStatus,
                onlyVideoStatus,
                toSameDeviceStatus({audio, video}),
            );

            return [stream, status];
        } catch (error: unknown) {
            if (error instanceof Error) {
                const deriveDeviceStatus = makeDeriveDeviceStatus(constraints);
                const status = toErrorDeviceStatus(error, deriveDeviceStatus);
                return [undefined, status];
            }
            throw error;
        }
    };

export const mergeNoDeviceStatus = (
    constraints: MediaDeviceRequest,
    anyDevices: {audio: boolean; video: boolean},
    status: UserMediaStatus,
): UserMediaStatus => {
    if (
        status !== UserMediaStatus.NoDevicesFound ||
        (!anyDevices.audio &&
            !anyDevices.video &&
            constraints.audio &&
            constraints.video)
    ) {
        return status;
    }
    if (!anyDevices.audio && constraints.audio) {
        return UserMediaStatus.NoAudioDevicesFound;
    }
    if (!anyDevices.video && constraints.video) {
        return UserMediaStatus.NoVideoDevicesFound;
    }
    return status;
};

export const deriveUserMediaStatus = (
    devices: IndexedDevices,
    constraints: MediaDeviceRequest,
    prevStatus: UserMediaStatus,
) => {
    const anyAudioDevices = devices.size('audioinput') > 0;
    const anyVideoDevices = devices.size('videoinput') > 0;
    return mergeNoDeviceStatus(
        constraints,
        {audio: anyAudioDevices, video: anyVideoDevices},
        prevStatus,
    );
};

export const requestUserMediaWithRetry = (
    getCurrentDevices: GetCurrentDevices,
    createRequestUserMedia = requestUserMedia,
    gUM = getUserMedia,
): GetUserMedia => {
    const request = createRequestUserMedia(getCurrentDevices, gUM);
    return async constraints => {
        const [stream, status] = await request(constraints);
        const devices = await getCurrentDevices();
        const finalStatus = deriveUserMediaStatus(devices, constraints, status);
        return [stream, finalStatus];
    };
};
interface Options {
    getUserMedia: GetUserMedia;
    getCurrentDevices: GetCurrentDevices;
    signals?: MediaSignals;
    scope?: string;
}

/**
 * A process to get user media
 */
export const createGetUserMediaProcess = ({
    getUserMedia,
    getCurrentDevices,
    signals,
    scope = 'media',
}: Options): GetUserMediaProcess => {
    return async ({
        constraints,
        permission,
        originalConstraints,
        currentMedia,
    }) => {
        // Release the current track(s)
        if (currentMedia) {
            await Promise.all(
                currentMedia.getTracks().map(track => {
                    switch (track.kind) {
                        case 'audioinput':
                            // When the constraints is undefined we should do nothing
                            if (constraints.audio !== undefined) {
                                // There is only one audio track, so we can safely assign it
                                currentMedia.removeTrack(track);
                                return track.release();
                            }
                            return Promise.resolve();

                        case 'videoinput':
                            // When the constraints is undefined we should do nothing
                            if (constraints.video !== undefined) {
                                // There is only one video track, so we can safely assign it
                                currentMedia.removeTrack(track);
                                return track.release();
                            }
                            return Promise.resolve();
                        default:
                            assert(
                                false,
                                `Undexpected track kind: ${track.kind}`,
                            );
                    }
                }),
            );
        }
        const currentDevices = await getCurrentDevices();
        logger.debug(
            {
                scope,
                originalConstraints,
                constraints,
                currentDevices,
                currentMedia,
            },
            'pre getUserMedia',
        );
        if (!constraints.audio && !constraints.video) {
            currentMedia?.setOriginalConstraints(originalConstraints);
            return (
                currentMedia ??
                buildMedia({
                    constraints,
                    permission,
                    originalConstraints,
                    devices: currentDevices,
                    status: UserMediaStatus.PermissionsGranted,
                    stream: undefined,
                    signals,
                    tracks: [],
                })
            );
        }
        const [stream, status] = await getUserMedia(constraints);
        let finalStatus = status;
        if (
            currentMedia &&
            constraints.audio !== false &&
            constraints.video !== false
        ) {
            finalStatus = mergeStatus(
                currentMedia.status,
                status,
                currentMedia.stream.getTracks(),
                stream?.getTracks(),
            );
        }
        logger.debug({scope, stream, status: finalStatus}, 'post getUserMedia');
        const devices = await getCurrentDevices();
        const findInput = findMediaInputFromMediaStreamTrack(devices);

        let audioMediaTrack: MediaTrack | undefined;
        let videoMediaTrack: MediaTrack | undefined;
        const extractContentHint = extractConstraintsWithKeys(['contentHint']);

        if (constraints.audio !== undefined) {
            const audioTrack = stream?.getAudioTracks().at(0);
            const audioInput = findInput(audioTrack);
            const {
                contentHint: [[contentHint] = []],
            } = extractContentHint(constraints.audio);
            if (audioTrack) {
                audioTrack.contentHint = contentHint ?? '';
            }
            audioMediaTrack = createMediaTrack({
                label: PROCESSOR_LABELS.GetUserMedia,
                kind: 'audioinput',
                track: audioTrack,
                input: audioInput,
                expectedInput: findExpectedInput(
                    devices,
                    constraints.audio,
                    audioInput,
                    'audioinput',
                ),
                constraints: constraints.audio,
                signals,
            });
            currentMedia?.addTrack(audioMediaTrack);
        }

        if (constraints.video !== undefined) {
            const videoTrack = stream?.getVideoTracks().at(0);
            const videoInput = findInput(videoTrack);
            const {
                contentHint: [[contentHint] = []],
            } = extractContentHint(constraints.video);
            if (videoTrack) {
                videoTrack.contentHint = contentHint ?? '';
            }
            videoMediaTrack = createMediaTrack({
                label: PROCESSOR_LABELS.GetUserMedia,
                kind: 'videoinput',
                track: videoTrack,
                input: videoInput,
                expectedInput: findExpectedInput(
                    devices,
                    constraints.video,
                    videoInput,
                    'videoinput',
                ),
                constraints: constraints.video,
                signals,
            });
            currentMedia?.addTrack(videoMediaTrack);
        }
        if (currentMedia) {
            currentMedia.status = finalStatus;
            currentMedia.setOriginalConstraints(originalConstraints);
            return currentMedia;
        }
        return buildMedia({
            constraints,
            permission,
            originalConstraints,
            devices,
            stream,
            status,
            signals,
            tracks: [audioMediaTrack, videoMediaTrack].flatMap(track =>
                track ? [track] : [],
            ),
        });
    };
};
