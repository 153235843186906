import {useState, useCallback, useRef, useEffect} from 'react';

import type {AnalyzerNodeInit} from '@pexip/media-processor';
import {useAnimationFrame} from '@pexip/hooks';

const maxFrequencyScale = 255;

const asPercentage = (max: number) =>
    Math.trunc((max / maxFrequencyScale) * 100);

const getMaxFrequency = (
    analyzer: AnalyzerNodeInit,
    buffer: Uint8Array | undefined,
) => {
    if (!analyzer?.node || !buffer) {
        return 0;
    }

    analyzer.node.getByteFrequencyData(buffer);
    let max = 0;
    for (const n of buffer) {
        if (n > max) {
            max = n;
            if (max === 255) {
                // No need to keep iterating if we already found the max value
                break;
            }
        }
    }
    return max;
};

/**
 * Returns the max frequency value from the current frequency data.
 *
 * The frequency data is composed of integers on a scale from 0 to 255.
 * For more info @see https://developer.mozilla.org/en-US/docs/Web/API/AnalyserNode/getByteFrequencyData
 */
export const useAudioMeter = (analyzer?: AnalyzerNodeInit) => {
    const [maxFrequency, setMaxFrequency] = useState(0);
    const bufferRef = useRef<Uint8Array | undefined>();

    useEffect(() => {
        if (analyzer?.node) {
            bufferRef.current = new Uint8Array(analyzer.node.fftSize / 2);
        }
    }, [analyzer?.node]);

    const updateAudioLevel = useCallback(() => {
        if (analyzer) {
            setMaxFrequency(
                asPercentage(getMaxFrequency(analyzer, bufferRef.current)),
            );
        }
    }, [analyzer]);

    useAnimationFrame(updateAudioLevel);

    return maxFrequency;
};
