import {useSyncExternalStore} from 'react';

import type {PreviewStreamController} from '@pexip/media';

import {useAnalyzer} from './useAnalyzer';

export const usePreviewAnalyzer = (controller: PreviewStreamController) => {
    const track = useSyncExternalStore(
        onchange => controller.onUpdatingPreview(() => onchange()),
        () => controller.media?.getAudioTracks()[0]?.source.track,
    );
    return useAnalyzer(track);
};
