import {useMenuItem} from '@react-aria/menu';
import {useHover} from '@react-aria/interactions';
import type {Item} from '@react-stately/collections';
import type {Key, Node} from '@react-types/shared';
import type {TreeState} from '@react-stately/tree';
import React from 'react';
import cx from 'classnames';

import type {AriaMenuItem} from './AriaMenu.types';

import styles from './AriaMenu.module.scss';

export const InnerAriaMenuItem: React.FC<{
    item: Node<typeof Item>;
    state: TreeState<typeof Item>;
    onAction?: (key: Key) => void;
    itemMap: Map<Key, AriaMenuItem>;
}> = ({item, state, onAction, itemMap}) => {
    const ref = React.useRef(null);

    const {isHovered, hoverProps} = useHover({
        onHoverStart: () => state.selectionManager.setFocusedKey(item.key),
        onHoverEnd: () => {
            if (state.selectionManager.focusedKey === item.key) {
                state.selectionManager.setFocusedKey(null);
            }
        },
    });

    const {menuItemProps} = useMenuItem(
        {
            key: item.key,
            onPress: onAction ? () => onAction(item.key) : undefined,
        },
        state,
        ref,
    );

    // Prevent default on pointer up to prevent unexpected menu item selection when the menu is opened by mouse click
    menuItemProps.onPointerUp = e => {
        e.preventDefault();
    };

    // Handle focus events so we can apply highlighted
    const isFocused = state.selectionManager.focusedKey === item.key;

    const alternativeHoverClassName = itemMap.get(
        item.key,
    )?.alternativeHoverClassName;

    const alternativeHoverStyle = itemMap.get(item.key)?.alternativeHoverStyle;

    // Highlight if focused or hovered but not both to allow keyboard navigation even if mouse is over the item
    const shouldHighlight = isFocused || (isFocused && !isHovered);

    return (
        <div
            {...menuItemProps}
            {...hoverProps}
            data-testid={itemMap.get(item.key)?.testId}
            ref={ref}
            style={{
                ...itemMap.get(item.key)?.style,
                ...(shouldHighlight ? alternativeHoverStyle : {}),
            }}
            className={cx(
                styles.innerMenuItem,
                shouldHighlight
                    ? alternativeHoverClassName ?? styles.hovered
                    : {},
                itemMap.get(item.key)?.className,
            )}
            data-active={itemMap.get(item.key)?.isActive}
        >
            {itemMap.get(item.key)?.icon}
            {item.rendered}
        </div>
    );
};
