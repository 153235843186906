import type {MediaDeviceRequest} from '@pexip/media-control';
import {extractConstraintsWithKeys} from '@pexip/media-control';
import {isEmpty, assert} from '@pexip/utils';
import type {AudioGraph, AudioNodeInit} from '@pexip/media-processor';
import {
    createAudioGraph,
    createAudioGraphProxy,
    createChannelMergerGraphNode,
    createStreamDestinationGraphNode,
    createStreamSourceGraphNode,
    resumeAudioOnUnmute,
} from '@pexip/media-processor';

import type {TrackProcessor, MediaTrack, AudioContentHint} from './types';
import {logger} from './logger';
import {createMediaTrack, isTrackMuted} from './utils';
import {PROCESSOR_LABELS} from './constants';

interface AudioStreamProcessorProps {
    mixWithAdditionalMedia?: boolean;
    merger?: AudioNodeInit<ChannelMergerNode, ChannelMergerNode>;
    displaySource?: AudioNodeInit<
        MediaStreamAudioSourceNode,
        MediaStreamAudioSourceNode
    >;
    audioGraph?: AudioGraph;
}

const FEATURE_KEYS: ['mixWithAdditionalMedia'] = ['mixWithAdditionalMedia'];
type FeaturePropKeys = (typeof FEATURE_KEYS)[number];
type FeatureProps = Pick<AudioStreamProcessorProps, FeaturePropKeys>;

const getAudioConstraints = extractConstraintsWithKeys(FEATURE_KEYS);

export const updateFeatureProps = (
    constraints: MediaDeviceRequest['audio'],
    props: FeatureProps,
) => {
    const extracted = getAudioConstraints(constraints);
    return FEATURE_KEYS.reduce((accm, key) => {
        const [feature] = extracted[key];
        if (feature !== undefined && props[key] !== feature) {
            props[key] = feature;
            return {...accm, [key]: feature};
        }
        return accm;
    }, {} as FeatureProps);
};

/**
 * Create a Audio Mixing Processor and will own the stream passed-in
 */
export const createAudioMixingProcess = (
    getCurrentMedia: () => MediaStream | undefined,
    label = PROCESSOR_LABELS.AudioMixingProcessor,
): TrackProcessor => {
    const props: AudioStreamProcessorProps = {};
    return async prevMediaTrack => {
        updateFeatureProps(prevMediaTrack.getConstraints(), props);

        const displayStream = getCurrentMedia();
        const displayTrack = displayStream?.getAudioTracks?.().at(0);

        const applyConstraints: MediaTrack['applyConstraints'] =
            async constraints => {
                if (isEmpty(constraints)) {
                    return;
                }
                const features = updateFeatureProps(constraints, props);
                logger.debug(
                    {label, constraints: constraints, features},
                    'apply audio mixing constraints',
                );
                if (
                    isEmpty(features) ||
                    (props.audioGraph &&
                        ['closed', 'closing'].includes(props.audioGraph.state))
                ) {
                    return;
                }
                if (features.mixWithAdditionalMedia) {
                    const newStream = getCurrentMedia();
                    const [newTrack] =
                        getCurrentMedia()?.getAudioTracks() ?? [];
                    if (!newTrack) {
                        return;
                    }
                    if (!props.audioGraph || !props.merger) {
                        logger.debug(
                            {label},
                            'Should update the media stream directly',
                        );
                        return;
                    }
                    if (props.displaySource && props.merger) {
                        if (
                            newStream ===
                            props.displaySource.audioNode?.mediaStream
                        ) {
                            return;
                        }
                        props.audioGraph.disconnect([
                            props.displaySource,
                            props.merger,
                        ]);
                    }
                    if (newStream) {
                        props.displaySource =
                            createStreamSourceGraphNode(newStream);
                        props.audioGraph.connect([
                            props.displaySource,
                            props.merger,
                        ]);
                    }
                } else if (features.mixWithAdditionalMedia === false) {
                    // Unmixing
                    if (props.displaySource) {
                        props.displaySource.release();
                        props.audioGraph?.disconnect([props.displaySource]);
                        props.displaySource = undefined;
                    }
                }

                return Promise.resolve();
            };

        // No need to mix and use as is
        if (!prevMediaTrack.track) {
            if (!displayTrack) {
                // Do nothing
                return prevMediaTrack;
            }
            return createMediaTrack({
                label,
                kind: 'audioinput',
                previousMediaTrack: prevMediaTrack,
                input: prevMediaTrack.input,
                expectedInput: prevMediaTrack.expectedInput,
                track: displayTrack,
                constraints: prevMediaTrack.getConstraints(),
                overrideMute: true,
                mute: () => {
                    /* Do Nothing */
                },
                applyConstraints,
                getSettings: () => {
                    const mixWithAdditionalMedia =
                        !!props.mixWithAdditionalMedia;
                    const contentHint =
                        (displayTrack?.contentHint as AudioContentHint) ?? '';
                    return {
                        mixWithAdditionalMedia,
                        contentHint,
                    };
                },
            });
        }

        assert(prevMediaTrack.track, 'Main track should be available');
        const mainSource = createStreamSourceGraphNode(
            new MediaStream([prevMediaTrack.track]),
        );
        props.displaySource =
            displayStream && createStreamSourceGraphNode(displayStream);
        props.merger = createChannelMergerGraphNode();
        const destination = createStreamDestinationGraphNode({
            channelCount: 1,
            channelCountMode: 'explicit',
        });
        const initialAudioNodeConnection = [
            [mainSource, props.merger],
            [props.merger, destination],
        ];
        logger.debug(
            {initialAudioNodeConnection, label},
            'Initial AudioNodeConnection',
        );
        const audioGraph = createAudioGraphProxy(
            createAudioGraph(initialAudioNodeConnection),
            {
                connect: (target, args) => {
                    logger.debug({label, target, args}, 'connect nodes');
                },
                disconnect: (target, args) => {
                    logger.debug({label, target, args}, 'disconnect nodes');
                },
            },
        );
        props.audioGraph = audioGraph;
        const unsubscribe = resumeAudioOnUnmute(audioGraph.context)(
            prevMediaTrack.track,
        );
        if (props.displaySource) {
            props.audioGraph.connect([props.displaySource, props.merger]);
        }
        const track = destination?.node?.stream.getAudioTracks().at(0);
        assert(track, 'No audio track mixed');
        // Inherit contentHint from previous track
        track.contentHint = prevMediaTrack.track.contentHint;

        const release = async () => {
            logger.debug({label}, 'Release Media');
            unsubscribe();
            // Release Props
            await props.audioGraph?.release();
            props.audioGraph = undefined;
            props.merger = undefined;
            props.displaySource = undefined;
        };

        return Promise.resolve(
            createMediaTrack({
                label,
                kind: 'audioinput',
                previousMediaTrack: prevMediaTrack,
                input: prevMediaTrack.input,
                expectedInput: prevMediaTrack.expectedInput,
                track,
                overrideMute: true,
                mute: toMute => {
                    prevMediaTrack.mute(toMute);
                    mainSource.node?.mediaStream
                        .getAudioTracks()
                        .forEach(track => {
                            track.enabled = !toMute;
                        });
                },
                get muted() {
                    return (
                        // If the source track muted, the following processed track will
                        // be muted as well since there is no data to process
                        !!prevMediaTrack.muted ||
                        mainSource.node?.mediaStream
                            .getAudioTracks()
                            .some(isTrackMuted)
                    );
                },
                constraints: prevMediaTrack.getConstraints(),
                applyConstraints,
                release,
                getSettings: () => {
                    const mixWithAdditionalMedia =
                        !!props.mixWithAdditionalMedia;
                    const contentHint =
                        (track.contentHint as AudioContentHint) ?? '';
                    return {
                        mixWithAdditionalMedia,
                        contentHint,
                    };
                },
            }),
        );
    };
};
