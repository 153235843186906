import React from 'react';

import {UserMediaContext} from '@pexip/media-components';

import {mediaService} from '../services/Media.service';

export const UserMediaContextProvider: React.FC<React.PropsWithChildren> = ({
    children,
}) => {
    return (
        <UserMediaContext.Provider value={mediaService}>
            {children}
        </UserMediaContext.Provider>
    );
};
