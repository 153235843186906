import {useCallback, useContext} from 'react';

import {Channels, Plugins} from '..';
import {useBranding} from '../../branding/Context';
import {logger} from '../../logger';

export const useOrderedPlugins = () => {
    const pluginManifest = useBranding('plugins');
    const plugins = useContext(Plugins);
    const channelsRef = useContext(Channels);

    /**
     * Finds the ids of all channels for a particular plugin ID, including its widgets channels
     */
    const findPluginChannelsIds = useCallback(
        (pluginId: string) => {
            const channelIds = Array.from(
                (channelsRef?.current ?? new Map<string, unknown>()).keys(),
            ).filter(channelId => channelId.includes(pluginId));
            if (channelIds.length === 0) {
                logger.warn(
                    'Plugin manifest id does not match with id given to "registerPlugin" in plugin source code',
                );
            }
            return channelIds;
        },
        [channelsRef],
    );

    return pluginManifest && pluginManifest.every(plugin => plugin.id)
        ? pluginManifest.flatMap(plugin => {
              const channelIds = plugin.id
                  ? findPluginChannelsIds(plugin.id)
                  : [];
              return channelIds.flatMap(channelId => {
                  const UIelements = plugins[channelId];
                  return UIelements ? UIelements : [];
              });
          })
        : Object.values(plugins);
};
