import type {Logger as PinoLogger} from 'pino';
import Pino from 'pino';

import {LogLevels, type Logger, type LogLevelsString} from './baseLogger';

const REDACTED_REPLACEMENT = '[REDACTED]';

export type {Logger, LogLevelsString};
export interface ChildLogger extends Logger {
    child(bindings: Record<string, unknown>): Logger;
}

export interface PexLogger extends ChildLogger {
    /**
     * Set this property to the desired logging level. In order of priority, available levels are:
        1. trace
        2. debug
        3. info
        4. warn
        5. error
        6. fatal
        7. silent

        The logging level is a minimum level. For instance if logger.level is 'info' then all 'fatal', 'error', 'warn', and 'info' logs will be enabled.

        You can pass 'silent' to disable logging.
     */
    level: string;
    /**
     * Get the log file as a blob
     */
    getLogs(): Blob;
    /**
     * Get a file name based on (app) name, current date/time, and extension
     */
    getLogFileName(): string;
    /**
     * Get the log file as a string.
     */
    getLogFile(): string;
    /**
     * Get Log Event Object
     **/
    logEvents: string[];
    /**
     * Create a log file based on the log until now, and trigger a download
     */
    downloadLog(): void;
}

function escapeRegexp(segment: string) {
    // While we don't expect values to contain any special characters, escape them (e.g. . => \.) to avoid blowing up if that happens.
    return segment.replace(/"/g, '\\$&').replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export function redactString(value: string, regex: RegExp, replaceTo: string) {
    return value.replace(regex, replaceTo);
}

export function defaultReplacer(_key: string, value: unknown) {
    if (value instanceof HTMLElement) {
        const innerHTML = value.innerHTML;
        if (innerHTML !== '') {
            return value.outerHTML.replace(innerHTML, '{...}');
        } else {
            return value.outerHTML;
        }
    } else if (value instanceof Function) {
        return `[Function ${value.name}]`;
    } else if (value instanceof MediaStream) {
        return {
            __type: 'MediaStream',
            id: value.id,
            active: value.active,
        };
    } else if (value instanceof MediaStreamTrack) {
        return {
            __type: 'MediaStreamTrack',
            kind: value.kind,
            id: value.id,
            enabled: value.enabled,
            muted: value.muted,
            readyState: value.readyState,
        };
    } else if (value instanceof Error) {
        return {
            name: value.toString(),
            message: value.message,
        };
    } else if (value instanceof AnalyserNode) {
        return {
            __type: 'AnalyserNode',
            frequencyBinCount: value.frequencyBinCount,
            fftSize: value.fftSize,
            minDecibels: value.minDecibels,
            maxDecibels: value.maxDecibels,
            smoothingTimeConstant: value.smoothingTimeConstant,
        };
    } else if (value instanceof AudioContext) {
        return {
            __type: 'AudioContext',
            audioWorklet: value.audioWorklet,
            state: value.state,
            sampleRate: value.sampleRate,
        };
    } else if (value instanceof MediaStreamAudioSourceNode) {
        return {
            __type: 'MediaStreamAudioSourceNode',
            mediaStream: value.mediaStream,
        };
    } else if (value instanceof MediaStreamAudioDestinationNode) {
        return {
            __type: 'MediaStreamAudioDestinationNode',
            stream: value.stream,
        };
    } else if (value instanceof ChannelMergerNode) {
        return {__type: 'ChannelMergerNode'};
    } else if (value instanceof GainNode) {
        return {__type: 'GainNode', gain: value.gain};
    } else if (value instanceof MediaElementAudioSourceNode) {
        return {
            __type: 'MediaElementAudioSourceNode',
            mediaElement: value.mediaElement,
        };
    } else if (
        'AudioWorkletNode' in window &&
        value instanceof AudioWorkletNode
    ) {
        return {
            __type: 'AudioWorkletNode',
            parameters: value.parameters,
        };
    } else if (value instanceof ChannelSplitterNode) {
        return {
            __type: 'ChannelSplitterNode',
        };
    } else if (value instanceof DelayNode) {
        return {
            __type: 'DelayNode',
            delayTime: value.delayTime,
        };
    } else if (value instanceof RTCRtpTransceiver) {
        return {
            __type: 'RTCRtpTransceiver',
            currentDirection: value.currentDirection,
            direction: value.direction,
            mid: value.mid,
            sender: value.sender,
            receiver: value.receiver,
        };
    } else if (value instanceof RTCRtpSender) {
        return {
            __type: 'RTCRtpSender',
            track: value.track,
        };
    } else if (value instanceof RTCRtpReceiver) {
        return {
            __type: 'RTCRtpReceiver',
            track: value.track,
        };
    }
    return value;
}

interface Options {
    /**
     * The name of the logger. When set adds a name field to every JSON line logged
     */
    name?: string;
    /**
     * Minimum level to be logged
     */
    minLevel?: LogLevelsString;
    /**
     * Replacer function to transform logging Javascript value to JSON
     *
     * @param key - The key for the logging object
     * @param value - Javascript value
     * @returns Transformed value
     */
    replacer?: (_key: string, value: unknown) => unknown;
    /**
     * Name to prefix the file with for the output log file
     * @defaultValue 'pexip'
     */
    fileName?: string;
    /**
     * Extension (without the dot) to use for the output log file
     * @defaultValue 'log'
     */
    fileExt?: string;
}

export const createRedactor = () => {
    const sensitiveValues = new Set<string>();
    let redactionRegex: RegExp | undefined = undefined;

    /**
     * @internal
     */
    function redact(value: string) {
        if (!redactionRegex) {
            return value;
        }
        return redactString(value, redactionRegex, REDACTED_REPLACEMENT);
    }

    function add(value: string) {
        // Doing raw string operations with json is fragile, try to detect some potential issues early.
        if (value === '') {
            throw new Error('Tried to add empty string for redaction');
        }
        if (/\n/.exec(value)) {
            throw new Error(
                'Trying to add value for redaction that is likely to break the log',
            );
        }
        // Probably overkill, but try to catch some possible non-string values that can break the log (null, undefined, booleans), or are likely to be unintended ([Object object]).
        if (
            ['null', 'undefined', 'true', 'false', '[Object object]'].includes(
                String(value),
            )
        ) {
            throw new Error(
                `Tried to add ${value} for redaction, which is almost certainly an error`,
            );
        }

        // avoid regenerating the RegExp if the value has already been added
        if (sensitiveValues.has(value)) {
            return;
        }
        sensitiveValues.add(value);

        // Check if the escapedValue is different from the original value, if so, add the escaped value to the set.
        // This could happen for example if the displayName is "My\Name". In this case, value is "My\\Name" and escapedValue is "My\\\\Name".
        // The escapedValue will be used in the body in the HTTP request, since we use JSON.stringify, which escapes the backslashes.
        const escapedValue = escapeRegexp(value);
        if (!sensitiveValues.has(escapedValue)) {
            sensitiveValues.add(escapedValue);
        }

        const doubleEscapedValue = escapeRegexp(escapedValue);
        if (!sensitiveValues.has(doubleEscapedValue)) {
            sensitiveValues.add(doubleEscapedValue);
        }

        // Redact values encoded in the query parameters. We replace the space with a plus sign, since that's what the browser does for query parameters.
        const encodedValue = encodeURI(value).replace(/%20/g, '+');
        if (!sensitiveValues.has(encodedValue)) {
            sensitiveValues.add(encodedValue);
        }

        redactionRegex = new RegExp(
            Array.from(sensitiveValues).map(escapeRegexp).join('|'),
            'g',
        );
    }

    function reset() {
        sensitiveValues.clear();
        redactionRegex = undefined;
    }

    return {
        redact,
        add,
        reset,
    };
};

const isLogLevelKey = (t: string | undefined): t is LogLevelsString =>
    typeof t === 'string' && t in LogLevels;

// For node `process` is defined. For vite (ie. in the browser) `process`
// is undefined, but `process.env.LOG_LEVEL` etc is defined because it's
// replaced in the build.
//
// For cypress `process.env.NODE_ENV` is defined, but `process` is not, and
// `process.env.LOG_LEVEL` is not.
const isCypress = (window as {Cypress?: unknown}).Cypress;
const LOG_LEVEL = isCypress
    ? 'info'
    : isLogLevelKey(process.env.LOG_LEVEL)
      ? process.env.LOG_LEVEL
      : 'debug';

export const createLogger = ({
    minLevel = LOG_LEVEL,
    replacer = defaultReplacer,
    fileName = 'pexip',
    fileExt = 'log',
}: Options = {}): PexLogger => {
    const logEvents: string[] = [];
    const redactor = createRedactor();

    function getLogs() {
        return new Blob(
            logEvents,
            // Technically application/ld+json
            {type: 'text/plain; charset=utf-8'},
        );
    }

    function getLogFile(): string {
        return logEvents.join('');
    }

    function getLogFileName(name = fileName, ext = fileExt) {
        return `${name}-${new Date().toISOString().replace(/:/g, '-')}.${ext}`;
    }

    function downloadLog(fileName = getLogFileName()) {
        const blobUrl = URL.createObjectURL(getLogs());
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = fileName;
        link.click();
        // Wait half a second, as reportedly some browsers will fail if it's revoked too quickly
        setTimeout(() => URL.revokeObjectURL(blobUrl), 500);
    }

    // HACK: Stupid solution, but seems to be the best way to transform
    //       transmit format to what e.g. pino-pretty expects.
    const pinoStore = Pino({
        browser: {
            write: (evt: unknown) => {
                if (typeof evt !== 'object' || !evt) {
                    return;
                }
                try {
                    const logEvent = JSON.stringify(evt, replacer);
                    logEvents.push(redactor.redact(logEvent));
                    logEvents.push('\n');
                } catch {
                    const logEvent = JSON.stringify(
                        Object.fromEntries(
                            Object.entries(evt).map(([k, v]) => [
                                k,
                                typeof v === 'object' ? `${v}` : v,
                            ]),
                        ),
                    );
                    logEvents.push(redactor.redact(logEvent));
                    logEvents.push('\n');
                }
            },
        },
        level: minLevel,
    });

    const pino = Pino({
        browser: {
            // asObject: true,
            transmit: {
                send: (level, event) => {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- semantically identical to passing directly
                    const [first, ...rest] = event.messages; // work around ts typechecking
                    event.bindings
                        .reduce<Pino.Logger>(
                            (ch, bi) => ch.child(bi),
                            pinoStore,
                        )
                        // eslint-disable-next-line no-unexpected-multiline -- prettier is doing something weird
                        [level](first, ...rest);
                },
            },
        },
        level: minLevel,
    });

    const redact = (value: string) => redactor.add(value);

    return {
        get logEvents() {
            return logEvents;
        },
        set level(level: typeof pino.level) {
            pino.level = level;
        },
        getLogs,
        getLogFileName,
        getLogFile,
        downloadLog,
        redact,
        fatal: (meta, message) => pino.fatal(meta, message),
        error: (meta, message) => pino.error(meta, message),
        warn: (meta, message) => pino.warn(meta, message),
        info: (meta, message) => pino.info(meta, message),
        debug: (meta, message) => pino.debug(meta, message),
        trace: (meta, message) => pino.trace(meta, message),
        silent: (meta, message) => pino.silent(meta, message),
        child: (...args: Parameters<PinoLogger['child']>) => {
            const child = pino.child(...args);
            return {
                fatal: (meta, message) => child.fatal(meta, message),
                error: (meta, message) => child.error(meta, message),
                warn: (meta, message) => child.warn(meta, message),
                info: (meta, message) => child.info(meta, message),
                debug: (meta, message) => child.debug(meta, message),
                trace: (meta, message) => child.trace(meta, message),
                silent: (meta, message) => child.silent(meta, message),
                redact,
            };
        },
    };
};
